<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t("dashboard") }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $t("all_quizzes") }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page" @click.prevent>
              {{ quizzes.length ? quizzes[0].subjectName : '' }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <h2 class="font-weight-bold fun_font text-center py-3">
            {{ $t("all_quizzes") }} "{{ quizzes.length ? quizzes[0].subjectName : '' }}"
          </h2>
          <button class="btn btn-blue-light m-1" @click="exportCSV()">{{ $t('export_csv') }}</button>
          <div class="dashboard_container_body">

            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('phone') }}</th>
                  <th class="text-left">{{ $t('aca_email') }}</th>
                  <th class="text-left">{{ $t('aca_password') }}</th>
                  <th class="text-left">{{ $t('note') }}</th>
                  <th class="text-left">{{ $t('status') }}</th>
                  <th class="text-left">{{ $t('attachment') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , i) in quizzes" :key="item.id">
                  <td style="max-width: 40px">{{ i + 1 }}</td>
                  <td>{{ item.user.student.mobile }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.password }}</td>
                  <td class="p-1">
                    <div class="d-flex">
                      <textarea type="text" data-vv-name="note" v-model="item.note"
                                class="form-control py-0"
                                :placeholder="$t('note')" style="min-height: 37px;max-height: 100px;height: 37px;min-width: 100px"
                      ></textarea>
                      <button
                          class="btn custom_btn btn-success-light"
                          @click="editNote(item.id, item.note)">
                        <i class="ti ti-save"></i>
                      </button>
                    </div>
                  </td>
                  <td class="p-1" style="width: 110px">
                    <select data-vv-name="status" v-model="item.status" class="form-control" style="height: 37px;width: 110px" @change="changeStatus(item.id, item.status)">
                      <option value="WAITING">{{ $t('waiting') }}</option>
                      <option value="PROCESSING">{{ $t('processing') }}</option>
                      <option value="DONE">{{ $t('done') }}</option>
                    </select>
                  </td>
                  <td style="max-width: 100px;">
                    <button class="btn custom_btn btn-warning-light" @click="openFile(item.attachment)" v-if="item.attachment && item.attachment.length">
                      {{ $t("show") }}
                    </button>
                    <input type="file" accept="*/*" @change="browseFile(item.id)" :ref="'fileRef' + item.id" class="form-control d-none">
                    <button class="btn custom_btn btn-success-light" @click="$refs['fileRef' + item.id][0].click()">
                      {{ $t("upload_attachment") }}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

              <p class="text-lg-center font-weight-bold" v-if="quizzes.length===0">{{ $t('no_data') }}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "All",
  data() {
    return {
      quizzes: [],
      quizId: null,
    };
  },
  mounted() {
    this.quizId = this.$route.params.id;
    this.getQuizDetails();
  },
  methods: {
    exportCSV() {
      let data = 'م, رقم الهاتف, الإيميل الأكاديمي, الرقم السري الأكاديمي, ملاحظة, الحالة, قيمة الإشتراك\n';
      let index = 0;
      let total = 0;
      this.quizzes.forEach(item => {
        data += ++index + ',';
        data += item.user.student.mobile + ',';
        data += item.email + ',';
        data += item.password + ',';
        data += item.note + ',';
        data += this.parseStatue(item.status) + ',';
        data += item.price + ',\n';

        total += item.price;
      });

      data += '\n\n';
      data += 'الإجمالى,,,,,,' + total + ',\n\n';

      const blob = new Blob(["\ufeff", data], {
        type: 'text/csv;charset=utf-8'
      });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', 'Report-' + Date.now() + '.csv');
      link.click();
    },
    openFile(url) {
      $(
          "<a href=" + `${encodeURI(url)}` + ' target="blank"></a>'
      )[0].click();
    },
    parseDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return ' - ';
    },
    parseStatue(status) {
      switch (status.toUpperCase()) {
        case "WAITING":
          return 'إنتظار';
        case "PROCESSING":
          return 'قيد التنفيذ';
        case "DONE":
          return 'إنتهي';
        default:
          return '';
      }
    },
    browseFile(id) {
      if (this.$refs['fileRef' + id][0].files.length) {
        let vm = this;
        vm.$swal({
          title: vm.$t("warning"),
          text: vm.$t("are_you_sure"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: vm.$t("yes"),
          cancelButtonText: vm.$t("no"),
        }).then(async (result) => {
          if (result.value) {
            await vm.uploadAttachment(id, this.$refs['fileRef' + id][0].files[0]);
          }

          this.$refs['fileRef' + id][0].value = '';
        });
      }
    },

    async getQuizDetails() {
      let vm = this;
      vm.$helper.showLoader();

      let response = await this.$store.dispatch('moduleAssignmentSubmission/getAssignmentDetails', {
        type: 1,
        doPagination: 0,
        paymentResult: "CAPTURED",
        subjectId: vm.quizId,
      }).catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      vm.$helper.hideLoader();

      if (response.data && response.data.status && response.data.data.data.length) {
        vm.quizzes = response.data.data.data;
      }
    },

    async uploadAttachment(id, file) {
      let vm = this;

      const payload = {};
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          $('#loader_message').text(percentCompleted + ' %')
        }
      };
      const formData = new FormData();
      formData.append('file', file);

      payload.data = formData;
      payload.config = config;
      payload.id = id;

      let response = await this.$store.dispatch('moduleAssignmentSubmission/uploadAssignment', payload).catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      if (response.data && response.data.status) {
        vm.$helper.showMessage('success', vm);
        vm.getQuizDetails();
      }
    },

    async editNote(id, note) {
      let vm = this;
      vm.$helper.showLoader();

      const payload = {
        id,
        data: {
          note,
        }
      };

      let response = await this.$store.dispatch('moduleAssignmentSubmission/updateAssignment', payload).catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      vm.$helper.hideLoader();

      if (response.data && response.data.status) {
        vm.$helper.showMessage('success', vm);
      }
    },

    async changeStatus(id, status) {
      let vm = this;
      vm.$helper.showLoader();

      const payload = {
        id,
        data: {
          status
        }
      };

      let response = await this.$store.dispatch('moduleAssignmentSubmission/updateAssignment', payload).catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      vm.$helper.hideLoader();

      if (response.data && response.data.status) {
        vm.$helper.showMessage('success', vm);
      }
    },
  },
};
</script>

<style scoped>
</style>
