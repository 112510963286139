<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t("dashboard") }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >
              {{ $t("all_lists") }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <h2 class="font-weight-bold fun_font text-center pt-3">
            {{ $t("all_lists") }}
          </h2>
          <div class="row m-3 align-items-end">
            <form
                class="col-sm-6 d-flex align-content-center justify-content-center"
                dir="ltr"
                @submit.prevent="getAllLists()">
              <button class="btn btn-success-light mx-2" type="submit" style="height: 53px">
                <i class="ti-search"></i>
              </button>

              <input
                  class="form-control"
                  type="search"
                  :placeholder="$t('search')"
                  v-model="query"
                  aria-label="Search"
              />
            </form>

            <div class="col-md-4">
              <label class="font-weight-bold">{{ $t("teacher") }}</label>
              <multiselect
                  :select-label="$t('multiselect_choose')"
                  :options="teachers"
                  :custom-label="$helper.getTitleValueLang"
                  track-by="id"
                  v-model="selectedTeacher"
                  data-vv-name="instructor"
                  :placeholder="$t('search')"
              ></multiselect>
              <span class="text-danger text-sm">{{ errors.first("instructor") }}</span>
            </div>
            <button
                class="btn btn-danger-light col-sm-2"
                v-if="$helper.hasAccessPermission('create-list')"
                @click="
                      $router.push({
                        name: $helper.getRouteFullName('add_lists'),
                      }).catch(()=>{})
                    "
                style="height: 53px"
            >
              <i class="ti-plus"></i>
              {{ $t("add") }}
            </button>
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive tableScroll">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t("name") }}</th>
                  <th class="text-left">{{ $t("teacher") }}<p>({{ $t("subject") }}) ({{ $t("type") }})</p></th>
                  <!-- <th class="text-left">{{ $t("subject") }}</th> -->
                  <th class="text-left">{{ $t("university") }}</th>
                  <th class="text-left">{{ $t("section") }}</th>
                  <!-- <th class="text-left">{{ $t("university") }}</th> -->
                  <th class="text-left">{{ $t("rating") }}</th>
                  <!-- <th class="text-center ">{{$t('all_register_students')}}</th> -->
                  <th class="text-left" width="100px">
                    {{ $t("operation") }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(list, index) in getLists" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <h5>{{ $helper.getTitleValueLang(list) }}</h5>
                  </td>
                  <td><h5>{{ $helper.getTitleValueLang(list.instructor) }}</h5>
                    <span>({{ $helper.getTitleValueLang(list.subject) }}) ({{ +list.isCourse === 1 ? 'مادة' : 'دورة تدريبية' }})</span>
                  </td>
                  <!-- <td>{{ $helper.getTitleValueLang(list.subject) }}</td> -->
                  <td>
                    <h5>{{
                        $helper.getTitleValueLang(
                            list.subject.academicYear.department.faculty
                                .university
                        )
                      }}</h5>
                  </td>
                  <td>
                    {{
                      $helper.getTitleValueLang(
                          list.subject.academicYear.department
                      )
                    }}
                  </td>
                  <!-- <td>
                     {{
                      $helper.getTitleValueLang(
                        list.subject.academicYear.department.faculty
                          .university
                      )
                    }}

                  </td> -->

                  <td class="text-center">
                    <h4 class="font-weight-bold">{{
                        list.AVGRatingAndCount.ratingAVG ? list.AVGRatingAndCount.ratingAVG.toFixed(1) + '/5' : ''
                      }}</h4>
                    <button v-if="list.AVGRatingAndCount.ratingAVG"
                            class="btn btn-outline-info btn-sm"
                            @click="getCourseRating(list.id)"
                    >
                      {{ $t("show_rating") }}
                    </button>
                    <span class="font-weight-bold text-dark text-small" v-else>{{ $t('no_rating') }}</span>

                  </td>
                  <!-- <td class="text-center">
                    <div class="font-weight-bold">{{ list.price }}</div>
                    <button class="btn btn-outline-warning btn-sm">
                      {{ $t("show_list") }}
                    </button>
                  </td>-->
                  <td class="text-center">
                    <div class="btn-group direction-inverse w-100">
                      <button
                          class="btn custom_btn btn-danger-light w-50"
                          @click="
                            $router.push({
                              name: $helper.getRouteFullName('all_courses'),
                              query: { list_id: list.id ,method:list.method,type:list.type},
                            }).catch(()=>{})
                          "
                      >
                        {{ $t("all_courses") }}
                      </button>
                      <!-- v-if="$helper.hasAccessPermission('update-list')" -->
                      <button
                          class="btn custom_btn btn-warning-light w-50"
                          @click="
                            $router.push({
                              name: $helper.getRouteFullName('add_courses'),
                              params: { list_id: list.id },query:{method:list.method,type:list.type},
                            }).catch(()=>{})
                          "
                      >
                        {{ $t("add_courses") }}
                      </button>
                    </div>

                    <br>
                    <div class="btn-group direction-inverse my-1 w-100">
                      <button
                          class="btn custom_btn btn-primary-light w-50"
                          @click="deleteList(list.id)"
                      >
                        {{ $t("delete") }}
                      </button>
                      <!-- v-if="$helper.hasAccessPermission('delete-list')" -->

                      <button
                          class="btn custom_btn btn-success-light w-50"
                          @click="
                            $router.push({
                              name: $helper.getRouteFullName('edit_lists'),
                              params: { id: list.id },
                            }).catch(()=>{})
                          "
                      >
                        {{ $t("edit") }}
                      </button>
                    </div>
                    <br>
                    <div class="btn-group direction-inverse w-100">
                      <button
                          class="btn custom_btn btn-blue-light w-100"
                          style="border-radius: 5px"
                          @click="
                            $router.push({
                              name: $helper.getRouteFullName('coupons'),
                              query:{courseId: list.id,name: $helper.getTitleValueLang(list)},
                            }).catch(()=>{})
                          "
                      >
                        {{ $t("discount_coupons") }}
                      </button>
                    </div>

                  </td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="getLists.length==0">{{ $t('no_data') }}</p>

            </div>


            <div class="row justify-content-between p-3">
              <div class="col-md-6" v-if="last_page > 1">
                <paginate
                    :page-count="last_page"
                    :page-range="1"
                    :click-handler="handlePaginate"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                >
                </paginate>
              </div>

              <div class="col-md-6">
                <div class="col-md-6">
                  <label class="font-weight-bold">{{ $t("items_in_page") }}</label>
                  <multiselect
                      :select-label="$t('multiselect_choose')"
                      :options="pager_items_choices"
                      v-model="itemsPerPage"
                      @input="getAllLists()"
                      :placeholder="$t('search')">
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="ratingModal" :title="$t('show_rating')">
      <u-animate-container>
        <div class="row">
          <div class="col-md-12" v-for="(rate , index) in ratingReviewList" :key="index">
            <!-- <u-animate
                    name="bounceInDown"
                    :delay="index/10+'s'"
                    duration="1s"
                    :iteration="1"
                    :offset="0"
                    animateClass="animated"
                    :begin="false"
            > -->
            <div class="card mb-2 mt-0 p-3">
              <div class="card-body">
                <div class="row direction text-left">
                  <div class="col-md-6">
                    <div class="d-flex">
                      <div style="flex: 1">
                        <img :src="rate.courseSubscribe.student.img" style="border-radius:70px;"
                             width="70px" height="70px" alt="" v-if="rate.courseSubscribe.student.img">
                        <img :src="require('@/assets/logo.png')" style="border-radius:70px;" v-else
                             width="70px" height="70px" alt="">
                      </div>
                      <div class="pr-1 pl-1" style="flex: 5">
                        <!-- <h4>{{$t('student_name')}}</h4> -->
                        <h5>{{ rate.courseSubscribe.student.name }}</h5>
                        <p>{{ $helper.formatDate(rate.date) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 text-right">
                    <div v-html="$helper.getRate(Math.round(rate.rate))"></div>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-10" style="text-align:right;">
                    <p class="font-weight-bold">{{ rate.reviewText }} </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- </u-animate> -->
          </div>
        </div>
      </u-animate-container>
    </sweet-modal>
  </div>
</template>

<script>
import {SweetModal} from "sweet-modal-vue";

export default {
  name: "All",
  components: {SweetModal},
  data() {
    return {
      list_types: ['subject', 'assignment'],
      list_methods: ['recorded', 'live'],
      lists: [],
      teachers: [],
      selectedTeacher: null,
      query: null,
      time: "",
      start_date: "",
      end_date: "",
      ratingReviewList: [],

      pager_items_choices: [5, 10, 20, 50, 100],
      itemsPerPage: 10,
      page: 1,
      last_page: 1,
      numRows: 1,
      config: {mode: "range", dateFormat: "Y-m-d"},
    };
  },
  watch: {
    time: function (n, o) {
      let start_date = null;
      let end_date = null;

      if (n) {
        let arr = n.split(" to ");
        start_date = arr[0];
        end_date = arr[1] ? arr[1] : arr[0];
        // end_date = arr[1];
      }
      console.log(start_date, end_date, n);
      this.start_date = start_date;
      this.end_date = end_date;
      end_date ? this.getAllLists() : "";
    },
  },
  computed: {
    getLists: function () {
      return this.lists.filter((item) => {
        if (this.selectedTeacher) {
          if (item.instructor.id == this.selectedTeacher.id) return item;
        } else {
          return item;
        }
      });
    },
  },
  mounted() {
    this.getAllLists();
    this.getAllTeachers();
  },
  methods: {
    showRating() {
      this.$refs.ratingModal.open();
    },
    handlePaginate(pageNum) {
      this.page = pageNum;
      this.getAllLists();
    },
    prepareFilters() {
      return {
        doPagination: 1,
        numPerPage: this.itemsPerPage,
        page: this.page,
        searchKey: this.query ? this.query : "",
        //1 or 0 or both
      };
    },
    getAllTeachers() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      let dispatch = this.$store.dispatch(
          "moduleTeacher/getAllTeachers",
          filters
      );
      dispatch
          .then((response) => {
            vm.teachers = this.$store.getters["moduleTeacher/getAllTeachers"];
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    getAllLists() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.startFrom = this.start_date
          ? this.start_date
          : "1992-11-05 00:00:00.000";
      filters.startTo = this.end_date
          ? this.end_date
          : "2992-11-05 00:00:00.000";
      // filters.method="both";
      let dispatch = this.$store.dispatch("moduleList/getAllList", filters);
      dispatch
          .then((response) => {
            vm.lists = this.$store.getters["moduleList/getAllList"];
            vm.numRows = response.data.data.result.numRows;
            vm.last_page = response.data.data.result.numPages;
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    deleteList(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },
    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleList/removeList", {
        id: id,
      });
      dispatch
          .then((response) => {
            vm.$helper.hideLoader();
            let index = vm.lists.findIndex((e) => {
              return e.id == id;
            });
            this.$store.commit("moduleList/REMOVE_LIST", index);
            this.$helper.showMessage("success", this);
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },

    getCourseRating(id) {
      let vm = this;
      vm.$helper.showLoader();
      let filters = {
        id: id,
      };
      let dispatch = this.$store.dispatch(
          "moduleRatingReview/findRatingReviewByCourseID",
          filters
      );
      dispatch
          .then((response) => {
            vm.ratingReviewList = response.data.data.ratingAndReview;
            console.log(vm.ratingReviewList);
            vm.showRating();
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    }
  },
};
</script>

<style scoped>
.tableScroll {
  width: 100%;
  max-width: auto;
  overflow-x: scroll;
}
</style>
