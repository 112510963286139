<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#" @click.prevent>{{ $t('teacher_profile') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{ $t('notify_subscriber') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('notify_subscriber') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('choose_from_your_courses') }}</label>
                  <multiselect
                      :select-label="$t('multiselect_choose')"
                      :options="courses" :custom-label="$helper.getTitleValueLang"
                      track-by="id" v-model="selectedCourse"
                      :placeholder="$t('search')"></multiselect>
                  <span class="text-danger text-sm">{{ errors.first('choose_from_your_courses') }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('notification_title') }}</label>
                  <input type="text"
                         v-validate="'required'" data-vv-name="notification_title"
                         v-model="dataModel.notification_title"
                         class="form-control"
                         :placeholder="$t('notification_title')">
                  <span class="text-danger text-sm">{{ errors.first('notification_title') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('notification_body') }}</label>
                  <input type="text"
                         v-validate="'required'" data-vv-name="notification_body" v-model="dataModel.notification_body"
                         class="form-control"
                         :placeholder="$t('notification_body')">
                  <span class="text-danger text-sm">{{ errors.first('notification_body') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('attachment_if_exist') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('image') }}</label>
                  <input type="file" accept="image/*" ref="myImg" @change="setImage()"
                         data-vv-name="img"
                         class="form-control"
                         :placeholder="$t('image')">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('file') }}</label>
                  <input type="file" accept="*/*" ref="myFile" @change="setFile()"
                         data-vv-name="file"
                         class="form-control"
                         :placeholder="$t('file')">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('video') }}</label>
                  <small class="font-weight-bold text-danger"> ({{ $t('max_video') }})</small>
                  <input type="file" accept="video/mp4, video/quicktime, video/x-ms-wmv, video/avi, video/x-flv"
                         ref="myVideo"
                         @change="setVideo()"
                         data-vv-name="video"
                         class="form-control"
                         :placeholder="$t('video')">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('url_link') }}</label>
                  <input type="text"
                         data-vv-name="url_link"
                         v-model="dataModel.url"
                         class="form-control"
                         :placeholder="$t('url_link_if_exist')">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard_container p-4">
      <div class="col-md-12 text-center">
        <button class="btn btn-danger-light" @click="submitForm()">
          {{ $t('send_notification') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: "Notify",
  components: {VueCropper},
  data() {
    return {
      courses: [],
      selectedCourse: null,
      dataModel: {
        notification_title: '',
        notification_body: '',
        url: '',
        img: null,
        file: null,
        video: null,
      },
    }
  },
  mounted() {
    this.getCourses();
  },
  methods: {
    getCourses() {
      let vm = this;
      vm.$helper.showLoader();
      let userInfo = localStorage.getItem('userInfo');
      let dispatch = this.$store.dispatch('moduleTeacher/getInstructorCourses', {
        id: JSON.parse(userInfo).instructorId,
      });
      dispatch.then((response) => {
        vm.courses = response.data.data;
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    setImage() {
      this.dataModel.img =  this.$refs.myImg.files[0];
      console.log(this.dataModel.img);
    },
    setVideo() {
      this.dataModel.video =  this.$refs.myVideo.files[0];
      console.log(this.dataModel.video);
    },
    setFile() {
      this.dataModel.file =  this.$refs.myFile.files[0];
      console.log(this.dataModel.file);
    },
    submitForm() {
      this.$validator.errors.clear();
      this.$validator.validateAll().then(result => {
        if (this.selectedCourse == null) {
          this.$validator.errors.add({
            field: "choose_from_your_courses",
            msg: this.$t('choose_from_your_courses')
          });
          result = false;
        }
        if (result) {
          let vm = this;
          vm.$helper.showLoader();
          const payload = {};
          payload.config = {
            headers: {
              'content-type': 'multipart/form-data'
            },
          };
          const formData = new FormData();
          formData.append('notificationTitle',this.dataModel.notification_title);
          formData.append('notificationBody',this.dataModel.notification_body);
          this.dataModel.img && formData.append('img',this.dataModel.img);
          this.dataModel.file && formData.append('file',this.dataModel.file);
          this.dataModel.video && formData.append('vedio',this.dataModel.video);
          this.dataModel.url && formData.append('zoom',this.dataModel.url);
          payload.data = formData;
          payload.courseId = this.selectedCourse.id;
          let dispatch = this.$store.dispatch('moduleTeacher/notifySubscribers', payload);
          dispatch.then((response) => {
            vm.$helper.showMessage('success', vm);
            vm.$helper.hideLoader();
          }).catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
        } else {
          $('html, body').animate({scrollTop: 19}, 'slow');
          this.$helper.printValidationError(this.$validator.errors, this);
        }
      })
    },
  }
}
</script>

<style scoped>

</style>