<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t("dashboard") }}</a>
            </li>
            <li
              class="breadcrumb-item active"
              aria-current="page"
              @click.prevent
            >
              {{ $t("all_assignments") }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">
                {{ $t("all_assignments") }}
              </h2>
            </div>
            <div class="col-md-8 text-center text-md-left">
              <div class="row">
                <div class="col-md-6 m-2">
                  <label class="font-weight-bold">{{ $t("list") }}</label>
                  <multiselect
                    :options="lists"
                    :custom-label="$helper.getTitleValueLang"
                    @input="clearLessonSelection"
                    track-by="id"
                    v-model="selectedList"
                    :select-label="$t('multiselect_choose')"
                    data-vv-name="list"
                    :placeholder="$t('search')"
                  ></multiselect>
                  <span class="text-danger text-sm">{{
                    errors.first("list")
                  }}</span>
                </div>
                <div class="col-md-4 m-2">
                  <label class="font-weight-bold">{{ $t("course") }}</label>
                  <multiselect
                    :options="lessons"
                    :custom-label="$helper.getTitleValueLang"
                    track-by="id"
                    :select-label="$t('multiselect_choose')"
                    v-model="selectedLesson"
                    data-vv-name="course"
                    @input="getAllAssignments"
                    :placeholder="$t('search')"
                  ></multiselect>
                  <span class="text-danger text-sm">{{
                    errors.first("course")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="dashboard_container_header">
                <div class="dashboard_fl_1"></div>
                <!-- <div class="dashboard_fl_2">
                  <ul class="mb0">
                    <li class="list-inline-item">
                      <form
                        class="form-inline my-2 my-lg-0"
                        dir="ltr"
                        @submit.prevent="getAllAssignments()"
                      >
                        <input
                          class="form-control"
                          type="search"
                          :placeholder="$t('search')"
                          v-model="query"
                          aria-label="Search"
                        />
                        <button class="btn my-2 my-sm-0" type="submit">
                         
                          <i class="ti-search"></i>
                        </button>
              
                      </form>
                    </li>
                   
                    
                    
                  </ul>
                </div> -->
              </div>
            </div>
          </div>

          <div class="row justify-content-end mb-2 ml-2">
              
            <div
              dir="ltr"
              class="col-3 text-center mt-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer items-center font-medium"
            >
         
              <span class=""
                >{{$t('items_of')}}{{ page * itemsPerPage - (itemsPerPage - 1) }} {{$t('items_to')}}
                {{
                  page * itemsPerPage -
                  (itemsPerPage - 1) +
                  (assignments.length - 1)
                }}
                {{$t('items_for')}} {{ numRows }}</span
              >
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <div class="col-3 text-center">
              <label class="font-weight-bold">{{ $t("items_in_page") }}</label>
              <multiselect
                :options="pager_items_choices"
                v-model="itemsPerPage"
                 :select-label="$t('multiselect_choose')"
                @input="getAllAssignments()"
                :placeholder="$t('search')"
              ></multiselect>
            </div>
            <!-- </div> -->
          </div>
          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">{{ $t("code") }}</th>
                    <th class="text-left">{{ $t("subject_name") }}</th>
                    <th class="text-left">{{ $t("course") }}</th>
                    <th class="text-left">{{ $t("student_name") }}</th>
                    <th class="text-left">{{ $t("status") }}</th>
                    <th class="text-left" width="100px">
                      {{ $t("operation") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(assignment, index) in assignments" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ assignment.lesson.course.code }}</td>
                    <td>
                      {{ $helper.getTitleValueLang(assignment.lesson.course) }}
                    </td>
                    <td>{{ $helper.getTitleValueLang(assignment.lesson) }}</td>
                    <td>
                      {{ assignment.student.name }}
                      <p>
                        {{ $helper.formatDate(assignment.student.updatedAt) }}
                      </p>
                    </td>
                    <td>
                      {{
                        submissionStatusValues[
                          assignment.status ? assignment.status : 2
                        ]
                      }}
                      <p>{{assignment.statusComments?assignment.statusComments:''}}</p>
                    </td>
                    <td>
                      <div class="btn-group direction-inverse">
                        <button
                          class="btn custom_btn btn-warning-light"
                          v-if="
                            $helper.hasAccessPermission(
                              'show-student-assignment'
                            )
                          "
                          @click="openFile(assignment)"
                        >
                          {{ $t("show_file") }}
                        </button>
                        <button
                          class="btn custom_btn btn-success-light"
                          v-if="$helper.isInstructor()"
                          @click="openAssignmentEvaluate(assignment)"
                        >
                          {{ $t("review") }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
           <p class="text-lg-center font-weight-bold" v-if="assignments.length==0">{{$t('no_data')}}</p>

            </div>

            <div class="text-center" v-if="last_page > 1">
              <paginate
                :page-count="last_page"
                :page-range="1"
                :click-handler="handlePaginate"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sweet-modal
      ref="evalute"
      class="p-0"
      overlay-theme="dark"
      modal-theme="dark"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 mt-3 mr-1">
            <div class="form-group">
            <label class="font-weight-bold">{{ $t("status") }}</label>
            <multiselect
              :options="submissionStatusValues"
              v-validate="'required'"
              data-vv-name="status"
               :select-label="$t('multiselect_choose')"
              v-model="submissionStatus.status"
              class="form-control"
              :placeholder="$t('status')"
            ></multiselect>

            <span class="text-danger text-sm" id="status">{{
              errors.first("status")
            }}</span>
          </div>
          </div>
          <div class="col-12 mr-1">
            <div class="form-group">
            <label class="font-weight-bold">{{ $t("comment") }}</label>
            <!-- type="text" -->

            <textarea
              v-validate="'required'"
              data-vv-name="statusComments"
              v-model="submissionStatus.statusComments"
              class="form-control"
              :placeholder="$t('comment')"
            />
            <span class="text-danger text-sm" id="statusComments">{{
              errors.first("statusComments")
            }}</span>
          </div>
          </div>

           <div class="col-md-12 text-center">
                <span class="flex-d text-danger text-sm" id="error_handle"
                  ></span
                >
              </div>

          <div class="col-12 mt-3">
            <button class="btn my-2 my-sm-0 text-secondary" type="submit" @click="validateEvalutionForm">
              {{ $t("add") }}
            </button>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
export default {
  name: "All",
  components: { SweetModal, SweetModalTab },
  data() {
    return {
      assignments: [],
      lists: [],
      lessons: [],
      selectedList: null,
      selectedLesson: null,
      submissionStatus: {},
      submissionStatusValues: [
        this.$t("submission_reject"),
        this.$t("submission_accept"),
        this.$t("submission_pending"),
      ],
      query: null,
      pager_items_choices: [1, 5, 10, 20, 50, 100],
      itemsPerPage: 10,
      page: 1,
      last_page: 1,
      numRows: 1,
    };
  },
  mounted() {
    this.getAllAssignments();
    this.getAllLists();
  },
  methods: {
    openFile(assignment) {
      console.log(assignment);
      $(
        "<a href=" + `${assignment.attachments}` + ' target="blank"></a>'
      )[0].click();
    },
    openAssignmentEvaluate(assignment) {
      this.submissionStatus.id=assignment.id;
      this.$refs.evalute.open();
    },

    clearLessonSelection() {
      let vm = this;
      vm.selectedLesson = null;
      vm.lessons = vm.selectedList ? vm.selectedList.lessons : [];
    },
    handlePaginate(pageNum) {
      this.page = pageNum;
      this.getAllAssignments();
    },
    prepareFilters() {
      return {
        doPagination: 1,
        numPerPage: this.itemsPerPage,
        page: this.page,
        searchKey: this.query ? this.query : "",
      };
    },
    validateEvalutionForm(){
      console.log(this.submissionStatus['status'],this.submissionStatus['statusComments'],'all_submission 316');
      //validate fields
      if(!this.submissionStatus.status)
        $('#status').text(this.$t('isRequired'));
      else
        $('#status').text('');

      if(!this.submissionStatus.statusComments)
        $('#statusComments').text(this.$t('isRequired'));
      else
        $('#status').text('');

      if(this.submissionStatus.statusComments && this.submissionStatus.status){
         this.addEvalution();
      }
    },
    getAllLists() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.startFrom = this.start_date
        ? this.start_date
        : "1992-11-05 00:00:00.000";
      filters.startTo = this.end_date
        ? this.end_date
        : "2992-11-05 00:00:00.000";
       filters.method = 1;
      let dispatch = this.$store.dispatch("moduleList/getAllList", filters);
      dispatch
        .then((response) => {
          vm.lists = this.$store.getters["moduleList/getAllList"];

          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },

    getAllLessons() {
      let vm = this;
      let course_id = this.selectedList ? this.selectedList.id : "";
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.type = "both"; //1 or 0 or both'
      filters.course_id = course_id;

      let dispatch = this.$store.dispatch(
        "moduleCourse/getAllCourses",
        filters
      );
      dispatch
        .then((response) => {
          vm.lessons = this.$store.getters["moduleCourse/getAllCourses"];
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },

    getAllAssignments() {
      let vm = this;
      let helper = vm.$helper;

      let data = "";
      //if this page is allowed
      if (helper.isInstructor()) {
        data = helper.getLocalStorage("userInfo");
      } else {
        data = helper.getLocalStorage("adminInfo");
      }

      helper.showLoader();
      let filters = vm.prepareFilters();
      let payload = {};
      payload.filters = filters;
      //check the user type to get all assignmebts for admin or only teacher assignemnts
      if (data.instructorId) {
        payload.filters.instructorId = data.instructorId;
      }
      if (vm.selectedLesson) {
        payload.filters.lessonId = vm.selectedLesson.id;
      }

      payload.filters.orderBy = "DESC";

      let dispatch = this.$store.dispatch(
        "moduleAssignmentSubmission/getAllAssignmentSubmissions",
        payload
      );
      dispatch
        .then((response) => {
          vm.assignments = this.$store.getters[
            "moduleAssignmentSubmission/getAllAssignmentSubmission"
          ];
          vm.$helper.hideLoader();
          vm.numRows = response.data.data.result.numRows;
          vm.last_page = response.data.data.result.numPages;
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
    addEvalution(){
                let vm = this;
                vm.$helper.showLoader();
                let payload = {};
                payload.id=this.submissionStatus.id;

                payload.data={};
                payload.data.status=this.submissionStatusValues.indexOf(this.submissionStatus.status);
                payload.data.statusComments=this.submissionStatus.statusComments;
                 
                vm.$store.dispatch('moduleAssignmentSubmission/evaluateSubmission', payload)
                    .then(() => {
                          vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)

                        //clear data from modal
                        delete this.submissionStatus.statusComments;
                        delete this.submissionStatus.status;
                        delete this.submissionStatus.id;

                        //close modal
                         this.$refs.evalute.close();
                        this.$router.go();
                      })
                    .catch(error => {
                      if(error.response){
                          let error_type = error.response.data;
 
          if (
            error_type.message === "ASSIGNMENT_SUBMISSION_UNEDITABLE"
          )
            $("#error_handle").text(
              vm.$helper.getErrorValueLang(error_type.data)
            );
                      }

                      this.submissionStatus.statusComments="";
                        this.submissionStatus.status="";
                        vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);
                      
                    })
            
    }
  },
};
</script>

<style scoped>
</style>