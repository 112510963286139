<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('discounts') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row p-2">
            <div class="col-12 pt-3 text-center">
              <h2 class="font-weight-bold fun_font">{{ $t('discounts') }}</h2>
              <p>التحكم فى الخصومات على السلة</p>
            </div>
            <div class="col-12 pt-3 text-right">
              <button
                  class="btn btn-danger-light mx-2"
                  @click="$router.push({name:$helper.getRouteFullName('addDiscount')}).catch(()=>{});"
              >{{ $t('add_discount') }}
              </button>
            </div>
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('num_of_courses_in_cart') }}</th>
                  <th class="text-left">{{ $t('value') }}</th>
                  <th class="text-left" style="width: 25px;">{{ $t('discount_type') }}</th>
                  <th class="text-left">{{ $t('start_date') }}</th>
                  <th class="text-left">{{ $t('end_date') }}</th>
                  <th class="text-left">{{ $t('max_user') }}</th>
                  <th class="text-left" style="width: 30px">{{ $t('operation') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(discount , index) in discounts" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ discount.numOfCourses }}</td>
                  <td>{{ discount.value }}{{ discount.type === 1 ? '' : ' %' }}</td>
                  <td style="width: 25px;"><h6 class="badge badge-secondary">{{ $t(discount.type === 1 ? 'static' : 'percentage') }}</h6></td>
                  <td>{{ parseDate(discount.startDate) }}</td>
                  <td>{{ parseDate(discount.endDate) }}</td>
                  <td>{{ discount.maxUser }}</td>
                  <td>
                    <div class="btn-group direction-inverse">
                      <button
                          class="btn custom_btn btn-primary-light"

                          @click="deleteCoupon(discount.id)"
                      >{{ $t('delete') }}
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="discounts.length==0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {cloneDeep} from "lodash";
import moment from "moment";

export default {
  name: "All",

  data() {
    return {
      discounts: [],
    }
  },
  mounted() {
    this.getAllDiscounts();
  },
  methods: {
    parseDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return ' - ';
    },
    async getAllDiscounts() {
      let vm = this;
      vm.$helper.showLoader();
      await this.$store.dispatch("moduleList/getAllDiscounts", {courseId: vm.courseId}).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
      vm.discounts = cloneDeep(this.$store.getters["moduleList/getAllList"]);
      vm.$helper.hideLoader();
    },
    deleteCoupon(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },
    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch("moduleList/removeDiscount", {id})
          .then(value => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage('success', vm, 'تم حذف الخصم');
            vm.discounts = cloneDeep(vm.coupons).filter(d => d.id != id);
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
  },
};
</script>

<style>
.multiselect__select {
  height: 55px;
}

.multiselect__tags {
  min-height: 55px;
  padding: 15px 40px 0 8px;
}
</style>