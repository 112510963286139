import { readyException } from 'jquery';
import moment from 'moment'

const CryptoJS = require("crypto-js");

const helper = {
    replaceNullWithSingleQuote(obj) {
        Object.keys(obj).forEach(function (key) {
            if (obj[key] === null || obj[key] === "null") {
                obj[key] = '';
            }
        });
        return obj;
    },
    splitFirst(str) {
        let username= str.split(' ');
        if(username.length>0)return username[0];
        else return str;
    },
    showLoader(message = null) {
        // show initial loading
        const appLoading = document.getElementById('loader_overlay');
        const appLoadingText = document.getElementById('loader_message');
        if (appLoading) {
            appLoading.style.display = "block";
            if (message) {
                appLoadingText.innerText = message
            }
        }
    },
    hideLoader() {
        // show initial loading
        const appLoading = document.getElementById('loader_overlay');
        const appLoadingText = document.getElementById('loader_message');
        if (appLoading) {
            appLoading.style.display = "none";
            appLoadingText.innerText = "";
        }
    },
    setLocalStorage(key, data) {
        localStorage.setItem(key, JSON.stringify(data))
        return JSON.parse(localStorage.getItem(key))
    },
    getLocalStorage(key) {
        return JSON.parse(localStorage.getItem(key))
    },
    removeLocalStorage(key) {
        return localStorage.removeItem(key)
    },
    removeAllLocalStorage() {
         helper.removeLocalStorage('userType');
         helper.removeLocalStorage('tokenAdmin');
         helper.removeLocalStorage('adminInfo');
         helper.removeLocalStorage('userInfo');
         helper.removeLocalStorage('token');

    },
    prepareObjectToSend: (request_data) => {
        return request_data;
    },
    isInstructor(){
        let data=helper.getLocalStorage('userInfo');
        if(data && data.roles && data.roles.includes("instructor"))
            return true
        else
        return false;
    },
    findInstructorData(){
        if(helper.isInstructor()){
            let data=helper.getLocalStorage('userInfo');
            return data;
        }else{
            return '';
        }
    },
    isAdmin(){
        let data=helper.getLocalStorage('adminInfo');
        if(data && data.roles && data.roles.includes("admin"))
            return true
        else
        return false;
    },
    //switch between components admin,instructor
    getRouteFullName(name){
        let fullName;
        if(helper.isInstructor())
        fullName= name+'_teacher';

        else if(helper.isAdmin())
        fullName= name;

        return fullName;
    },
    removeFromArray: (array, remove) => {
        const index = array.indexOf(remove);
        if (index > -1) {
            array.splice(index, 1);
        }
        return array
    },
    getTitleValueLang(event){
        // console.log(event);
        let lang=JSON.parse(localStorage.getItem("language"));
        if(lang=='ar')
            return event.name_ar;
        else
            return event.name_en;

    },

    getLogoName(){
        let lang=JSON.parse(localStorage.getItem("language"));
        if(lang=='ar')
            return require('../assets/logo.png')
        else
            return require('../assets/logo-en.png')

    },
    getErrorValueLang(event){

        // console.log(event);
        let lang=JSON.parse(localStorage.getItem("language"));
        //return validation error for items
        if(lang=='ar')
            return event.ar;
        else
            return event.en;

    },
    generateRandomValues() {
        return Math.random().toString(36).slice(-8);
    },
    diffForhuman(date) {
        if (!date) return '';
        let delta = Math.round((new Date() - new Date(date)) / 1000);

        let minute = 60;
        let hour = minute * 60;
        let day = hour * 24;
        let week = day * 7;

        let fuzzy = '';

        if (delta < 30) {
            fuzzy = 'just then.';
        } else if (delta < minute) {
            fuzzy = delta + ' seconds ago.';
        } else if (delta < 2 * minute) {
            fuzzy = 'a minute ago.'
        } else if (delta < hour) {
            fuzzy = Math.floor(delta / minute) + ' minutes ago.';
        } else if (Math.floor(delta / hour) == 1) {
            fuzzy = '1 hour ago.'
        } else if (delta < day) {
            fuzzy = Math.floor(delta / hour) + ' hours ago.';
        } else if (delta < day * 2) {
            fuzzy = 'yesterday';
        } else if (delta < week) {
            fuzzy = Math.floor(delta / day) + 1 + ' days ago.';
        } else {
            fuzzy = ''
        }

        return fuzzy
    },
    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    getRate(val) {
        val = parseFloat(val);
        if (val == 5) {
            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                            </ul>`;
        } else if (val == 1) {
            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                            </ul>`;
        } else if (val == 2) {
            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                            </ul>`;
        } else if (val == 3) {
            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                            </ul>`;
        } else if (val == 4) {
            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                            </ul>`;
        } else if (val == 5) {
            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                            </ul>`;
        } else if (val > 0 && val <= 2.5) {
            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star-half-o text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                            </ul>`;
        } else if (val > 2.5 && val <= 4.5) {

            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="fa fa-star-half-o text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                            </ul>`;
        } else {

            return `<ul class="p-0 mb-1">
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                                <li class="list-inline-item"><i
                                        class="far fa-star text-warning"></i>
                                </li>
                            </ul>`;
        }
    },
    getDays(lang = 'ar') {
        let days_ar = [
            'الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة', 'السبت'
        ];

        let days_en = [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
        ];

        return lang=='ar'?days_ar:days_en;
    },
    hasAccessPermission: (permission) => {
        return true;
        let myPermission = JSON.parse(localStorage.getItem('myPermission'));
        if (permission == 'ALLOW_ALL') {
            return true
        } else if (myPermission.includes(permission)) {

            return true;
        } else {

            return false;
        }
        // if (user.permissions.includes(permission)) return true;
    },
    deleteMulti(ids, collection) {
        return _.dropWhile(collection, function (n) {
            return _.includes(ids, n.id);
        });
    },
    deleteSingle(id,collection){
        return collection.splice(collection.map(e => e.id).indexOf(id),1);
    },
    //if you need to delete unused property from json 'object'
    deletUnusedObj(neededobj,collection){
         const newCollection = Object.keys(collection).reduce((object, key) => {
             if (neededobj.includes(key) === true) {
               object[key] = collection[key];
            }
            return object
        }, {});
        return newCollection;
    },
    getTranslatedData(model, l = 'ar') {
        let lang = _.map(model.details, function (o) {
            if (o.lang == l) return o;
        });
        lang = _.without(lang, undefined);
        return lang[0];
    },
    JSON_to_URLEncoded(element, key, list) {
        var list = list || [];
        if (typeof (element) == 'object') {
            for (var idx in element)
                JSON_to_URLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list);
        } else {
            list.push(key + '=' + encodeURIComponent(element));
        }
        return list.join('&');
    },
    showMessage: (type, vm, message = '') => {
        vm.$notify({
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            horizontalAlign: 'center',
            position:'left',
            verticalAlign: 'top',
            title: `${type == 'success' ? vm.$t('success') : vm.$t('error')}`,
            text: message || `${type == 'success' ? vm.$t('success_msg') : vm.$t('error_msg')}`,
            type: type,
            // duration:-1

        });
    },
    validationGroupingData: (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    },
    addErrors: (inputs, errors) => {
        for (let input in inputs) {
            try {
                // console.log(input)
                document.getElementById(input + '_error').innerText = ' ';
            } catch (e) {

                console.log(e)
            }
        }
        for (const [key, value] of Object.entries(errors)) {
            try {
                // console.log(key)
                $(`#${key}_error`).text(value[0]);
                setTimeout(() => {
                    document.getElementById(key + '_error').innerText = value[0];
                }, 300)
            } catch (e) {
                console.log(e)
            }
        }
    },
    handleError: (error, vm) => {
        // request error cause of offline using
        if (!navigator.onLine) return;

        if (error.response) {
            // The request was made and the server responded with a status code

            // validation error over api
            if (error.response.status == 422) {
                let validationApi = error.response.data.data;
                //  vm.$notify({
                //     // icon: "ti-info",
                //     horizontalAlign: 'center',
                //     verticalAlign: 'top',
                //     title: error.response.data.message?vm.$t('JOI_VALIDATION_INVALID_DATA'):'Server Error Code ' +error.response.status,
                //     text:( validationApi.reduce((total, n) => total + ", \n" + (n.message?helper.getErrorValueLang(JSON.parse(n.message)):{}), '')),
                //     type: 'error',
                //     duration:-1

                // });

                for (let [key, value] of Object.entries(validationApi)) {
                    // console.log("sola" ,JSON.parse(value.message));

                    let validator = value.message?JSON.parse(value.message):{};
                    //key name
                    let field=validator?validator.key:'';
                    //message which shown underlined (parsed to locale)
                    let message=validator?helper.getErrorValueLang(validator):'';

                    vm.$notify({
                        // icon: "ti-info",
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        position:'left',
                        title: error.response.data.message?vm.$t('JOI_VALIDATION_INVALID_DATA'):'Server Error Code ' +error.response.status,
                        text:field + " " + message,
                        type: 'error',
                        duration:-1

                    });
                    console.log(field,message);
                    vm.$validator.errors.add({
                        field: field,
                        msg: message
                    })
                }
                return;
            }

            // un authenticated
            if (error.response.status == 401) {
                helper.removeLocalStorage(helper.isAdmin()?'tokenAdmin':'token');
                helper.removeLocalStorage(helper.isAdmin()?'adminInfo':'userInfo');
                helper.removeLocalStorage('userType');
                vm.$router.push({name: 'login'});
                return;
            }

            // permission denied
            if (error.response.status == 403) {
                helper.removeLocalStorage(helper.isAdmin()?'tokenAdmin':'token');
                helper.removeLocalStorage(helper.isAdmin()?'adminInfo':'userInfo');
                helper.removeLocalStorage('userType');
                vm.$router.push({name: 'pageError403'})
                return;
            }

            // default 500 message
            let message = vm.$t('server_error');
            if (error.response.status == 401) message = vm.$t('server_error_401');
            if (error.response.status == 403) message = vm.$t('server_error_403');
            if (error.response.status == 404) message = vm.$t('server_error_404');

            vm.$notify({
                // icon: "ti-info",
                horizontalAlign: 'center',
                verticalAlign: 'top',
                position:'left',
                text: error.response.data.data?helper.getErrorValueLang(error.response.data.data):'Server Error Code ' +error.response.status,
                title: `${message}`,
                type: 'error',
                duration:-1

            });
            // console.log(error.response.data, error.response.status, error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            vm.$notify({
                horizontalAlign: 'center',
                verticalAlign: 'top',
                position:'left',
                text: `${vm.$t('error')}`,
                title: `${vm.$t('server_error')}`,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                type: 'error',
                duration:-1

            });
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            vm.$notify({
                horizontalAlign: 'center',
                verticalAlign: 'top',
                position:'left',
                title: `${vm.$t('error')}`,
                text: error.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                type: 'error',
                duration:-1


            });
            // console.log('Error', error.message);
        }
    },
    formatDate: (value, format = 'YYYY-MM-DD hh:mm a') => {
        if (value) {
            return moment(new Date(value)).format(format)
        }
    },
    isDateGreater: (small_date,large_date)=>{
        let smallDate=moment(new Date(small_date));
        let largeDate=moment(new Date(large_date));

         return largeDate.isSameOrAfter(smallDate,'day');
        // return largeDate.diff(smallDate) >= 0

    },

    printValidationError(errors,vm){
        for (const element of errors.items) {

            vm.$notify({
                // icon: "ti-info",
                horizontalAlign: 'center',
                verticalAlign: 'top',
                position:'left',
                text: element.field +" "+element.msg,
                title: vm.$t('JOI_VALIDATION_INVALID_DATA'),
                type: 'error',
                duration:-1

            });
        }
    },

    canAdminEdit(){
        if(helper.isAdmin()){
            let data=helper.getLocalStorage('adminInfo');
            if(["AhmedAmin1@gmail.com","AliAdmin1@gmail.com"].includes(data.email))
                return true;
            else
                return false;

        }
    },
    diffTwoDates: (vm,from_date, to_date=new moment()) => {
        from_date=moment(new Date(from_date));
        let duration= Math.abs(moment.duration(to_date.diff(from_date)).as('minutes'));
        let msg=vm.$t('minutes');
        if(duration>(60*24)){duration=duration/(60*24); msg=vm.$t('days');}
        else if(duration>59){duration=duration/60; msg=vm.$t('hours');}
        return {duration:Math.round(duration),msg: msg};
    },
    splitHostname() {
        let result = {};
        let url = window.location.href;

        url = url.replace(new RegExp(/^\s+/), "");
        url = url.replace(new RegExp(/\s+$/), "");
        url = url.replace(new RegExp(/\\/g), "/");
        url = url.replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i), "");
        url = url.replace(new RegExp(/^www\./i), "");
        url = url.replace(new RegExp(/\/(.*)/), "");
        if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
            url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), "");
        } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
            url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), "");
        }
        result.hasSubdomain = (url.match(new RegExp(/\./g))) ? true : false;
        result.data = url.split('.');
        return result;
    },
    saveAccountCredential(data) {
        let key = 'accountApi';
        let accountApi = JSON.parse(localStorage.getItem(key));
        if (accountApi) {
            // append data
            _.forEach(data, (v, k) => {
                accountApi[k] = v
            })
            localStorage.setItem(key, JSON.stringify(accountApi));
        } else {
            localStorage.setItem(key, JSON.stringify(data));
        }
    },
    validateFormErrors(vm) {
        return vm.errors.any();
    },
    getCurrentBranch() {
        return 1;
    },
    checkNestedObjectProp(obj /*, level1, level2, ... levelN*/) {
        let args = Array.prototype.slice.call(arguments, 1);

        for (let i = 0; i < args.length; i++) {
            if (!obj || !obj.hasOwnProperty(args[i])) {
                return false;
            }
            obj = obj[args[i]];
        }
        return true;
    },

    getMapDarkStyle() {
        return [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#1d2c4d"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#8ec3b9"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#1a3646"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#4b6878"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#64779e"
                    }
                ]
            },
            {
                "featureType": "administrative.province",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#4b6878"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#334e87"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#023e58"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#283d6a"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#6f9ba5"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#1d2c4d"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#023e58"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#3C7680"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#304a7d"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#98a5be"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#1d2c4d"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#2c6675"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#255763"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#b0d5ce"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#023e58"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#98a5be"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#1d2c4d"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#283d6a"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#3a4762"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#0e1626"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#4e6d70"
                    }
                ]
            }
        ]
    }
};
export default helper;
