<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('main_settings') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">

            <div class="row m-0 d-flex justify-content-between align-items-center m-1">
              <h5><i class="fa fa-photo-video"></i> فيديو "عن جامعتي" </h5>

              <div>
                <button class="btn btn-success-light mx-1" @click="displayVideo()">
                  عرض
                </button>
                <button class="btn btn-danger-light mx-1" @click="$refs.introVideo.click()">
                  تغيير
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <input type="file" accept="video/*" @change="previewVideo()" ref="introVideo" multiple
           data-vv-name="video"
           class="form-control d-none">
    <sweet-modal ref="modal" class="p-0" overlay-theme="dark" modal-theme="dark" @close="closeVideo()">
      <video src="https://jam3ty.net:30001/public/vedio/intro.mp4" controls style="height: 100%;width: 100%;" ref="videoPlayer"></video>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";

export default {
  name: "settings",
  components: { SweetModal },
  data() {
    return {
      dataModel: {},
    }
  },
  mounted() {

  },
  methods: {
    closeVideo() {
      this.$refs.videoPlayer.pause();
      this.$refs.videoPlayer.currentTime = 0;
    },
    displayVideo() {
      this.$refs.modal.open();
    },
    previewVideo() {
      if (this.$refs.introVideo.files.length) {
        let vm = this;
        vm.$swal({
          title: vm.$t("warning"),
          text: vm.$t("are_you_sure"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: vm.$t("yes"),
          cancelButtonText: vm.$t("no"),
        }).then(async (result) => {
          if (result.value) {
            await vm.uploadVideo(this.$refs.introVideo.files[0]);
          }

          this.$refs.introVideo.value = '';
        });
      }
    },
    uploadVideo(file) {
      let vm = this;
      vm.$helper.showLoader();

      const payload = {};
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          $('#loader_message').text(percentCompleted + ' %');
        }
      };
      const formData = new FormData();
      formData.append('vedio', file);

      payload.data = formData;
      payload.config = config;

      this.$store.dispatch('moduleCommon/uploadIntroVideo', payload).then(() => {
        vm.$helper.hideLoader();
        vm.$helper.showMessage('success', vm);
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
  }
}
</script>

<style scoped>

</style>
