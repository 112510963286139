import axios from "@/axios.js"
import requests from "@/requests.js"
import router from '@/router'
import jwt from "../../http/requests/auth/jwt/index.js"

export default {
    getBoard({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_BOARD}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addBoardItem({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.GET_BOARD}/add`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeBoardItem({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${requests.GET_BOARD}/${payload.id}/delete`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAds({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_ADS}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addAd({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.GET_ADS}/add`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeAd({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${requests.GET_ADS}/${payload.id}/delete`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    notifyUsers({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.NOTIFY_USERS}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addAdmin({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.ADD_ADMIN, payload)
                .then((response) => {
                    commit('ADD_ADMIN', Object.assign(payload, {id: response.data.data.id}))
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchAdmin({commit}, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.ALL_ADMINS, {
                params: filters
            })
                .then((response) => {
                    if (response.data.status) {
                        commit('SET_ADMINS', response.data.data.data)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    findAdmin({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_ADMIN}/${payload.id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateAdmin({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`${requests.UPDATE_ADMIN}/${payload.id}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeAdmin({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_ADMIN}/${payload.id}`, payload)
                .then((response) => {
                    commit('REMOVE_ADMIN', payload.id)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    loginJWT({commit}, payload) {

        return new Promise((resolve, reject) => {
             jwt.login(requests.AUTH_JWT_LOGIN_ADMIN, payload)
                .then(response => {
                    response = response.data;

                    // If there's user data in response
                    if (response.data) {

                        if(response.data.roles.includes("admin")){
                        // set userType
                        localStorage.setItem("userType", "admin");

                        // Set accessToken
                        localStorage.setItem("tokenAdmin", response.data.accessToken);

                        // Update user details
                        commit('UPDATE_ADMIN_INFO', response.data, {root: true});

                        // Set bearer token in axios
                        commit("SET_BEARER", response.data.accessToken);

                        // Navigate User to homepage
                        router.push(router.currentRoute.query.to || '/admin');

                        // location.reload()
                        // let permissions = _.map(response.data.admin.role.permissions, 'name');
                        // permissions = permissions ? permissions : [];
                        // Set accessToken
                        // localStorage.setItem("myPermission", JSON.stringify(permissions));

                        resolve(response)
                    //user not admin
                    }else{
                        reject({message:'server_permission_not_admin'})
                    }

                    } else {
                        reject({message:'server_parse_error'})
                    }

                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    setBearer({commit}, token) {
        // Set bearer token in axios
        commit("SET_BEARER", token);

    },
    getLinks({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_LINKS_ADMIN}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addLink({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.GET_LINKS}/add`, data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateLink({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(`${requests.GET_LINKS}/${payload.id}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeLink({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${requests.GET_LINKS}/${payload.id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getVideos({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_VIDEOS}/${payload.type}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getVideosCategories({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_VIDEOS}/${payload.type}/categories`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addVideo({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.GET_VIDEOS}/add`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeVideo({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${requests.GET_VIDEOS}/${payload.id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
