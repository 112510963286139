<template>
    <div>
        <!--{{categories}}-->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" @click.prevent>{{$t('dashboard')}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{$t('add_university')}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="dashboard_container">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <h2 class="font-weight-bold fun_font text-center">{{$t('add_university')}}</h2>
                        </div>
                    </div>
                    <div class="dashboard_container_body p-2">

                        <div class="row">

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_ar')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_ar" v-model="dataModel.data.name_ar"
                                           class="form-control"
                                           :placeholder="$t('title_ar')">
                                    <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_en')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_en" v-model="dataModel.data.name_en"
                                           class="form-control"
                                           :placeholder="$t('title_en')">
                                    <span class="text-danger text-sm">{{ errors.first('name_en') }}</span>
                                </div>
                            </div>

                         </div>
                            <div class="row">
                                <div class="col-12 pt-3">
                                     <h3 class="font-weight-bold fun_font text-center">{{$t('add_faculty')}}</h3>
                                  </div>
                                 <div class="col-md-12" :key="index" v-for="(faculty , index) in dataModel.data.faculties">
                                     <div class="card p-2">
                                          <div class="row align-items-center">
                                        <div class="col-md-4">
                                                <label class="font-weight-bold">{{$t('title_ar')}}</label>
                                                <input type="text" class="form-control" v-model="faculty.faculty_name_ar">
                                         </div>
                                           <div class="col-md-4">
                                                <label class="font-weight-bold">{{$t('title_en')}}</label>
                                                <input type="text" class="form-control" v-model="faculty.faculty_name_en">
                                         </div>
                                         <div class="col-md-4 mx-auto mt-3">
                                                <button class="btn btn-warning-light btn-sm" @click="removeFacultyDom(index)">
                                            {{$t('delete')}}
                                        </button>
                                         </div>
                                         </div>
                                   </div>
                                </div>
                                  <div class="col-md-12 mt-1">
                                        <button class="btn btn-warning-light btn-sm" @click="addFacultyDom()">
                                            {{$t('add_faculty')}}
                                        </button>
                                    </div>
                            </div>
                            <div class="col-md-12 text-center">
                                <button class="btn btn-danger-light" @click="submitForm()">
                                    {{$t('add')}}
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Add",
        data() {
            return {
                dataModel: {
                    data:{
                    name_ar: '',
                    name_en: '',
                    faculties:[],
                }
                }
            }
        },
        mounted() {

        },
        methods: {
             addFacultyDom() {
                this.dataModel.data.faculties.push({
                    faculty_name_ar: '', faculty_name_en: ''
                })
            },
             removeFacultyDom(index) {
                this.dataModel.data.faculties.splice(index,1);
            },
            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // if form have no errors
                        this.addUniversity();
                    } else {
                        // form have validation errors
                     $('html, body').animate({ scrollTop: 19 }, 'slow');
                     this.$helper.printValidationError(this.$validator.errors,this);
                    }
                })
            },
            addUniversity() {
                let vm = this;
                vm.$helper.showLoader();
                const payload = vm.dataModel;
                //server take this input as strigified
                payload.data.faculties=JSON.stringify(payload.data.faculties);
               

                vm.$store.dispatch('moduleUniversity/addUniversity', payload)
                    .then(() => {
                         //to parse the object to the normal case from the stringify
                       vm.dataModel.data.faculties=JSON.parse(vm.dataModel.data.faculties);
                        vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)
                        vm.$router.push({name: 'all_universities'}).catch(()=>{})
                    })
                    .catch(error => {
                         //to parse the object to the normal case from the stringify
                          vm.dataModel.data.faculties=JSON.parse(vm.dataModel.data.faculties);
                        vm.$helper.handleError(error, vm);
                        vm.$helper.hideLoader()
                    })
            },
        }
    }
</script>

<style scoped>

</style>