<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#" @click.prevent>{{ $t('dashboard') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{ $t('edit_list') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('edit_list') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('subject_name') }}</label>
                  <input type="text"
                         v-validate="'required|min:3|max:30'" data-vv-name="name_ar" v-model="dataModel.name_ar"
                         class="form-control"
                         :placeholder="$t('subject_name')">
                  <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('what_you_learn') }}</label>
                  <vue-editor dir="ltr" v-validate="'required|min:5'" v-model="dataModel.whatYouWillLearn"></vue-editor>
                  <span class="text-danger text-sm">{{ errors.first('whatYouWillLearn') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('description') }}</label>
                  <vue-editor dir="ltr" v-validate="'required|min:5'" v-model="dataModel.desc"></vue-editor>
                  <span class="text-danger text-sm">{{ errors.first('desc') }}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('price') }}</label>
                  <input type="text"
                         v-validate="'required|numeric'" data-vv-name="price" v-model="dataModel.price"
                         class="form-control"
                         :placeholder="$t('price')">
                  <span class="text-danger text-sm">{{ errors.first('price') }}</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('price_before_discount') }}</label>
                  <input type="text"
                         v-validate="'required|numeric|greaterThanPrice'" data-vv-name="priceBeforeDiscount"
                         v-model="dataModel.priceBeforeDiscount"
                         class="form-control"
                         :placeholder="$t('price_before_discount')">
                  <span class="text-danger text-sm">{{ errors.first('priceBeforeDiscount') }}</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('lectures_count') }}</label>
                  <input type="number"
                         v-validate="'required|numeric'" data-vv-name="numOfLessons"
                         v-model="dataModel.numOfLessons"
                         class="form-control"
                         :placeholder="$t('lectures_count')">
                  <span class="text-danger text-sm">{{ errors.first('numOfLessons') }}</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('course_keyword') }}</label>
                  <input type="text"
                         data-vv-name="course_keyword"
                         v-validate="'min:2'"
                         v-model="dataModel.course_keyword"
                         class="form-control"
                         :placeholder="$t('course_keyword')">
                  <span class="text-danger text-sm">{{ errors.first('course_keyword') }}</span>
                </div>
              </div>

              <!-- <div class="col-md-3">
                  <div class="form-group">
                      <label class="font-weight-bold">{{$t('teacher')}}</label>
                       <multiselect
                          :options="instructors" :custom-label="$helper.getTitleValueLang"
                          track-by="id" v-model="selectedInstructor"  v-validate="'required'" data-vv-name="instructor"
                          :placeholder="$t('search')"></multiselect>
                      <span class="text-danger text-sm">{{ errors.first('instructor') }}</span>
                  </div>
              </div> -->


              <div class="col-md-3" v-if="method==1">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('start_date') }}</label>
                  <flat-pickr
                      v-validate="'required'" data-vv-name="startDate"
                      v-model="dataModel.startDate"
                      class="form-control"
                      :placeholder="$t('start_date')"/>
                  <span class="text-danger text-sm">{{ errors.first('startDate') }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="dashboard_container">
                <div class="row">
                  <div class="col-12 pt-3">
                    <h2 class="font-weight-bold fun_font text-center">{{ $t('subject') }}</h2>
                  </div>
                </div>
                <div class="dashboard_container_body p-2">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="font-weight-bold">{{ $t('university') }}</label>

                        <multiselect
                            :select-label="$t('multiselect_choose')"
                            :options="universities" :custom-label="$helper.getTitleValueLang"
                            track-by="id" v-model="universityId" @input="getAllFaculties(universityId.id)"
                            :placeholder="$t('search')"></multiselect>
                        <span class="text-danger text-sm">{{ errors.first('list_type_id') }}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="font-weight-bold">{{ $t('faculty') }}</label>
                        <!-- <select v-validate="'required'" data-vv-name="list_type_id"
                                v-model="facultyId"  @input="getAllCategories(facultyId)"
                                class="form-control">
                            <option :key="index" v-for="(list_type , index) in faculties" :value="list_type.id">
                                {{$helper.getTitleValueLang(list_type)}}
                            </option>
                        </select> -->
                        <multiselect
                            :select-label="$t('multiselect_choose')"
                            :options="faculties" :custom-label="$helper.getTitleValueLang"
                            track-by="id" v-model="facultyId" @input="getAllCategories(facultyId.id)"
                            :placeholder="$t('search')"></multiselect>
                        <span class="text-danger text-sm">{{ errors.first('list_type_id') }}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="font-weight-bold">{{ $t('section') }}</label>

                        <multiselect
                            :select-label="$t('multiselect_choose')"
                            :options="categories" :custom-label="$helper.getTitleValueLang"
                            track-by="id" v-model="DepartmentId" @input="getAllYears(DepartmentId.id)"
                            :placeholder="$t('search')"></multiselect>
                        <span class="text-danger text-sm">{{ errors.first('list_type_id') }}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="font-weight-bold">{{ $t('academicyear') }}</label>
                        <!-- <select v-validate="'required'" data-vv-name="list_type_id"
                                v-model="yearId" @input="getAllSubjects(yearId)"
                                class="form-control">
                            <option  :key="index" v-for="(list_type , index) in years" :value="list_type.id">
                                 {{$helper.getTitleValueLang(list_type)}}
                            </option>
                        </select> -->
                        <multiselect
                            :select-label="$t('multiselect_choose')"
                            :options="years" :custom-label="$helper.getTitleValueLang"
                            track-by="id" v-model="yearId" @input="getAllsubjects(yearId.id)"
                            :placeholder="$t('search')"></multiselect>
                        <span class="text-danger text-sm">{{ errors.first('list_type_id') }}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="font-weight-bold">{{ $t('subject') }}</label>
                        <!-- <select v-validate="'required'" data-vv-name="subject_id"
                                v-model="dataModel.subjectId"
                                class="form-control">
                            <option :key="index" v-for="(list_type , index) in subjects" :value="list_type.id">
                                 {{$helper.getTitleValueLang(list_type)}}
                            </option>
                        </select> -->
                        <multiselect
                            :select-label="$t('multiselect_choose')"
                            :options="subjects" :custom-label="$helper.getTitleValueLang"
                            track-by="id" v-model="dataModel.subjectId" v-validate="'required'" data-vv-name="subjectId"
                            :placeholder="$t('search')"></multiselect>
                        <span class="text-danger text-sm">{{ errors.first('subjectId') }}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="font-weight-bold">{{ $t('type') }}</label>
                        <multiselect
                            :select-label="$t('multiselect_choose')"
                            :options="['true', 'false']" :custom-label="typeLabel"
                            track-by="id" v-model="dataModel.isCourse" v-validate="'required'" data-vv-name="type"
                            :placeholder="$t('type')"></multiselect>
                        <span class="text-danger text-sm">{{ errors.first('type') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="dashboard_container">
                <div class="row">
                  <div class="col-12 pt-3 d-flex justify-content-center align-items-center">
                    <h2 class="font-weight-bold fun_font text-center">{{ $t('lectures_times') }}</h2>
                    <span class="font-weight-light pb-2 px-2">({{ $t('optional') }})</span>
                  </div>
                  <div class="col-12 d-flex justify-content-end">
                    <button class="btn btn-blue-light mx-3" @click="addTimeTableItem()">إضافة عنصر</button>
                  </div>
                </div>
                <div class="dashboard_container_body p-2">
                  <div class="row align-items-center" v-for="(item , index) in dataModel.timetable" :key="index">
                    <div class="col-sm-4 form-group">
                      <label class="font-weight-bold">{{ $t('date_time') }} *</label>
                      <flat-pickr
                          v-validate="'required'" data-vv-name="date_time"
                          v-model="item.date"
                          class="form-control"
                          :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
                          :placeholder="$t('date_time')"/>
                      <span class="text-danger text-sm">{{ errors.first(`date_time${index}`) }}</span>
                    </div>
                    <div class="col-sm-5 form-group" v-show="item.type === 1">
                      <label class="font-weight-bold">{{ $t('link') }}</label>
                      <input type="url"
                             data-vv-name="link"
                             v-model="item.link"
                             class="form-control"
                             :placeholder="$t('link')">
                    </div>
                    <div class="col-sm-2 form-group">
                      <label class="font-weight-bold">{{ $t('type') }}</label>
                      <div>
                        <input type="radio" v-model="item.type" :value="0" class="form-control" :id="'type0_' + index">
                        <label :for="'type0_' + index">مسجلة</label><br>
                        <input type="radio" v-model="item.type" :value="1" class="form-control" :id="'type1_' + index">
                        <label :for="'type1_' + index">اونلاين</label><br>
                      </div>
                      <span class="text-danger text-sm">{{ errors.first(`date_time_type${index}`) }}</span>
                    </div>
                    <div class="col-sm-1">
                      <button class="btn btn-black btn-circle" @click="deleteTimeTableItem(index)"><i class="ti ti-trash"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- live =>1 , record=>0 -->

      <div class="col-md-12" v-if="dataModel.type==1">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('attachment_course_info') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('attachement_price') }}</label>
                  <input type="number"
                         data-vv-name="attachement_price"
                         v-model="dataModel.attachement_price"
                         class="form-control"
                         :placeholder="$t('attachement_price')">
                  <span class="text-danger text-sm">{{ errors.first('attachement_price') }}</span>
                </div>
              </div>

              <!-- <div v-if="imgSrc">
                  <vue-cropper
                          ref="cropper"
                          :src="imgSrc"
                          :aspect-ratio="1 / 1"
                          alt="Source Image">
                  </vue-cropper>
              </div> -->


              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('file') }}</label>
                  <input type="file" @change="previewAttachement()" ref="myAttachement"
                         data-vv-name="attachement"
                         class="form-control"
                         :placeholder="$t('file')">
                  <span class="text-danger text-sm">{{ errors.first('attachement') }}</span>
                  <a class="btn btn-danger-light" v-if="attachement_path" @click="displayImage(attachement_path)">
                    <i class="ti ti-file"></i>
                    {{ $t('show_file') }}
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('intro_video') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('image') }}</label>
                  <input type="file" accept="image/*" @change="previewImage()" ref="myImage"
                         data-vv-name="image"
                         class="form-control"
                         :placeholder="$t('image')">
                  <span class="text-danger text-sm">{{ errors.first('image') }}</span>
                  <a class="btn btn-danger-light" v-if="image_path" @click="displayImage(image_path)">
                    <i class="ti ti-file"></i>
                    {{ $t('show_file') }}
                  </a>
                </div>
                <!-- <div v-if="imgSrc">
                    <vue-cropper
                            ref="cropper"
                            :src="imgSrc"
                            :aspect-ratio="1 / 1"
                            alt="Source Image">
                    </vue-cropper>
                </div> -->
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('video') }}</label>
                  <small class="font-weight-bold text-danger"> ({{ $t('max_video') }})</small>
                  <input type="file" accept="video/mp4, video/quicktime, video/x-ms-wmv, video/avi, video/x-flv"
                         @change="previewVideo()" ref="myVideo"
                         data-vv-name="video"
                         class="form-control"
                         :placeholder="$t('video')">
                  <span class="text-danger text-sm">{{ errors.first('video') }}</span>
                  <a class="btn btn-danger-light" v-if="vedio_path" @click="displayVedio(vedio_path)">
                    <i class="ti ti-file"></i>
                    {{ $t('show_file') }}
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-12 text-center">
                <button class="btn btn-danger-light" @click="submitForm()">
                  {{ $t('edit') }}
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <sweet-modal ref="modal" class="p-0" overlay-theme="dark" modal-theme="dark">
      <div class="container">
        <div class="row">
          <div class="col-mds-12 w-100" v-if="videoID">
            <vue-vimeo-player ref="player" :video-id="videoID" @ready="onReady" :player-height="height"
                              style="width: 100%">
            </vue-vimeo-player>
          </div>
        </div>
      </div>
    </sweet-modal>

  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import {vueVimeoPlayer} from 'vue-vimeo-player'
import {SweetModal} from 'sweet-modal-vue'


export default {
  name: "Edit",
  components: {SweetModal, VueCropper, vueVimeoPlayer},
  data() {
    return {
      dataModel: {
        code: '',
        name_ar: '',
        name_en: '',
        desc: '',
        prerequisiteText: '',
        whatYouWillLearn: '',
        numOfLessons: '',
        numOfHours: '',
        price: '',
        priceBeforeDiscount: '',
        startDate: '',
        type: 0,

        // instructorId:{},
        course_keyword: null,
        attachement_price: null,
        attachments: null,
        subjectId: {},
        img: null,
        vedio: null,
        timetable: [],
        isCourse: 'true',
      },

      imgSrc: null,
      method: 0,
      // selectedInstructor:{},
      instructors: [],
      image_path: null,
      vedio_path: null,
      attachement_path: null,
      categories: [],
      universities: [],
      universityId: 0,
      faculties: [],
      facultyId: 0,
      categories: [],
      DepartmentId: 0,
      years: [],
      yearId: 0,
      subjects: [],
      videoID: null,
      height: 300,
      options: {},
      playerReady: false

    }
  },
  mounted() {
    this.getAllUniversities()
    this.findList();
    //    this.getAllTeachers()
  },
  created() {
    this.$validator.extend(
        'greaterThanPrice', {
          getMessage: field => this.$t('price_before_discount') + '  ' + this.$t('greater_than_price'),
          validate: (value) => {
            // value must be > zero
            if (value > this.dataModel.price) return true;
            return false;
          }
        });
  },
  methods: {
    typeLabel(o) {
      if (o == 'true') {
        return 'مادة';
      } else if (o == 'false') {
        return 'دورة تدريبية';
      }
    },
    addTimeTableItem() {
      this.dataModel.timetable.push({date: '', link: '', type: 0});
    },
    deleteTimeTableItem(i) {
      this.dataModel.timetable = this.dataModel.timetable.filter((value, index) => index !== i);
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    pause() {
      this.$refs.player.pause();
    },
    previewImage() {
      this.dataModel.img = this.$refs.myImage.files[0];
      // let image = this.$refs.myImage.files[0];
      // this.readFile(image)
    },
    displayImage(image_path) {

      let mywindow = window.open(image_path, '_blank');
      mywindow.moveTo(0, 0);
    },
    displayVedio(vedio_path) {
      let code = vedio_path.split('/').slice(-1);
      this.videoID = code;

      this.$refs.modal.open();
      // console.log(code);
      //  let  mywindow = window.open('https://vimeo.com/'+code, '_blank');
      //   mywindow.moveTo(0, 0);

    },
    readFile(file) {
      if (file.type.indexOf('image/') === -1) {
        console.log('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        console.log('Sorry, FileReader API not supported');
      }
    },
    setImageToModel() {
      let cropper = this.$refs.cropper;
      if (cropper) {
        let data = cropper.getCroppedCanvas().toDataURL();
        this.dataModel.img = this.convertToBlob(data);
      }
    },
    convertToBlob(data) {
      let vm = this;
      let block = data.split(";");
      let contentType = block[0].split(":")[1];
      let realData = block[1].split(",")[1];
      let image_file = vm.b64toBlob(realData, contentType);
      let extension = '';
      try {
        extension = image_file.type.split('/')[1];
      } catch (e) {
        extension = 'jpg'
      }
      let image_file_name = `image_name_example.${extension}`;
      image_file = vm.blobToFile(image_file, image_file_name);
      return image_file

    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.lastModified = new Date().getTime();
      theBlob.name = fileName;
      return theBlob;
      // return new File(theBlob, fileName);
    },
    previewVideo() {
      this.dataModel.vedio = this.$refs.myVideo.files[0]
    },
    previewAttachement() {
      this.dataModel.attachments = this.$refs.myAttachement.files[0]
    },
    prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: this.query ? this.query : '',
        // universityId:this.$route.query.id?this.$route.query.id:'',
      };
    },
    //   getAllTeachers() {
    //     let vm = this;
    //     vm.$helper.showLoader();
    //     let filters = vm.prepareFilters();
    //     let dispatch = this.$store.dispatch('moduleTeacher/getAllTeachers', filters);
    //     dispatch.then((response) => {
    //         vm.instructors = this.$store.getters['moduleTeacher/getAllTeachers'];
    //         vm.$helper.hideLoader();
    //     }).catch((error) => {
    //         vm.$helper.hideLoader();
    //         vm.$helper.handleError(error, vm);
    //     });
    // },

    getAllsubjects(id) {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.yearId = id;
      let dispatch = this.$store.dispatch('moduleSubject/getAllSubjects', filters);
      dispatch.then((response) => {
        vm.subjects = this.$store.getters['moduleSubject/getAllSubjects'];
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);

      });
    },

    getAllYears(id) {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.DepartmentId = id;
      let dispatch = this.$store.dispatch('moduleAcedemicyears/getAllAcedemicyears', filters);
      dispatch.then((response) => {
        vm.years = this.$store.getters['moduleAcedemicyears/getAllAcedemicyears'];

        vm.$helper.hideLoader();
      }).catch((error) => {

        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    getAllCategories(id) {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.facultyId = id;
      let dispatch = this.$store.dispatch('moduleCategory/getAllCategories', filters);
      dispatch.then((response) => {
        vm.categories = this.$store.getters['moduleCategory/getAllCategories'];
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    getAllUniversities() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      let dispatch = this.$store.dispatch('moduleUniversity/getAllUniversities', filters);
      dispatch.then((response) => {
        vm.universities = this.$store.getters['moduleUniversity/getAllUniversities'];

        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);

      });
    },
    getAllFaculties(id) {
      console.log(id, this.universityId);
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.universityId = id;
      let dispatch = this.$store.dispatch('moduleFaculty/getAllFaculties', filters);
      dispatch.then((response) => {
        vm.faculties = this.$store.getters['moduleFaculty/getAllFaculties'];
        console.log(response, vm.faculties, "dsaasdasdasddsasa");
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);

      });
    },
    submitForm() {
      this.dataModel.name_en = this.dataModel.name_ar;
      this.dataModel.code = this.dataModel.name_ar;

      this.$validator.validateAll().then(result => {

        this.dataModel.timetable.length && this.dataModel.timetable.forEach((item, index) => {
          if (!item.date || item.date.length === 0) {
            this.$validator.errors.add({
              field: `date_time${index}`,
              msg: this.$t('date_time')
            });

            result = false;
          }

          if (![0, 1].includes(+item.type)) {
            this.$validator.errors.add({
              field: `date_time_type${index}`,
              msg: this.$t('date_time_type')
            });

            result = false;
          }
        });

        if (result) {
          // if form have no errors
          this.editList();
        } else {
          // form have validation errors
          $('html, body').animate({scrollTop: 19}, 'slow');
          this.$helper.printValidationError(this.$validator.errors, this);
        }
      })
    },
    findList() {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch('moduleList/findList', {
        id: vm.$route.params.id
      });
      dispatch.then((response) => {
        vm.$helper.hideLoader();
        let list = response.data.data.course;
        this.dataModel.code = list.name_ar;
        this.dataModel.name_ar = list.name_ar;
        this.dataModel.name_en = list.name_ar;
        this.dataModel.desc = list.desc;
        this.dataModel.prerequisiteText = list.prerequisiteText || '';
        this.dataModel.whatYouWillLearn = list.whatYouWillLearn;
        this.dataModel.numOfLessons = list.numOfLessons;
        this.dataModel.numOfHours = list.numOfHours || 0;
        this.dataModel.price = list.price;
        this.dataModel.priceBeforeDiscount = list.priceBeforeDiscount;
        this.dataModel.startDate = list.startDate;
        this.dataModel.course_keyword = list.course_keyword;
        this.dataModel.attachement_price = list.attachement_price;
        this.dataModel.timetable = list.times || [];
        this.dataModel.isCourse = list.isCourse ? 'true' : 'false';

        // this.selectedInstructor=list.instructor,
        this.dataModel.type = list.type || 0;
        this.method = list.method || 0;
        this.image_path = list.img;
        this.vedio_path = list.vedio;
        this.attachement_path = list.attachments;

        this.universityId = list.subject.academicYear.department.faculty.university;
        this.facultyId = list.subject.academicYear.department.faculty;
        this.DepartmentId = list.subject.academicYear.department;
        this.yearId = list.subject.academicYear;
        delete list.subject.academicYear;
        this.dataModel.subjectId = list.subject;

      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },

    editList() {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};
      // vm.setImageToModel();
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          $('#loader_message').text(percentCompleted + ' %')
        }
      };

      const formData = new FormData();
      //  vm.dataModel.instructorId=vm.selectedInstructor.id;
      _.forEach(vm.dataModel, (value, key) => {
        if (key == 'subjectId')
          value = value ? value.id : 0;

        if (key == 'timetable' && value.length) {
          value = JSON.stringify(value.map(item => {
            return {id: item.id, date: item.date, link: item.link, type: item.type};
          }));
        }

        if (value != null)
          formData.append(key, value)
      });

      payload.data = formData;
      payload.config = config;
      payload.id = vm.$route.params.id;
      vm.$store.dispatch('moduleList/updateList', payload)
          .then(() => {
            vm.$helper.hideLoader()
            vm.$helper.showMessage('success', vm)
            vm.$router.push({name: this.$helper.getRouteFullName('all_lists')}).catch(() => {
            })
            $('#loader_message').text('')
          })
          .catch(error => {
            vm.$helper.hideLoader()
            vm.$helper.handleError(error, vm);

          })
    },
  }
}
</script>

<style>

.sweet-modal.is-visible .sweet-buttons, .sweet-modal.is-visible .sweet-content {
  padding: 0 !important;
  direction: ltr;
  margin-top: 60px;
}

.sweet-modal .sweet-box-actions {
  z-index: 9;
}

iframe {
  width: 100%;
}
</style>
