/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
  SET_FACULTIES(state, faculties) {
        state.faculties = faculties
    },
  REMOVE_FACULTIES(state, index) {
    state.faculties.splice(index,1)
    },
}
