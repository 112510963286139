import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllAssignmentSubmission({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.LIST_ASSIGNMENT_SUBMISSION_ID}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_ASSIGNMENT_SUBMISSION', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    //get all submissions with filters
    getAllAssignmentSubmissions({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.LIST_ASSIGNMENT_SUBMISSION}`, {
                params: payload.filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_ASSIGNMENT_SUBMISSION', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addAssignmentSubmission({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.ADD_ASSIGNMENT_SUBMISSION}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateAssignmentSubmission({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_ASSIGNMENT_SUBMISSION}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeAssignmentSubmission({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_ASSIGNMENT_SUBMISSION}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    evaluateSubmission({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.EVALUTE_ASSIGNMENT_SUBMISSION}/${payload.id}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeAssignmentSubmissionAttachments({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_ASSIGNMENT_SUBMISSION_ATTACHEMNT}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAssignments({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_ASSIGNMENT_ADMIN}`, {
                params: params
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAssignmentDetails({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_ASSIGNMENT}`, {
                params: params
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    uploadAssignment({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`${requests.GET_ASSIGNMENT}/${payload.id}/upload`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateAssignment({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(`${requests.GET_ASSIGNMENT}/${payload.id}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
