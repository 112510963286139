import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
  createSubscribe({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(requests.CREATE_SUBSCRIBE, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCourseSubscriptions({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(requests.GET_COURSE_SUBSCRIBES(payload.id))
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeSubscriptionPoints({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(requests.CHANGE_SUBSCRIBE_POINTS(payload.id), payload.data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getSubscriptionsOfCourse({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(requests.GET_SUBSCRIPTIONS_OF_COURSE(payload.id), {
        params: payload.params,
      })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    });
  },

  getAllSubscriptionsReport({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(requests.GET_ALL_SUBSCRIPTIONS_REPORT, {
        params: params,
      })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
    });
  },
}
