<template>
    <div>
        <!--{{categories}}-->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" @click.prevent>{{$t('dashboard')}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{$t('edit_academicyear')}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="dashboard_container">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <h2 class="font-weight-bold fun_font text-center">{{$t('edit_academicyear')}}</h2>
                        </div>
                    </div>
                    <div class="dashboard_container_body p-2">

                        <div class="row">

                            <div class="col-md-4" >
                                <label class="font-weight-bold">{{$t('section')}}</label>
                                <multiselect
                                        :options="lists" :custom-label="$helper.getTitleValueLang"
                                        track-by="id" v-model="selected_list"
                                         :select-label="$t('multiselect_choose')"
                                     data-vv-name="departmentId" v-validate="'required'"
                                        :placeholder="$t('search')"></multiselect>
                                    <span class="text-danger text-sm">{{ errors.first('departmentId') }}</span>
                            </div>


                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_ar')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_ar" v-model="dataModel.data.name_ar"
                                           class="form-control"
                                           :placeholder="$t('title_ar')">
                                    <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_en')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_en" v-model="dataModel.data.name_en"
                                           class="form-control"
                                           :placeholder="$t('title_en')">
                                    <span class="text-danger text-sm">{{ errors.first('name_en') }}</span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12 mb-2">
                        <button class="btn btn-danger-light  btn-sm" @click="addLevelDom()">
                            {{$t('add_level')}}
                        </button>
                    </div>
                    <div class=" col-md-12" v-for="(level , index) in dataModel.levels">
                        <div class="card p-2">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="font-weight-bold">{{$t('level_name')}}</label>
                                        <input type="text" class="form-control" v-model="level.name">
                                    </div>
                                    <div class="col-md-12 mt-1">
                                        <button class="btn btn-warning-light btn-sm" @click="addLevelSubjectDom(index)">
                                            {{$t('add_subject')}}
                                        </button>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4" v-for="(subject , index) in level.subjects">
                                                <label class="font-weight-bold">{{$t('subject_name')}}</label>
                                                <input type="text" class="form-control" v-model="subject.name">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    <div class="col-md-12 text-center">
                        <button class="btn btn-danger-light" @click="submitForm()">
                            {{$t('edit')}}
                        </button>
                    </div>
                </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "Add",
        data() {
            return {
                dataModel: {
                    data:{
                    name_ar: '',
                    name_en: '',
                    departmentId:0,
                    }
                },
                lists:[],
                selected_list:null,

            }
        },
        mounted() {
            this.getAllLists();
        },
        methods: {
             findYear() {
                let vm = this;
                vm.$helper.showLoader();
                let dispatch = this.$store.dispatch('moduleAcedemicyears/findAcedemicyear', {
                    id: vm.$route.params.id
                });
                dispatch.then((response) => {
                    vm.dataModel.data.name_ar = response.data.data.academicYear.name_ar;
                    vm.dataModel.data.name_en = response.data.data.academicYear.name_en;
                    vm.dataModel.data.departmentId = response.data.data.academicYear.departmentId;
                    vm.selected_list=response.data.data.academicYear.department;
                    vm.$helper.hideLoader();
                }).catch((error) => {
                    vm.$helper.hideLoader();
                    vm.$helper.handleError(error, vm);

                });
            },
            getAllLists() {
                let vm = this;
                vm.$helper.showLoader();
                let filters = vm.prepareFilters();
                let dispatch = this.$store.dispatch('moduleCategory/getAllCategories', filters);
                dispatch.then((response) => {
                    vm.lists = this.$store.getters['moduleCategory/getAllCategories'];
                     vm.findYear();
                     vm.$helper.hideLoader();

                }).catch((error) => {
                    vm.$helper.hideLoader();
                    vm.$helper.handleError(error, vm);
                 });
            },
             prepareFilters() {
                return {
                doPagination:0,
                numPerPage:1000,
                page:1,
                searchKey:this.query?this.query:''
                };
            },

            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // if form have no errors
                        this.editFaculty();
                    } else {
                        // form have validation errors
 $('html, body').animate({ scrollTop: 19 }, 'slow');
 this.$helper.printValidationError(this.$validator.errors,this);
                    }
                })
            },
            editFaculty() {
                let vm = this;
                vm.$helper.showLoader();
                const payload = vm.dataModel;
                payload.id=vm.$route.params.id;
                payload.data.departmentId=vm.selected_list.id;
                vm.$store.dispatch('moduleAcedemicyears/updateAcedemicyear', payload)
                    .then(() => {
                        vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)
                        vm.$router.push({name: 'all_academicyear',query:{id:this.$route.query.id}}).catch(()=>{});
                    })
                    .catch(error => {
                        vm.$helper.handleError(error, vm);
                        vm.$helper.hideLoader()
                    })
            },
        }
    }
</script>

<style>
    .multiselect__select {
        height: 55px;
    }

    .multiselect__tags {
        min-height: 55px;
        padding: 15px 40px 0 8px;
    }
</style>
