/*=========================================================================================
  File Name: moduleCaptain.js
  Description: Common Module
==========================================================================================*/


import state from './moduleFacultyState.js'
import mutations from './moduleFacultyMutations.js'
import actions from './moduleFacultyActions.js'
import getters from './moduleFacultyGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

