<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('edit_courses') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('edit_courses') }}</h2>
              <h2 class="font-weight-bold fun_font text-center" v-if="list">
                {{ $t('list') }}
                <b class="text-danger">" {{ $helper.getTitleValueLang(list) }} "</b>
              </h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('title_ar') }}</label>
                  <input
                      type="text"
                      v-validate="'required|min:3|max:30'"
                      data-vv-name="name_ar"
                      v-model="dataModel.name_ar"
                      class="form-control"
                      :placeholder="$t('title_ar')"
                  />
                  <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('title_en') }}</label>
                  <input
                      type="text"
                      v-validate="'required|min:3|max:30'"
                      data-vv-name="name_en"
                      v-model="dataModel.name_en"
                      class="form-control"
                      :placeholder="$t('title_en')"
                  />
                  <span class="text-danger text-sm">{{ errors.first('name_en') }}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('description') }}</label>
                  <vue-editor
                      dir="ltr"
                      type="text"
                      v-validate="'min:5'"
                      data-vv-name="desc"
                      v-model="dataModel.desc"
                  ></vue-editor>
                  <span class="text-danger text-sm">{{ errors.first('desc') }}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('video_audio')}}</label>
                                    <small class="font-weight-bold text-danger"> ({{$t('max_video')}})</small>
                                    <input type="file" @change="previewVideo()" ref="myVideo"
                                           data-vv-name="file"
                                           accept="video/* , audio/*"
                                           class="form-control"
                                           :placeholder="$t('video_audio')">
                                    <span class="text-danger text-sm">{{ errors.first('file') }}</span>
                                </div>
              </div>-->
              <div class="col-md-6" v-show="getListMethod()==1">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('course_type') }}</label>
                  <select
                      v-validate="'required'"
                      data-vv-name="type"

                      v-model="dataModel.type"
                      v
                      class="form-control"
                  >
                    <option
                        :key="index"
                        :disabled="checkLessonTypesIsEnabled()"
                        v-for="(lesson_type , index) in lesson_types"
                        :value="index"
                    >{{ $t(lesson_type) }}
                    </option>
                  </select>
                  <span class="text-danger text-sm">{{ errors.first('type') }}</span>
                </div>
              </div>

              <div class="col-md-6" v-if="isAssignment()">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('end_assignment_date') }}</label>
                  <flat-pickr
                      data-vv-name="assignmentDeadLineDate"
                      v-validate.immediate="'required_if:type,0'"
                      v-model="dataModel.assignmentDeadLineDate"
                      class="form-control"
                      :placeholder="$t('end_assignment_date')"/>
                  <span class="text-danger text-sm">{{ errors.first('assignmentDeadLineDate') }}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('list') }}</label>
                  <multiselect
                      v-model="selectedList"
                      v-validate="'required'"
                      :options="lists"
                      :select-label="$t('multiselect_choose')"
                      @input="getAllGroubyByCourseId(selectedList)"
                      track-by="id"
                      :placeholder="$t('select_list')"
                      :custom-label="nameWithLang"
                  ></multiselect>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{$t('lesson_category')}} ({{$t('optional')}})</label>
                  <multiselect
                      v-model="dataModel.category"
                      :select-label="$t('multiselect_choose')"
                      :options="categories"
                      :placeholder="$t('category')"
                  ></multiselect>
                </div>
              </div>

              <div class="col-md-6" v-if="getListMethod()==1">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('group_name') }}</label>
                  <multiselect
                      v-model="selectedGroup"
                      :options="groups"
                      :select-label="$t('multiselect_choose')"
                      track-by="id"
                      data-vv-name="groupId"
                      :placeholder="$t('group_name')"
                      label="name"
                  ></multiselect>
                  <span class="text-danger text-sm">{{ errors.first('groupId') }}</span>
                </div>
              </div>

              <div class="col-md-6" v-show="!isAssignment()">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('video') }}</label>
                  <input
                      type="file"
                      @change="previewVideo()"
                      accept="video/mp4, video/quicktime, video/x-ms-wmv, video/avi, video/x-flv"
                      ref="myVideo"
                      data-vv-name="vedio"
                      class="form-control"
                      :placeholder="$t('video')"
                  />
                  <span class="text-danger text-sm">{{ errors.first('vedio') }}</span>
                  <a class="btn btn-danger-light" v-if="vedio_path" @click="displayVedio(vedio_path)">
                    <i class="ti ti-file"></i>
                    {{ $t('show_file') }}
                  </a>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('file') }}</label>
                  <input
                      type="file"
                      @change="previewFile()"
                      ref="myfile"
                      data-vv-name="file"
                      class="form-control"
                      :placeholder="$t('file')"
                      multiple
                  />
                  <span class="text-danger text-sm">{{ errors.first('attachments') }}</span>
                </div>
                <div class="row">
                  <div class="col-md-3" v-for="(src,index) in file_paths" :key="index">
                    <div class="thumbnail">
                      <button class="btn btn-danger-light w-100"
                              @click="displayImage(src)">{{ $t('show') }}
                      </button>
                      <i class="btn-danger-light ti-trash" @click="deleteCourseAttachments(src)"/>
                      <!-- <a @click="showMessage()">Delete</a> -->
                    </div>
                  </div>

                  <div class="col-md-3" v-for="(item,index) in otherAttaches" :key="index">
                    <div class="thumbnail">
                      <button class="btn btn-danger-light w-100"
                              @click="displayImage(item.attachment)">{{ $t('show') }}
                      </button>
                      <i class="btn-danger-light ti-trash" @click="deleteOtherAttachment(item.id)"/>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-md-6 direction" v-show="!isAssignment() && getListMethod()==1">
                <hr/>
                <input type="checkbox" id="isLive" v-model="isLive" value="1"/>
                <label for="isLive" class="p-1 font-weight-bold">{{ $t('live') }}</label>
              </div>

              <div class="col-md-6" v-if="isLive " v-show="!isAssignment() && getListMethod()==1">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label class="font-weight-bold">{{ $t('meeting_info') }}</label>
                      <input
                          type="text"
                          v-validate="'required|min:3'"
                          data-vv-name="liveStreamingInfo"
                          v-model="dataModel.liveStreamingInfo"
                          class="form-control"
                          :placeholder="$t('meeting_info')"
                      />
                      <span class="text-danger text-sm">{{ errors.first('liveStreamingInfo') }}</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="font-weight-bold">{{ $t('start_date') }}</label>
                      <flat-pickr
                          v-validate="'required'"
                          data-vv-name="liveStreamingTime"
                          :config="config"
                          v-model="dataModel.liveStreamingTime"
                          class="form-control"
                          :placeholder="$t('start_date')"
                      />
                      <span class="text-danger text-sm">{{ errors.first('liveStreamingTime') }}</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="font-weight-bold">{{ $t('end_date') }}</label>
                      <flat-pickr
                          v-validate="'required'"
                          data-vv-name="liveStreamingEndTime"
                          :config="config"
                          v-model="dataModel.liveStreamingEndTime"
                          class="form-control"
                          :placeholder="$t('end_date')"
                      />
                      <span class="text-danger text-sm" id="liveStreamingEndTime">{{ errors.first('liveStreamingEndTime') }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 green-skin">
                <input id="isFree" class="checkbox-custom" name="isFree" type="checkbox" v-model="dataModel.isFree">
                <label class="font-weight-bold checkbox-custom-label" for="isFree">مجاني ؟</label> <span style="font-size: 12px">مشاهده بدون إشتراك</span>
              </div>

              <div class="col-md-12 text-center">
                <span class="flex-d text-danger text-sm" id="error_handle"></span>
              </div>

              <div class="col-md-12 text-center">
                <button class="btn btn-danger-light" @click="submitForm()">{{ $t('edit') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sweet-modal ref="modal" class="p-0" overlay-theme="dark" modal-theme="dark">
      <div class="container">
        <div class="row">
          <div class="col-mds-12 w-100" v-if="videoID">
            <vue-vimeo-player ref="player" :video-id="videoID" @ready="onReady" :player-height="height"
                              style="width: 100%">
            </vue-vimeo-player>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>


<script>
import {SweetModal} from "sweet-modal-vue";
import {vueVimeoPlayer} from "vue-vimeo-player";


export default {
  name: "Edit",
  components: {SweetModal, vueVimeoPlayer},
  data() {
    return {
      dataModel: {
        name_ar: "",
        name_en: "",
        desc: "",
        type: 1,
        isLiveStreaming: 1,
        vedio: null,
        courseId: 2,
        isAssostatedWithGroup: 1,
        groupId: 1,
        attachments: null,
        liveStreamingInfo: "",
        liveStreamingTime: "",
        liveStreamingEndTime: "",
        assignmentDeadLineDate: "",
        category: '',
        isFree: false,
      },
      otherAttaches: [],
      list: "",
      selector_type: "link",
      lesson_types: ["assignment", "visual_lesson"],
      isLive: false,
      selectedGroup: null,
      groups: [],
      selectedList: null,
      file_paths: [],
      vedio_path: "",
      lists: [],
      categories: [],
      config: {
        enableTime: true,
        dateFormat: "Z",
        altInput: true,
        altFormat: "Y-m-d h:i K",
      },
      videoID: null,
      height: 300,
      options: {},
      playerReady: false
    };
  },

  mounted() {
    this.getAllCategories();
    this.findCourse();
    this.getAllLists();
  },

  methods: {
    async getAllCategories() {
      let vm = this;
      vm.$helper.showLoader();
      const res = await vm.$store.dispatch("moduleCourse/getAllCategories").catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      if (res.data.status && res.data.data) {
        vm.categories = res.data.data.map(item => item.category);
      }

      vm.$helper.hideLoader();
    },
    getListMethod() {
      return this.$route.query.method;
    },
    getListType() {
      return this.$route.query.type;
    },
    checkLessonTypesIsEnabled() {
      let vm = this;
      if (vm.getListType() == 0)
        return true;

      return false;


    },
    nameWithLang(o) {
      return this.$helper.getTitleValueLang(o);
    },
    previewFile() {
      this.dataModel.attachments = this.$refs.myfile.files;
    },
    displayImage(image_path) {

      let mywindow = window.open(image_path, '_blank');
      mywindow.moveTo(0, 0);
    },
    displayVedio(vedio_path) {
      let code = vedio_path.split('/').slice(-1);
      this.videoID = code;

      this.$refs.modal.open();


    },
    onReady() {
      this.playerReady = true
    },
    play() {
      this.$refs.player.play()
    },
    pause() {
      this.$refs.player.pause()
    },
    prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 10000,
        page: 1,
        searchKey: this.query ? this.query : "",
      };
    },
    isAssignment() {
      //check if the selected type is assignent
      if (this.dataModel.type == 0)
        return true;
      else
        return false;
    },
    getAllLists() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      // filters.method = "both";
      filters.startFrom = "1992-11-05 00:00:00.000";
      filters.startTo = "2992-11-05 00:00:00.000";
      let dispatch = this.$store.dispatch("moduleList/getAllList", filters);
      dispatch
          .then((response) => {
            let lists = this.$store.getters["moduleList/getAllList"];
            vm.lists = lists;
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },

    getAllGroubyByCourseId(list, selectedGroup = null) {
      let vm = this;
      if (!list) return;
      vm.$helper.showLoader();
      let filters = {};

      filters.filters = vm.prepareFilters();
      filters.id = list.id;
      let dispatch = this.$store.dispatch(
          "moduleGroup/findGroupByCourseID",
          filters
      );
      dispatch
          .then((response) => {
            //handeled by MM
            let groups = response.data.data.result.data
                ? response.data.data.result.data
                : [];

            vm.groups = groups;
            vm.selectedGroup = selectedGroup

            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },

    deleteCourseAttachments(src) {
      let vm = this;
      vm.$helper.showLoader();
      let payload = {};
      payload.data = {};


      payload.data.attachmentPath = src ? src.split('/').slice(3).join('/') : '';
      payload.id = vm.$route.params.list_id;
      let dispatch = this.$store.dispatch("moduleCourse/deleteCourseAttachments", payload);
      dispatch
          .then((response) => {
            if (response.status) {
              //delete from array
              const index = vm.file_paths.indexOf(src);
              if (index > -1) {
                vm.file_paths.splice(index, 1);
              }
            }
            vm.$helper.hideLoader();
          }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },

    deleteOtherAttachment(id) {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleCourse/deleteCourseOtherAttachments", { id });
      dispatch
          .then((response) => {
            if (response.status) {
              vm.otherAttaches = vm.otherAttaches.filter(item => item.id !== id);
            }
            vm.$helper.hideLoader();
          }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },

    findCourse() {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleCourse/findCourse", {
        id: vm.$route.params.list_id,
      });
      dispatch
          .then((response) => {
            let course = response.data.data.lesson;

            (vm.dataModel.name_ar = course.name_ar);
            (vm.dataModel.name_en = course.name_en);
            (vm.dataModel.category = course.category || '');
            (vm.dataModel.isFree = course.isFree || false);
            (vm.dataModel.desc = course.desc);
            (vm.dataModel.type = course.type);
            (vm.isLive = course.isLiveStreaming ? 1 : 0);
            (vm.vedio_path = course.vedio ? course.vedio : null);
            (vm.selectedList = course.course ? course.course : null);
            (vm.otherAttaches = course.otherAttaches || []);
            (vm.dataModel.isAssostatedWithGroup = course.isAssostatedWithGroup);
            (vm.dataModel.assignmentDeadLineDate = course.assignmentDeadLineDate);
            (vm.selectedGroup = course.group ? course.group : null);
            // vm.dataModel.attachments= course.attachments;
            (vm.dataModel.liveStreamingInfo = course.liveStreamingInfo);
            (vm.dataModel.liveStreamingTime = course.liveStreamingTime);
            (vm.dataModel.liveStreamingEndTime = course.liveStreamingEndTime);
            vm.file_paths = course.attachments ? course.attachments.split(',') : [];
            this.getAllGroubyByCourseId(vm.selectedList, this.selectedGroup);

            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    previewImage() {
      this.dataModel.image = this.$refs.myImage.files[0];
    },
    previewVideo() {
      this.dataModel.vedio = this.$refs.myVideo.files[0];
    },
    submitForm() {


      //---validators---
      this.$validator.validateAll().then((result) => {
        //check if the date is less than end date in livestream section
        if (this.dataModel.liveStreamingTime && this.dataModel.liveStreamingEndTime && !this.$helper.isDateGreater(this.dataModel.liveStreamingTime, this.dataModel.liveStreamingEndTime)) {
          this.$validator.errors.add({
            field: "liveStreamingEndTime",
            msg: this.$t('liveStreamingEndTime')
          })
          result = false;
        }

        if (result) {

          // if form have no errors
          this.updateCourse();
        } else {
          // form have validation errors
          $('html, body').animate({scrollTop: 19}, 'slow');
          this.$helper.printValidationError(this.$validator.errors, this);
        }
      });
    },

    updateCourse() {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};

      vm.dataModel.isAssostatedWithGroup = vm.selectedGroup ? 1 : 0;
      vm.dataModel.groupId = vm.selectedGroup ? vm.selectedGroup.id : '';
      vm.dataModel.courseId = vm.selectedList ? vm.selectedList.id : '';
      vm.dataModel.isLiveStreaming = vm.isLive ? 1 : 0;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
          );
          $("#loader_message").text(percentCompleted + " %");
        },
      };

      const formData = new FormData();
      _.forEach(vm.dataModel, (value, key) => {
        if (value != null || key == 'assignmentDeadLineDate')
          formData.append(key, value);
      });

      if (this.dataModel.attachments && this.dataModel.attachments.length > 0) {
        //delete the key attachments to re append them as array of elemnets
        formData.delete('attachments');

        _.forEach(this.dataModel.attachments, (value, index) => {
          formData.append("attachments", value);
        });
      }

      payload.data = formData;
      payload.data.courseId
      payload.config = config;
      payload.id = vm.$route.params.list_id;

      vm.$store
          .dispatch("moduleCourse/updateCourse", payload)
          .then(() => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage("success", vm);
            vm.$router.push({
              name: this.$helper.getRouteFullName("all_courses"),
              query: {list_id: this.$route.query.list_id, method: this.$route.query.method, type: this.$route.query.type}
            }).catch(() => {
            });
          })
          .catch((error) => {
            if (error.response) {
              let error_type = error.response.data;

              if (
                  error_type.message === "RESOURCE_NOT_FOUND" ||
                  error_type.message === "UNACCEPTABLE_DATE"
              )
                $("#error_handle").text(
                    vm.$helper.getErrorValueLang(error_type.data)
                );
            }
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
  },
};
</script>

<style>
.sweet-modal.is-visible .sweet-buttons,
.sweet-modal.is-visible .sweet-content {
  padding: 0 !important;
  direction: ltr;
  margin-top: 60px;
}

.sweet-modal .sweet-box-actions {
  z-index: 9;
}

iframe {
  width: 100%;
}

.thumbnail {
  width: 100%;
  margin-left: 0px;
  position: relative;
}

.thumbnail button {
  max-width: 100%;
  max-height: 100%;
}

.thumbnail i {
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 3px;
  right: 3px;
  /* background:#c00; */
  background: transparent;
  overflow: hidden;
  /* text-indent:-9999px; */
}

</style>
