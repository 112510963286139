<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t("dashboard") }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >
              {{ $t("all_assignments") }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <h2 class="font-weight-bold fun_font text-center py-2">
            {{ $t("all_assignments") }}
          </h2>

          <div class="dashboard_container_body my-2 mx-3">
            <ul>
              <p class="text-lg-center font-weight-bold" v-if="Object.keys(items).length === 0">{{ $t('no_data') }}</p>
              <li v-for="(key , i) in Object.keys(items)" :key="key">
                <h4 class="primary-clr" v-if="items[key].length">{{ $helper.getTitleValueLang(items[key][0].university) }}</h4>

                <div class="row" v-if="items[key].length">
                  <div class="col-sm-6 col-md-4 col-lg-3" v-for="(sub, index) in items[key]" :key="sub.course.id">
                    <figure class="notification-card d-flex justify-content-between" @click="$router.push({name:'assignment_details',params:{id:sub.course.id}}).catch(()=>{});">
                      <h6 class="m-0">{{ sub.course.name_ar }}</h6><h6 class="m-0 badge badge-primary">{{ sub.count }}</h6>
                    </figure>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "All",
  components: {},
  data() {
    return {
      items: {},
    };
  },
  mounted() {
    this.getAssignments();
  },
  methods: {
    async getAssignments() {
      let vm = this;
      vm.$helper.showLoader();

      let response = await this.$store.dispatch('moduleAssignmentSubmission/getAssignments', { type: 0 }).catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      vm.$helper.hideLoader();

      if (response.data && response.data.status && response.data.data.length) {
        let list = {};

        response.data.data.forEach(item => {
          item.course = item.course || {};
          item.course.subject = item.course.subject || {};
          item.course.subject.academicYear = item.course.subject.academicYear || {};
          item.course.subject.academicYear.department = item.course.subject.academicYear.department || {};
          item.course.subject.academicYear.department.faculty = item.course.subject.academicYear.department.faculty || {};
          item.course.subject.academicYear.department.faculty.university = item.course.subject.academicYear.department.faculty.university || {};

          if (item.course.subject.academicYear.department.faculty.university.name_ar.indexOf('جامعه تجريبيه') === -1) {
            list[item.course.subject.academicYear.department.faculty.university.id] = list[item.course.subject.academicYear.department.faculty.university.id] || [];
            list[item.course.subject.academicYear.department.faculty.university.id].push({
              university: item.course.subject.academicYear.department.faculty.university,
              course: item.course,
              count: item.count
            });
          }
        });

        vm.items = list;
      }
    },
  },
};
</script>

<style scoped>
</style>
