<template>
  <div>
    <Navigation />

    <u-animate-container>
      <div class="row">
        <div class="col-md-12">
          <div class="card pt-0 mt-0">
            <div class="card-header border-bottom-1">
              <h4 class="font-weight-bold">{{$t('my_lessons')}} ({{lessons.length}})</h4>
            </div>
            <div class="p-2">
                <div class="d-flex justify-content-center mt-2">
                  <button
                    class="btn btn-primary-light mx-1"
                   @click="$router.push({name:$helper.getRouteFullName('add_courses'),params:{list_id:$route.params.id},query:{list_id:$route.query.list_id,method:$route.query.method,type:$route.query.type}}).catch(()=>{})"
                  >
                    {{ $t("add_courses") }}
                      </button>

                  <button
                      class="btn btn-black mx-1"
                      @click="$router.push({name:$helper.getRouteFullName('manage_lessons'),query:{list_id:$route.query.list_id}}).catch(()=>{})"
                  >
                    {{ $t("manage_lessons") }}
                  </button>
                </div>
              </div>
            <div class="card-body">
              <div class="p-2" v-for="(lesson,index) in lessons" :key="index">
                <u-animate
                  name="bounceInDown"
                  :delay="index/10+'s'"
                  duration="1s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
                >
                  <div

                    class="row direction text-left pointer"
                  >
                    <div class="col-md-6">
                      <div class="d-flex">
                        <div class="text-center" style="flex: 1">
                          <img :src="require('@/assets/img/3180572.png')" width="60px" alt />
                        </div>
                        <div style="flex: 6">
                          <h4>{{$helper.getTitleValueLang(lesson)}}</h4>
                          <p v-html="lesson.desc">

                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-2">
                      <div class="text-right direction-inverse">
                        <i class="ti-calendar text-danger theme-cl"></i>
                        <span class="font-weight-bold text-dark text-small">{{$helper.formatDate(lesson.updatedAt)}}</span>
                       <div class="col-md-4"></div>
                        <span class="text-white p-1 bg-success">{{$t(lesson_types[lesson.type?lesson.type : 0])}}</span>
                      </div>

                    </div>
                    <div class="col-md-12" >
                    <div class="row justify-content-center">
                       <div class="col-1">
                         <button class="btn btn-success-light btn-sm m-2" @click="$router.push({name:$helper.getRouteFullName('edit_courses'),params:{list_id:lesson.id},query:{list_id:$route.query.list_id,method:$route.query.method,type:$route.query.type}}).catch(()=>{})">{{$t('edit')}}</button>
                      </div>
                       <div class="col-1">
                         <button class="btn btn-danger-light btn-sm m-2" @click="deleteCourse(lesson.id)">{{$t('delete')}}</button>
                      </div>
                      </div>
                       <hr class="hr_bg" v-if="index < lessons.length -1"/>
                    </div>
                  </div>
                </u-animate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </u-animate-container>
  </div>
</template>

<script>
    import Navigation from './Navigation'

    export default {
        name: "Courses",
        components: {Navigation},
        data() {
            return {
            lessons:[],
             lesson_types: ["assignment", "visual_lesson"],
            };
        },
      mounted() {
             this.getAlllessons();
        },
    methods:{
      prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: this.query ? this.query : "",
      };
    },
    getAlllessons() {
      let vm = this;
      let helper=vm.$helper;

        //if this page is allowed
      if(!helper.isInstructor())
      {helper.showMessage('error',vm,vm.$t('server_error_403')); return};

       let data=helper.getLocalStorage('userInfo');
      helper.showLoader();
      let filters = vm.prepareFilters();
      // filters.method = "both";
      let payload = {};
      payload.filters = filters;
      payload.id = vm.$route.params.id;

      let dispatch = this.$store.dispatch(
        "moduleList/findList",
        payload
      );
      dispatch
        .then((response) => {
           let list = response.data.data.course;
           vm.lessons =list ? list.lessons:[];
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },


     deleteCourse(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },
    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleCourse/removeCourse", {
        id: id,
      });
      dispatch
        .then((response) => {
          vm.$helper.hideLoader();
           let index=vm.lessons.findIndex(e=>{return e.id==id})
          vm.lessons=vm.lessons.splice(index,1);
           this.$helper.showMessage('success',this);
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
  },

    }
</script>

<style>
section {
  padding: 0;
}
</style>