<template>
  <div style="min-height:1000px;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb d-print-none">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('getAllUsersReport') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-2">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('getAllUsersReport') }}</h2>
            </div>
          </div>

          <div class="dashboard_container_body m-3">
            <ul>
              <li v-for="(uni , i) in universities" :key="uni.university.id">
                <h4 class="primary-clr" v-if="uni.courses.length">{{ $helper.getTitleValueLang(uni.university) }}</h4>

                <div class="row" v-if="uni.courses.length">
                  <div class="col-sm-6 col-md-4 col-lg-3" v-for="(course, index) in uni.courses" :key="course.id">
                    <figure class="notification-card d-flex justify-content-between align-items-center flex-wrap" @click="$router.push({name:'getCourseReport',params:{id:course.id}}).catch(()=>{});">
                      <h6 class="m-0">{{ $helper.getTitleValueLang(course) }}</h6>

                      <div class="d-flex flex-column align-items-end" style="flex-grow: 1">
                        <span>نسبة الدكتور: {{ course.totals.teacherPercentage.toFixed(2).replace(/\.00$/, '') }}</span>
                        <span>الإجمالى: {{ course.totals.course }}</span>
                      </div>
                    </figure>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "All",
  components: {},
  data() {
    return {
      universities: [],
    };
  },

  mounted() {
    this.getAllUniversities();
  },
  methods: {
    getAllUniversities() {
      let vm = this;
      vm.$helper.showLoader();

      const params = {
        paymentResult: 'CAPTURED',
        expired: '0',
      };

      this.$store.dispatch('moduleSubscribe/getAllSubscriptionsReport', params).then((response) => {
        const universities = response.data.data;

        const index = universities.map(uni => uni.university.name_ar).indexOf('جامعه تجريبيه');

        if (index > -1) {
          universities.splice(index, 1);
        }

        vm.universities = universities;

        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);

      });
    },
  },
};
</script>
