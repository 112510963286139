<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $t('courses') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ courseName }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('coupons') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row p-2">
            <div class="col-12 pt-3 text-center">
              <h2 class="font-weight-bold fun_font">{{ $t('coupons') }}</h2>
              <p>التحكم فى كوبونات والخصومات على الدورات</p>
            </div>
          </div>

          <div class="text-right row mx-1 my-2 d-flex justify-content-between align-items-end">
            <div class="col-md-4" v-if="$helper.isAdmin()">
              <label class="font-weight-bold">{{ $t('list') }}</label>
              <multiselect
                  v-model="selectedCourse"
                  :options="courses"
                  track-by="id"
                  :select-label="$t('multiselect_choose')"
                  :placeholder="$t('select_list')"
                  :custom-label="nameWithLang"
                  @select="onCourseChanged"
              ></multiselect>
            </div>
            <button
                class="btn btn-danger-light mx-2"
                @click="$router.push({name:$helper.getRouteFullName('add_coupon'),query:{courseId,courseName}}).catch(()=>{});"
            >{{ $t('add_coupon') }}
            </button>
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('list') }}</th>
                  <th class="text-left">{{ $t('course_price') }}</th>
                  <th class="text-left">{{ $t('coupon_code') }}</th>
                  <th class="text-left">{{ $t('value') }}</th>
                  <th class="text-left" style="width: 25px;">{{ $t('discount_type') }}</th>
                  <th class="text-left">{{ $t('start_date') }}</th>
                  <th class="text-left">{{ $t('end_date') }}</th>
                  <th class="text-left">{{ $t('max_user') }}</th>
                  <th class="text-left" style="width: 30px">{{ $t('operation') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(coupon , index) in coupons" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ $helper.getTitleValueLang(coupon.course) }}</td>
                  <td>{{ coupon.course.price }}</td>
                  <td>{{ coupon.code }}</td>
                  <td>{{ coupon.value }}{{ coupon.type === 1 ? '' : ' %' }}</td>
                  <td style="width: 25px;"><h6 class="badge badge-secondary">{{ $t(coupon.type === 1 ? 'static' : 'percentage') }}</h6></td>
                  <td>{{ parseDate(coupon.startDate) }}</td>
                  <td>{{ parseDate(coupon.endDate) }}</td>
                  <td>{{ coupon.maxUser }}</td>
                  <td>
                    <div class="btn-group direction-inverse">
                      <button
                          class="btn custom_btn btn-primary-light"

                          @click="deleteCoupon(coupon.id)"
                      >{{ $t('delete') }}
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="coupons.length==0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {cloneDeep} from "lodash";
import moment from "moment";
import helper from "../../../helpers/helper";

export default {
  name: "All",

  data() {
    return {
      courseId: this.$route.query.courseId,
      courseName: this.$route.query.name,
      list_types: ['subject', 'assignment'],
      list_methods: ['recorded', 'live'],
      coupons: [],
      courses: [],
      selectedCourse: null,
    }
  },
  mounted() {
    if (helper.isAdmin()) {
      this.getAllCourses();
    }
    this.getAllCoupons();
  },
  methods: {
    nameWithLang(o) {
      return this.$helper.getTitleValueLang(o);
    },
    onCourseChanged(course) {
      this.courseId = course.id;
      this.getAllCoupons();
    },
    getAllCourses() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = {
        doPagination: 0,
        numPerPage: 1,
        page: 1,
        searchKey: "",
      };
      filters.startFrom = "1992-11-05 00:00:00.000";
      filters.startTo = "2992-11-05 00:00:00.000";
      this.$store.dispatch("moduleList/getAllList", filters).then((res) => {
            let lists = this.$store.getters["moduleList/getAllList"];
            lists.unshift({
              id: 'all',
              name_ar: "لكل الدورات",
              name_en: "all",
            });
            vm.courses = lists;
            let courseId = this.$route.query.courseId;
            if (courseId) {
              _.forEach(lists, (course) => {
                if (course.id == courseId) {
                  vm.selectedCourse = course;
                }
              });
            } else {
              vm.selectedCourse = lists[0];
            }
            vm.courseId = vm.selectedCourse.id;
            vm.$helper.hideLoader();
          }).catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    parseDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return ' - ';
    },
    async getAllCoupons() {
      let vm = this;
      if (helper.isInstructor() && !vm.courseId) {
        helper.showMessage('error', vm, 'برجاء تحديد الدورة لتحميل الكوبونات');
        return;
      }

      vm.$helper.showLoader();
      await this.$store.dispatch("moduleList/getAllCoupons", {courseId: vm.courseId || 'all'}).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
      vm.coupons = cloneDeep(this.$store.getters["moduleList/getAllList"]).map(coupon => {
        coupon.course = coupon.course || {};
        coupon.course.name_ar = coupon.course.name_ar || 'لكل الدورات';
        coupon.course.name_en = coupon.course.name_en || 'All';
        coupon.course.price = coupon.course.price || '';
        return coupon;
      });
      vm.$helper.hideLoader();
    },
    deleteCoupon(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },
    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch("moduleList/removeCoupon", {id})
          .then(value => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage('success', vm, 'تم حذف الكوبون');
            vm.coupons = cloneDeep(vm.coupons).filter(coupon => coupon.id != id);
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
  },
};
</script>

<style>
.multiselect__select {
  height: 55px;
}

.multiselect__tags {
  min-height: 55px;
  padding: 15px 40px 0 8px;
}
</style>
