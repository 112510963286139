import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllLessonDiscussion({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.LIST_LESSON_DISSCUSSION_COMMENTS}`, {
                params: payload.filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_LESSON_DISSCUSSION', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    //get all disscussions with filters
    getAllDisscusion({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get(`${requests.LIST_LESSON_DISSCUSSION}`, {
                params: payload.filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_LESSON_DISSCUSSION', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getAllLessonDiscussionCourse({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.LIST_LESSON_DISSCUSSION_COMMENTS}`, {
                params: payload.filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_LESSON_DISSCUSSION', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    


    findLessonDiscussionComments({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.LIST_LESSON_DISSCUSSION_ID}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    findLessonDiscussionByCommentsID({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.LIST_LESSON_DISSCUSSION_COMMENTS_ID}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    addLessonDiscussion({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.ADD_LESSON_DISSCUSSION}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateLessonDiscussion({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_LESSON_DISSCUSSION}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateLessonDiscussionComments({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_LESSON_DISSCUSSION_COMMENT}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeLessonDiscussion({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_LESSON_DISSCUSSION}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeLessonDiscussionComments({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_LESSON_DISSCUSSION_COMMENT}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
