<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{$t('dashboard')}}</a>
            </li>
            <li
              class="breadcrumb-item active"
              aria-current="page"
              @click.prevent
            >{{$t('all_groups')}}</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-4">
              <h2 class="font-weight-bold fun_font text-center">{{$t('all_groups')}}</h2>
            </div>
            <div class="col-md-9 ">
            <div class=" m-0 p-2 border-0">
                
              <ul>
                  <li class="list-group-item">
                  <label class="font-weight-bold">{{$t('list')}}</label>
                  <multiselect
                    :options="lists"
                    :custom-label="$helper.getTitleValueLang"
                    track-by="id"
                     :select-label="$t('multiselect_choose')"
                    v-model="selectedList"
                    v-validate="'required'"
                    data-vv-name="list"
                    :placeholder="$t('search')"
                  ></multiselect>
              <span class="text-danger text-sm">{{ errors.first('list') }}</span>

                </li>
                <div class="d-flex justify-content-center ">
                <li class="list-inline-item m-1">
                  <button
                    class="btn btn-danger-light"
                    v-if="$helper.hasAccessPermission('create-list')"
                    @click="$router.push({name:$helper.getRouteFullName('add_groups'),params:{id:selectedList?selectedList.id:''}}).catch(()=>{})"
                  >
                    <i class="ti-plus"></i>
                    {{$t('add')}}
                  </button>
                </li>
                  <li class="list-inline-item m-1">
                  <button
                    class="btn btn-success-light"
                    v-if="$helper.hasAccessPermission('create-list')"
                    @click="getAllGroups()"
                  >
                    <i class="ti-search"></i>
                    {{$t('filter')}}
                  </button>
                </li>
                 </div>
                <!--<li class="list-inline-item">-->
                <!--<button class="btn btn-warning-light">-->
                <!--<i class="ti-printer"></i>-->
                <!--{{$t('print')}}-->
                <!--</button>-->
                <!--</li>-->
              </ul>
        </div>
        </div>
           
            <div class="col-md-3">
              <div class="dashboard_container_header">
                <div class="dashboard_fl_1"></div>
                <div class="dashboard_fl_2">
                  <ul class="mb0">
                    <li class="list-inline-item">
                      <form
                        class="form-inline my-2 my-lg-0"
                        dir="ltr"
                        @submit.prevent="getAllGroups()"
                      >
                        <input
                          class="form-control"
                          type="search"
                          :placeholder="$t('search')"
                          v-model="query"
                          aria-label="Search"
                        />
                        <button class="btn my-2 my-sm-0" type="submit">
                          <i class="ti-search"></i>
                        </button>
                      </form>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
         <div class="row justify-content-end mb-2 ml-2">
              
            <div
              dir="ltr"
              class="col-3 text-center mt-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer items-center font-medium"
            >
         
              <span class=""
                >{{$t('items_of')}}{{ page * itemsPerPage - (itemsPerPage - 1) }} {{$t('items_to')}}
                {{
                  page * itemsPerPage -
                  (itemsPerPage - 1) +
                  (groups.length - 1)
                }}
                {{$t('items_for')}} {{ numRows }}</span
              >
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <div class="col-3 text-center">
              <label class="font-weight-bold">{{ $t("items_in_page") }}</label>
                <multiselect
                 :select-label="$t('multiselect_choose')"
                  :options="pager_items_choices" 
                   v-model="itemsPerPage" 
                   @input="getAllGroups()"
                  :placeholder="$t('search')"></multiselect>
              </div>
              <!-- </div> -->
              </div>
          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">{{$t('name')}}</th>
                    <th class="text-left">{{$t('teacher')}}</th>
                    <th class="text-left">{{$t('limit_numbers')}}</th>
                    <th class="text-left">{{$t('end_date')}}</th>
                    
                    <!-- <th class="text-center">{{$t('rating')}}</th> -->
                    <!-- <th class="text-center ">{{$t('all_register_students')}}</th> -->
                    <th class="text-left" width="100px">{{$t('operation')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(group , index) in groups" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{group.name}}</td>
                     <td>{{$helper.getTitleValueLang(group.instructor)}}</td>
                    <td>{{group.maxNumOfStudents}}</td>
                     <td>{{$helper.formatDate(group.endDate)}}</td>
                   <!-- <td>{{$helper.getTitleValueLang(group.subject.academicYear.department)}}</td>
                    <td>{{$helper.getTitleValueLang(group.subject.academicYear.department.faculty)}}</td>
                    <td>{{$helper.getTitleValueLang(group.subject.academicYear.department.faculty.university)}}</td>     -->

                    <!-- <td class="text-center">
                                        <h4 class="font-weight-bold">4/5</h4>
                                        <button class="btn btn-outline-info btn-sm" @click="showRating()">
                                            {{$t('show_rating')}}
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <div class="font-weight-bold">{{list.price}}</div>
                                        <button class="btn btn-outline-warning btn-sm">{{$t('show_list')}}</button>
                    </td>-->
                    <td>
                      <div class="btn-group direction-inverse">
                        <button
                          class="btn custom_btn btn-primary-light"
                          @click="deleteList(group.id)"
                        >{{$t('delete')}}</button>
                        <!-- v-if="$helper.hasAccessPermission('delete-list')" -->
                        <button
                          class="btn custom_btn btn-success-light"
                          @click="$router.push({name:$helper.getRouteFullName('edit_groups'),params:{id:group.id}}).catch(()=>{})"
                        >{{$t('edit')}}</button>
                        <!-- v-if="$helper.hasAccessPermission('all-course')" -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
                  <p class="text-lg-center font-weight-bold" v-if="groups.length==0">{{$t('no_data')}}</p>

            </div>


               <div class="text-center" v-if="last_page > 1">
               
                            <paginate
                                    :page-count="last_page"
                                    :page-range="1"
                                    :click-handler="handlePaginate"
                                    :prev-text="'Prev'"
                                    :next-text="'Next'"
                                    :container-class="'pagination'"
                                    :page-class="'page-item'">
                            </paginate>
                 
           
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";

export default {
  name: "All",
  components: { SweetModal },
  data() {
    return {
      groups: [],
      lists: [],
      selectedList:null,
      query: null,

      pager_items_choices:[1,5,10,20,50,100],
      itemsPerPage:10,
      page: 1,
      last_page: 1,
      numRows:1,
    };
  },
  mounted() {
   
    this.getAllLists();
  },
  methods: {
    showRating() {
      this.$refs.ratingModal.open();
    },
    prepareFilters() {
      return {
        doPagination: 1,
       numPerPage: this.itemsPerPage,
        page: this.page,
        searchKey: this.query ? this.query : "",
      };
    },
     handlePaginate(pageNum) {
      this.page = pageNum;
      this.getAllGroups();
    },

    getAllLists() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      (filters.method = "both"),
        (filters.startFrom = this.start_date
          ? this.start_date
          : "1992-11-05 00:00:00.000");
      filters.startTo = this.end_date
        ? this.end_date
        : "2992-11-05 00:00:00.000";
      filters.method = 1;


      let dispatch = this.$store.dispatch("moduleList/getAllList", filters);
      dispatch
        .then((response) => {
          vm.lists = this.$store.getters["moduleList/getAllList"];
          if (vm.lists.length>0){
            vm.selectedList=vm.lists[0];
             this.getAllGroups();
          }
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
    getAllGroups() {
      let vm = this;
  //cancel requist if list is not chooosed
  console.log(vm.selectedList);
      if(!vm.selectedList)return;

      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
    
      

      let payload={};
      payload.id=vm.selectedList?vm.selectedList.id:'';
      payload.filters=filters;

      let dispatch = this.$store.dispatch("moduleGroup/getAllGroup", payload);
      dispatch
        .then((response) => {
            if(response.status){
            vm.groups = this.$store.getters["moduleGroup/getAllGroup"];
            vm.numRows=response.data.data.result.numRows;
            vm.last_page=response.data.data.result.numPages;
            console.log(vm.groups);
            }
            vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
    deleteList(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },
    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleGroup/removeGroup", {
        id: id,
      });
      dispatch
        .then((response) => {
          vm.$helper.hideLoader();
          let index = vm.groups.findIndex((e) => {
            return e.id == id;
          });
          this.$store.commit("moduleGroup/REMOVE_GROUP", index);
           this.$helper.showMessage('success',this);
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
  },
};
</script>

<style scoped>
</style>