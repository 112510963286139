import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllUniversities({commit}, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_UNIVERSITIES, {
                params: filters
            }).then((response) => {
                 if (response.status) {
                     commit('SET_UNIVERSITIES', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addUniversity({commit}, payload) {
        return new Promise((resolve, reject) => {

            // payload.data.faculties=JSON.stringify(payload.data.faculties);

            axios.post(requests.CREATE_UNIVERSITY, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    findUniversity({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_UNIVERSITY}/${payload.id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateUniversity({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`${requests.UPDATE_UNIVERSITY}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeUniversity({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_UNIVERSITY}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
