import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllLessonsForReorder({commit}, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_LESSONS_FOR_REORDER, {
                params: filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_COURSES', response.data.data.rows);
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    changeLessonsPriorityAndAvailability({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.CHANGE_LESSON_ORDER_AVAILABILITY, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllCategories({commit}, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_LESSON_CATEGORIES, {
                params: filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getAllCourses({commit}, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_COURSES, {
                params: filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_COURSES', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addCourse({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.CREATE_COURSE, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    findCourse({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_COURSE}/${payload.id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateCourse({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_COURSE}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deleteCourseAttachments({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_COURSE_ATTACHEMENT}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deleteCourseOtherAttachments({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${requests.DELETE_OTHER_COURSE_ATTACHEMENT}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeCourse({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_COURSES}/${payload.id}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
