<template>
  <div style="min-height:1000px;">
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb d-print-none">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $t('getAllUsersReport') }}</a>
            </li>
            <li v-if="course"
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ course.name_ar }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">تقرير الطلاب المسجلين بالدورة {{ course ? course.name_ar : '' }}</h2>
            </div>
          </div>
          <div class="m-2">

            <div class="d-flex justify-content-between align-items-end">
              <h6 v-if="course">المحاضر: {{ $helper.getTitleValueLang(course.instructor) }}</h6>

              <div>
                <button class="btn btn-danger-light" @click="print()">{{ $t('print') }}</button>
                <button class="btn btn-success-light" @click="toggleCompareCalcs()"><i class="ti-filter"></i> {{ $t('compare_calculations') }}</button>
                <button class="btn btn-success-light" @click="togglePostponed()"><i class="ti-filter"></i> {{ $t('show_postponed') }}</button>
              </div>
            </div>

            <div class="d-flex justify-content-end align-items-end" v-if="compareCalculations.isOpened">
              <div class="form-group">
                <label class="font-weight-bold">{{ $t("start_date") }}</label>
                <flat-pickr
                    v-model="compareCalculations.startDate"
                    class="form-control"
                    :placeholder="$t('start_date')"
                />
              </div>
              <div class="form-group mx-2">
                <label class="font-weight-bold">{{ $t("end_date") }}</label>
                <flat-pickr
                    v-model="compareCalculations.endDate"
                    class="form-control"
                    :placeholder="$t('end_date')"
                />
              </div>

              <div class="form-group">
                <button class="btn btn-black mx-1" @click="getExpiredSubscriptions()">{{ $t('compare') }}</button>
                <button class="btn btn-danger-light btn-circle" @click="resetFilters('expired')"><i class="ti ti-reload"></i></button>
              </div>
            </div>

            <div class="d-flex justify-content-end align-items-end" v-if="postponedCourses.isOpened">
              <div class="form-group">
                <label class="font-weight-bold">{{ $t("start_date") }}</label>
                <flat-pickr
                    v-model="postponedCourses.startDate"
                    class="form-control"
                    :placeholder="$t('start_date')"
                />
              </div>
              <div class="form-group mx-2">
                <label class="font-weight-bold">{{ $t("end_date") }}</label>
                <flat-pickr
                    v-model="postponedCourses.endDate"
                    class="form-control"
                    :placeholder="$t('end_date')"
                />
              </div>

              <div class="form-group">
                <button class="btn btn-black mx-1" @click="getHoldingSubscriptions()">{{ $t('show') }}</button>
                <button class="btn btn-danger-light btn-circle" @click="resetFilters('holding')"><i class="ti ti-reload"></i></button>
              </div>
            </div>
          </div>

          <div class="row dashboard_container_body mt-3">
            <div :class="expiredCourse ? 'col-lg-6' : 'col-12'">
              <div class="d-flex justify-content-between align-items-end px-2 py-1">
                <h5 v-if="course" class="primary-clr">الإشتراكات الحالية</h5>
                <button class="btn btn-blue-light p-1" @click="exportCSV([course, holdingCourse])"><i class="ti-export"></i> {{ $t('export_csv') }}</button>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead class="thead-default">
                  <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">{{ $t('phone') }}</th>
                    <th class="text-center" v-if="!expiredCourse">{{ $t('department') }}</th>
                    <th class="text-center">{{ $t('status') }}</th>
                    <th class="text-center" v-if="!expiredCourse">{{ $t('list_type') }}</th>
                    <th class="text-center">{{ $t('teacher_percent') }}</th>
                    <th class="text-center">{{ $t('app_percent') }}</th>
                    <th class="text-center">{{ $t('transfer_cost') }}</th>
                    <th class="text-center">{{ $t('cost') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="course && course.courseSubscribes">
                    <tr v-for="(sub , index) in course.courseSubscribes" :key="sub.id">
                      <td>{{ index + 1 }}</td>

                      <td class="text-center">
                        <div v-if="$helper.isAdmin()" class="text-center">
                          <a :href="'tel:'+sub.student.mobile">{{ sub.student.mobile }}</a>
                        </div>
                      </td>

                      <td v-if="!expiredCourse">
                        <span>{{ $helper.getTitleValueLang(sub.student.academicYear.department) }}</span>
                      </td>

                      <td class="text-center">{{ getStatus(sub) }}</td>

                      <td class="text-center" v-if="!expiredCourse">{{ sub.type || '' }}</td>
                      <td class="text-center">{{ sub.totals.teacher.toFixed(2).replace(/\.00$/, '') }}</td>
                      <td class="text-center">{{ sub.totals.app.toFixed(2).replace(/\.00$/, '') }}</td>
                      <td class="text-center">{{ sub.totals.transferCost }}</td>

                      <td class="font-weight-bolder text-center" style="width: 100px;">{{ sub.totals.total }}</td>
                    </tr>

                    <template v-if="holdingCourse && holdingCourse.courseSubscribes.length">
                      <tr v-for="(sub , index) in holdingCourse.courseSubscribes" :key="sub.id">
                        <td>{{ course.courseSubscribes.length + index + 1 }}</td>

                        <td class="text-center">
                          <div v-if="$helper.isAdmin()" class="text-center">
                            <a :href="'tel:'+sub.student.mobile">{{ sub.student.mobile }}</a>
                          </div>
                        </td>

                        <td v-if="!expiredCourse">
                          <span>{{ $helper.getTitleValueLang(sub.student.academicYear.department) }}</span>
                        </td>

                        <td class="text-center">{{ getStatus(sub) }}</td>

                        <td class="text-center" v-if="!expiredCourse">{{ sub.type || '' }}</td>
                        <td class="text-center">{{ sub.totals.teacher.toFixed(2).replace(/\.00$/, '') }}</td>
                        <td class="text-center">{{ sub.totals.app.toFixed(2).replace(/\.00$/, '') }}</td>
                        <td class="text-center">{{ sub.totals.transferCost }}</td>

                        <td class="font-weight-bolder text-center" style="width: 100px;">{{ sub.totals.total }}</td>
                      </tr>
                    </template>

                    <tr class="font-weight-bolder" :key="course.id">
                      <td :colspan="expiredCourse ? 3 : 5" class="p-0 px-2">{{ $t('total_list') }}</td>
                      <td class="font-weight-bolder color--success p-0 px-2 text-center" style="font-size: 18px">
                        {{ (course.totals.teacher + (holdingCourse?.totals?.teacher || 0)).toFixed(2).replace(/\.00$/, '') }}
                      </td>
                      <td class="font-weight-bolder color--success p-0 px-2 text-center" style="font-size: 18px">
                        {{ (course.totals.app + (holdingCourse?.totals?.app || 0)).toFixed(2).replace(/\.00$/, '') }}
                      </td>
                      <td class="font-weight-bolder color--success p-0 px-2 text-center" style="font-size: 18px">
                        {{ course.totals.transferCost + (holdingCourse?.totals?.transferCost || 0) }}
                      </td>
                      <td class="font-weight-bolder color--success p-0 px-2 text-center" style="font-size: 18px">{{
                          course.totals.total + (holdingCourse?.totals?.total || 0)
                        }}
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-6" v-if="expiredCourse">
              <div class="d-flex justify-content-between align-items-end px-2 py-1">
                <h5 v-if="course" class="primary-clr">الإشتراكات المنتهية</h5>
                <button class="btn btn-blue-light p-1" @click="exportCSV([expiredCourse])"><i class="ti-export"></i> {{ $t('export_csv') }}</button>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead class="thead-default">
                  <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">{{ $t('phone') }}</th>
                    <th class="text-center">{{ $t('status') }}</th>
                    <th class="text-center">{{ $t('teacher_percent') }}</th>
                    <th class="text-center">{{ $t('app_percent') }}</th>
                    <th class="text-center">{{ $t('transfer_cost') }}</th>
                    <th class="text-center">{{ $t('cost') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template>
                    <tr v-for="(sub , index) in expiredCourse.courseSubscribes" :key="sub.id">
                      <td>{{ index + 1 }}</td>

                      <td class="text-center">
                        <div v-if="$helper.isAdmin()" class="text-center">
                          <a :href="'tel:'+sub.student.mobile">{{ sub.student.mobile }}</a>
                        </div>
                      </td>

                      <td class="text-center">{{ getStatus(sub) }}</td>
                      <td class="text-center">{{ sub.totals.teacher.toFixed(2).replace(/\.00$/, '') }}</td>
                      <td class="text-center">{{ sub.totals.app.toFixed(2).replace(/\.00$/, '') }}</td>
                      <td class="text-center">{{ sub.totals.transferCost }}</td>

                      <td class="font-weight-bolder text-center" style="width: 100px;">{{ sub.totals.total }}</td>
                    </tr>

                    <tr class="font-weight-bolder" :key="expiredCourse.id">
                      <td :colspan="3" class="p-0 px-2">{{ $t('total_expired') }}</td>
                      <td class="font-weight-bolder color--success p-0 px-2 text-center" style="font-size: 18px">
                        {{ expiredCourse.totals.teacher.toFixed(2).replace(/\.00$/, '') }}
                      </td>
                      <td class="font-weight-bolder color--success p-0 px-2 text-center" style="font-size: 18px">
                        {{ expiredCourse.totals.app.toFixed(2).replace(/\.00$/, '') }}
                      </td>
                      <td class="font-weight-bolder color--success p-0 px-2 text-center" style="font-size: 18px">{{ expiredCourse.totals.transferCost }}</td>
                      <td class="font-weight-bolder color--success p-0 px-2 text-center" style="font-size: 18px">{{ expiredCourse.totals.total }}</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-if="showCharts" class="m-3 mt-5">
            <h5 class="primary-clr">إحصائيات</h5>

            <div class="row">
              <div class="col-md-6">
                <Bar :chart-data="barChartData"
                     :height="barHeight"/>
              </div>

              <div class="col-md-6">
                <Pie :chart-data="pieChartData"
                     :height="pieHeight"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Bar, Pie } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default {
  name: "All",
  components: { Bar, Pie },
  props: {
    barHeight: {
      type: Number,
      default: 400
    },
    pieHeight: {
      type: Number,
      default: 200
    },
  },
  data() {
    return {
      courseId: null,
      course: null,
      holdingCourse: null,
      expiredCourse: null,
      compareCalculations: {
        isOpened: false,
        startDate: '',
        endDate: '',
      },
      postponedCourses: {
        isOpened: false,
        startDate: '',
        endDate: '',
      },
      showCharts: false,
      barChartData: {
        labels: [],
        datasets: [],
      },
      pieChartData: {
        labels: [],
        datasets: [
          {
            label: 'الإجمالي',
            backgroundColor: ['#d34747', '#00A9B4', '#44ab34'],
            data: []
          }
        ],
      }
    };
  },

  mounted() {
    this.courseId = this.$route.params.id;
    this.getAllSubscriptionsOfCourse();
  },
  methods: {
    toggleCompareCalcs() {
      this.compareCalculations.isOpened = !this.compareCalculations.isOpened;
      this.postponedCourses.isOpened = false;
    },
    togglePostponed() {
      this.postponedCourses.isOpened = !this.postponedCourses.isOpened;
      this.compareCalculations.isOpened = false;
    },

    resetFilters(type) {
      switch (type) {
        case 'holding':
          this.holdingCourse = null;
          this.postponedCourses.startDate = '';
          this.postponedCourses.endDate = '';
          break;
        case 'expired':
          this.expiredCourse = null;
          this.compareCalculations.startDate = '';
          this.compareCalculations.endDate = '';
          break;
      }

      this.showCharts = false;
    },

    prepareCharts() {
      let show = false;
      const labels = [];
      const datasets = {
        total: {
          label: 'الإجمالي',
          backgroundColor: '#d34747',
          borderRadius: 5,
          data: [],
          grandTotal: 0,
        },
        teacher: {
          label: 'نسبة الدكتور',
          borderRadius: 5,
          backgroundColor: '#00A9B4',
          data: [],
          grandTotal: 0,
        },
        app: {
          label: 'نسبة التطبيق',
          borderRadius: 5,
          backgroundColor: '#44ab34',
          data: [],
          grandTotal: 0,
        }
      };
      this.pieChartData.labels = [];
      this.pieChartData.datasets[0].data = [];
      this.pieChartData.datasets[0].grandTotal = 0;

      if (this.course && this.course.courseSubscribes && this.course.courseSubscribes.length) {
        const courseTotals = this.calcTotalsPerCourse(this.course);

        if (this.holdingCourse && this.holdingCourse.courseSubscribes && this.holdingCourse.courseSubscribes.length) {
          const holdingTotals = this.calcTotalsPerCourse(this.holdingCourse);

          courseTotals.total += holdingTotals.total;
          courseTotals.app += holdingTotals.app;
          courseTotals.teacher += holdingTotals.teacher;
          courseTotals.transferCost += holdingTotals.transferCost;
        }

        datasets.total.data.push(courseTotals.total);
        datasets.total.grandTotal += courseTotals.total;
        datasets.app.data.push(courseTotals.app.toFixed(2).replace(/\.00$/, ''));
        datasets.app.grandTotal += courseTotals.app;
        datasets.teacher.data.push(courseTotals.teacher.toFixed(2).replace(/\.00$/, ''));
        datasets.teacher.grandTotal += courseTotals.teacher;
        labels.push('الإشتراكات الحالية');
        this.pieChartData.labels.push('إجمالى الإشتراكات الحالية');
        this.pieChartData.datasets[0].data.push(courseTotals.total);
        this.pieChartData.datasets[0].grandTotal += courseTotals.total;

        if (this.holdingCourse && this.holdingCourse.courseSubscribes && this.holdingCourse.courseSubscribes.length) {
          show = true;
        }
      }

      if (this.expiredCourse && this.expiredCourse.courseSubscribes && this.expiredCourse.courseSubscribes.length) {
        const expiredTotals = this.calcTotalsPerCourse(this.expiredCourse);

        datasets.total.data.push(expiredTotals.total);
        datasets.total.grandTotal += expiredTotals.total;
        datasets.app.data.push(expiredTotals.app.toFixed(2).replace(/\.00$/, ''));
        datasets.app.grandTotal += expiredTotals.app;
        datasets.teacher.data.push(expiredTotals.teacher.toFixed(2).replace(/\.00$/, ''));
        datasets.teacher.grandTotal += expiredTotals.teacher;

        labels.push('الإشتراكات المنتهية');
        this.pieChartData.labels.push('إجمالى الإشتراكات المنتهية');
        this.pieChartData.datasets[0].data.push(expiredTotals.total);
        this.pieChartData.datasets[0].grandTotal += expiredTotals.total;
        show = true;
      }

      // Percentage calculations
      this.pieChartData.datasets[0].data.forEach((value, index) => {
        this.pieChartData.labels[index] += ' (' + ((value / this.pieChartData.datasets[0].grandTotal) * 100).toFixed(2).replace(/\.00$/, '') + '%)';
      });

      if (show) {
        // this.barChartData.datasets = Object.values(datasets).map((dataset) => {
        //   dataset.data = dataset.data.map((data) => {
        //     return data + ' (' + 5 + '%)';
        //   });
        //
        //   return dataset;
        // });
        this.barChartData.datasets = Object.values(datasets);
        this.barChartData.labels = labels;
        this.showCharts = true;
      }
    },

    getStatus(sub) {
      switch (sub.paymentResult) {
        case "CAPTURED":
          var status = "مشترك";
          if (sub.heldAt != null)
            status += ' وكان مؤجل ';
          return status;
        case "HOLDING":
          return "مؤجل";
        case "EXPIRED":
          return "منتهي";
        default:
          return "";
      }
    },

    getUsedCoupon(sub) {
      if (sub && sub.coupon) {
        return sub.coupon || '';
      } else if (sub && sub.details != "") {
        return JSON.parse(sub.details)[0].code || '';
      } else return "";
    },

    calcTotalsPerCourse(course) {
      const totals = { teacher: 0, app: 0, transferCost: 0, total: 0 };

      (course.courseSubscribes || []).forEach(sub => {
        const { teacher, app, transferCost, total } = this.calcTotalsPerSubscription(sub);

        totals.teacher += teacher;
        totals.app += app;
        totals.transferCost += transferCost;
        totals.total += total;
      });

      return totals;
    },

    calcTotalsPerSubscription(sub) {
      let teacher = 0, app = 0, transferCost = 0, total = 0;

      if (sub.total > 0 && ((['CAPTURED', 'HOLDING'].includes(sub.paymentResult) && sub.heldAt === null) || sub.paymentResult === 'EXPIRED')) {
        teacher = (sub.total - 1) * 0.6;
        app = (sub.total - 1) - teacher;
        transferCost = 1;
        total = sub.total;
      }

      return {
        teacher,
        app,
        transferCost,
        total
      };
    },

    print() {
      window.print();
    },

    exportCSV(courses) {
      let data = 'م, إسم الدورة, الجامعة, المحاضر, نوع الدورة,نسبة الدكتور,نسبة التطبيق,مصاريف التحويل,إجمالي الإشتراك\n';
      let index = 0;
      const totals = { teacher: 0, app: 0, transferCost: 0, total: 0 };

      courses.forEach(course => {

        course && course.courseSubscribes.forEach(sub => {
          data += ++index + ',';
          data += this.$helper.getTitleValueLang(this.course).replaceAll(',', '') + ',';
          data += this.$helper.getTitleValueLang(sub.student.academicYear.department.faculty.university).replaceAll(',', '') + ',';
          data += this.$helper.getTitleValueLang(this.course.instructor).replaceAll(',', '') + ',';
          data += (sub.type || '') + ',';

          const { teacher, app, total, transferCost } = this.calcTotalsPerSubscription(sub);

          data += teacher.toFixed(2).replace(/\.00$/, '') + ',';
          data += app.toFixed(2).replace(/\.00$/, '') + ',';
          data += transferCost + ',';
          data += total + ',\n';

          totals.teacher += teacher;
          totals.app += app;
          totals.transferCost += transferCost;
          totals.total += total;
        });
      });

      data += '\nإجمالي الدورة,,,,,' + totals.teacher.toFixed(2).replace(/\.00$/, '') + ',' +
          totals.app.toFixed(2).replace(/\.00$/, '') + ',' + totals.transferCost + ',' + totals.total + '\n\n';

      const blob = new Blob(["\ufeff", data], {
        type: 'text/csv;charset=utf-8'
      });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', 'Report-' + Date.now() + '.csv');
      link.click();
    },

    async getAllSubscriptionsOfCourse() {
      this.course = await this.getSubscriptionsRequest();
    },

    async getHoldingSubscriptions() {
      const params = {
        paymentResult: 'HOLDING',
      };

      this.postponedCourses.startDate && (params.fromDate = this.postponedCourses.startDate);
      this.postponedCourses.endDate && (params.toDate = this.postponedCourses.endDate);

      const result = await this.getSubscriptionsRequest(params);
      this.holdingCourse = result && result.courseSubscribes && result.courseSubscribes.length ? result : null;
      this.prepareCharts();
    },

    async getExpiredSubscriptions() {
      const params = {
        expired: '1',
        paymentResult: 'EXPIRED',
      };

      this.compareCalculations.startDate && (params.fromDate = this.compareCalculations.startDate);
      this.compareCalculations.endDate && (params.toDate = this.compareCalculations.endDate);

      const result = await this.getSubscriptionsRequest(params);
      this.expiredCourse = result && result.courseSubscribes && result.courseSubscribes.length ? result : null;
      this.prepareCharts();
    },

    getSubscriptionsRequest(params) {
      return new Promise((resolve) => {
        let vm = this;
        vm.$helper.showLoader();

        const payload = {
          id: vm.courseId || '',
          params: {
            paymentResult: 'CAPTURED',
            expired: '0',
          },
        };

        params && (payload.params = params);

        this.$store.dispatch("moduleSubscribe/getSubscriptionsOfCourse", payload).then((response) => {
          vm.$helper.hideLoader();
          const course = response.data.data;
          course.courseSubscribes = course.courseSubscribes || [];

          course.totals = this.calcTotalsPerCourse(course);
          course.courseSubscribes.forEach(sub => {
            sub.totals = this.calcTotalsPerSubscription(sub);
          });

          resolve(course);
        }).catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
          resolve(null);
        });
      });
    },
  },
};
</script>
