<template>
    <div>
        <Navigation/>

        <u-animate-container>
            <div class="row">
                <div class="col-md-12">
                    <div class="card pt-0 mt-0">
                        <div class="card-header border-bottom-1">
                            <h4 class="font-weight-bold">{{$t('reg_students')}} ({{students.length}})</h4>
                        </div>
                        <div class="card-body">
                            <div class="p-5 text-center" v-if="students.length==0">
                                <i class="ti-book fa-3x color--primary"></i>
                                <h3>{{$t('no_data')}}</h3>
                            </div>
                            <div class="p-2" v-for="(student,index) in students" :key="index">
                                <u-animate
                                        name="bounceInDown"
                                        :delay="index/10+'s'"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                        :begin="false"
                                >
                                    <div class="row direction text-left pointer">
                                        <div class="col-md-12">
                                            <div class="d-flex">
                                                <div class="text-center" style="flex: 1">
                                                    <img :src="require('@/assets/img/3180572.png')"
                                                         width="60px" alt="">
                                                </div>
                                                <div style="flex: 4">
                                                    <h4>{{student.student.name}}</h4>
                                                    <!-- <div>
                                                        <i class="ti-email text-danger theme-cl"></i>
                                                        <span class="font-weight-bold text-dark text-small">{{student.student.email}}</span>
                                                    </div> -->
                                                    <!-- <div>
                                                        <i class="ti-mobile text-danger theme-cl"></i>
                                                        <span class="font-weight-bold text-dark text-small">{{student.student.mobile}}</span>
                                                    </div> -->
                                                    <div>
                                                        <i class="ti-calendar text-danger theme-cl"></i>
                                                        <span class="font-weight-bold text-dark text-small">{{$helper.formatDate(student.student.updatedAt)}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-if="index < students.length -1">
                                            <hr class="hr_bg">
                                        </div>
                                    </div>
                                </u-animate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </u-animate-container>
    </div>
</template>

<script>
    import Navigation from './Navigation'

    export default {
        name: "Courses",
        components: {Navigation},
        data() {
            return {
            students:[],
            };
        },
      mounted() {
             this.getAllstudents();
        },
    methods:{
      prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: this.query ? this.query : "",
      };
    },
    getAllstudents() {
      let vm = this;
      let helper=vm.$helper;
    
        //if this page is allowed
      if(!helper.isInstructor())
      {helper.showMessage('error',vm,vm.$t('server_error_403')); return};

       let data=helper.getLocalStorage('userInfo');
      helper.showLoader();
      let filters = vm.prepareFilters();
    //   filters.method = "both";
      let payload = {};
      payload.filters = filters;
      payload.id = vm.$route.params.id;

      let dispatch = this.$store.dispatch(
        "moduleList/findList",
        payload
      );
      dispatch
        .then((response) => {
           let list = response.data.data.course;
           vm.students =list ? list.courseSubscribes:[]; 
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },


    
  },
        
    }
</script>

<style>
section {
  padding: 0;
}
</style>