<template>
  <div style="min-height:1000px;">
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb d-print-none">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('getAllUsersReport') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('getAllUsersReport') }}</h2>
            </div>
          </div>
          <div class="row p-2 mb-2 d-print-none align-items-end">
            <div class="col-md-4">
              <label class="font-weight-bold">{{ $t('list') }}</label>
              <multiselect
                  :select-label="$t('multiselect_choose')"
                  v-model="selectedList"
                  :options="lists"
                  track-by="id"
                  @input="getAllLists()"
                  :custom-label="$helper.getTitleValueLang"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <form class="d-flex align-content-center justify-content-center" dir="ltr" @submit.prevent="getAllLists()">
                <button class="btn btn-success-light" type="submit">
                  <i class="ti-search"></i>
                </button>
                <input
                    class="form-control"
                    type="search"
                    :placeholder="$t('search')"
                    v-model="query"
                    aria-label="Search"
                />
              </form>
            </div>
            <div class="col-md-4">
<!--              <button class="btn btn-success-light" @click="getAllLists()">{{ $t('filter') }}</button>-->
              <button class="btn btn-danger-light" @click="print()">{{ $t('print') }}</button>
              <button class="btn btn-blue-light" @click="exportCSV()">{{ $t('export_csv') }}</button>
            </div>

          </div>

          <div class="dashboard_container_body">

            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('phone') }}</th>
                  <th class="text-left">{{ $t('department') }}</th>
                  <th class="text-left">{{ $t('status') }}</th>
                  <th class="text-left">{{ $t('cost') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item , index) in lists">
                  <tr v-for="(sub_item , sub_index) in item.courseSubscribes" :key="sub_item.id">
                    <td>{{ index + sub_index + 1 }}</td>
                    <td>
                      <p class="font-weight-bolder m-0">{{ $helper.getTitleValueLang(item) }}</p>
                    </td>

                    <td class="text-center">
                      <a :href="'tel:'+sub_item.student.mobile">{{ sub_item.student.mobile }}</a>
                    </td>

                    <td>
                      {{ $helper.getTitleValueLang(sub_item.student.academicYear.department) }}
                    </td>

                    <td>{{ getStatus(sub_item) }}</td>

                    <td class="font-weight-bolder" style="width: 100px;">{{ calculateCoursePrice(sub_item) }}</td>
                  </tr>
                  <tr class="font-weight-bolder">
                    <td :colspan="5" class="p-0 px-2">{{ $t('total_list') }}</td>
                    <td class="font-weight-bolder color--success p-0 px-2" style="font-size: 20px">{{ calculateTotalCoursePrice(item) }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="lists.length==0">{{ $t('no_data') }}</p>

            </div>

            <!--            <div class="text-center" v-if="last_page > 1">-->
            <!--              <paginate-->
            <!--                  :page-count="last_page"-->
            <!--                  :page-range="1"-->
            <!--                  :click-handler="handlePaginate"-->
            <!--                  :prev-text="'Prev'"-->
            <!--                  :next-text="'Next'"-->
            <!--                  :container-class="'pagination'"-->
            <!--                  :page-class="'page-item'">-->
            <!--              </paginate>-->
            <!--            </div>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SweetModal} from "sweet-modal-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "All",
  components: {SweetModal, flatPickr},
  data() {
    return {
      lists: [],
      selectedList: null,
      users: [],
      user: null,
      query: null,
      start_date: null,
      end_date: null,
      date: new Date(),
      config: {
        mode: "range",
        dateFormat: "Y-m-d",
      },
      pager_items_choices: [5, 10, 20, 50, 100],
      itemsPerPage: 10,
      page: 1,
      last_page: 1,
      numRows: 1,
    };
  },

  mounted() {
    this.getAllLists();
  },
  methods: {
    getStatus(sub_item) {
      switch (sub_item.paymentResult) {
        case "CAPTURED":
          var status = "مشترك";
          if (sub_item.heldAt != null)
            status += ' وكان مؤجل ';
          return status;
        case "HOLDING":
          return "مؤجل";
        case "EXPIRED":
          return "منتهي";
        default:
          return "";
      }
    },

    getUsedCoupon(sub_item) {
      if (sub_item && sub_item.coupon) {
        return sub_item.coupon || '';
      } else if (sub_item && sub_item.details != "") {
        return JSON.parse(sub_item.details)[0].code || '';
      } else return "";
    },

    calculateCoursePrice(sub_item) {
      return sub_item.total || 0;
    },

    calculateCoursePriceBefore(sub_item) {
      if (sub_item && sub_item.details != "") {
        return JSON.parse(sub_item.details)[0].priceBeforeDiscount;
      } else return "";
    },

    calculateTotalCoursePrice(course) {
      let total = 0;

      course.courseSubscribes.forEach(element => {
        total = total + (element.total || 0);
      });

      return total > 0 ? total : '';
    },

    print() {
      window.print();
    },

    exportCSV() {
      if (this.lists.length === 0){
        return;
      }

      // let data = 'م, إسم الدورة, نوع الدورة,الجامعة, القسم, المادة, المحاضر, رقم الهاتف, القيمة قبل الخصم, قيمة الإشتراك\n';
      let data = 'م, إسم الدورة, الجامعة, المحاضر, القيمة قبل الخصم, قيمة الإشتراك\n';
      let index = 0;

      this.lists.forEach(course => {
        course.courseSubscribes.forEach(sub => {
          data += ++index + ',';
          data += this.$helper.getTitleValueLang(course).replaceAll(',', '') + ',';
          // data += course.course_keyword.replaceAll(',', '') + ',';
          data += this.$helper.getTitleValueLang(sub.student.academicYear.department.faculty.university).replaceAll(',', '') + ',';
          // data += this.$helper.getTitleValueLang(sub.student.academicYear.department).replaceAll(',', '') + ',';
          // data += this.$helper.getTitleValueLang(course.subject).replaceAll(',', '') + ',';
          data += this.$helper.getTitleValueLang(course.instructor).replaceAll(',', '') + ',';
          // data += sub.student.mobile.replaceAll(',', '') + ',';
          data += this.calculateCoursePriceBefore(sub) + ',';
          data += this.calculateCoursePrice(sub) + ',\n';
        });

        data += ',,,,إجمالي الدورة,' + this.calculateTotalCoursePrice(course) + ',\n\n\n\n';
      });

      const blob = new Blob(["\ufeff", data], {
        type: 'text/csv;charset=utf-8'
      });
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', 'Report-' + Date.now() + '.csv');
      link.click() ;
    },

    prepareFilters() {
      return {
        doPagination: 1,
        numPerPage: this.itemsPerPage,
        page: this.page,
        //1 or 0 or both
      };
    },

    getAllLists() {
      let vm = this;
      let query = this.query ? this.query : "";
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.startFrom = this.start_date
          ? this.start_date
          : "1992-11-05 00:00:00.000";
      filters.startTo = this.end_date
          ? this.end_date
          : "2992-11-05 00:00:00.000";
      // filters.method = "both";
      filters.searchKey = query;
      filters.doPagination = '0';
      filters.courseId = this.selectedList ? this.selectedList.id : '';
      filters.instructorId = this.$helper.findInstructorData() ? this.$helper.findInstructorData().instructorId : '';

      let dispatch = this.$store.dispatch("moduleList/getAllList", filters);
      dispatch
          .then((response) => {
            vm.lists = this.$store.getters["moduleList/getAllList"].filter(item => item.courseSubscribes && item.courseSubscribes.length);
            vm.numRows = response.data.data.result.numRows;
            vm.last_page = response.data.data.result.numPages;
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    handlePaginate(pageNum) {
      this.page = pageNum;
      this.getAllLists();
    },
  },
};
</script>

<style>
.sweet-modal .sweet-title > h2 {
  padding-top: 15px;
}

.multiselect__select {
  height: 55px;
}

.multiselect__tags {
  min-height: 55px;
  padding: 15px 40px 0 8px;
}
</style>
