<template>
    <div>
        <!--{{categories}}-->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" @click.prevent>{{$t('dashboard')}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{$t('add_faculty')}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="dashboard_container">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <h2 class="font-weight-bold fun_font text-center">{{$t('add_faculty')}}</h2>
                        </div>
                    </div>
                    <div class="dashboard_container_body p-2">

                        <div class="row">

                            <div class="col-md-4" v-if="!hasUniversityChoiced">
                                <label class="font-weight-bold">{{$t('university')}}</label>
                                <multiselect
                                        :select-label="$t('multiselect_choose')"
                                        :options="lists" :custom-label="$helper.getTitleValueLang"
                                        track-by="id" v-model="selected_list"
                                        data-vv-name="universityId" v-validate="'required'"
                                        :placeholder="$t('search')">              
                                          </multiselect>
                                          <span class="text-danger text-sm">{{ errors.first('universityId') }}</span>
                            </div>

                            

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_ar')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_ar" v-model="dataModel.data.name_ar"
                                           class="form-control"
                                           :placeholder="$t('title_ar')">
                                    <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_en')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_en" v-model="dataModel.data.name_en"
                                           class="form-control"
                                           :placeholder="$t('title_en')">
                                    <span class="text-danger text-sm">{{ errors.first('name_en') }}</span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
               
                    <div class="col-md-12 text-center">
                        <button class="btn btn-danger-light" @click="submitForm()">
                            {{$t('add')}}
                        </button>
                    </div>
                </div>
             
        </div>

    </div>
</template>

<script>
    export default {
        name: "Add",
        data() {
            return {
                dataModel: {
                    data:{
                    name_ar: '',
                    name_en: '',
                    universityId:0,
                    }
                },
                lists:[],
                selected_list:null,
                hasUniversityChoiced:false,
            }
        },
        mounted() {
            this.getAllLists ();  
            //if the university is already choiced
            if(this.$route.query.id){
                this.hasUniversityChoiced=true;
                this.dataModel.data.universityId=this.$route.query.id;
            }
        },
        methods: {
            
            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // if form have no errors
                        this.addFaculty();
                    } else {
                        // form have validation errors
 $('html, body').animate({ scrollTop: 19 }, 'slow');
 this.$helper.printValidationError(this.$validator.errors,this);
                    }
                })
            },
             getAllLists() {
                let vm = this;
                vm.$helper.showLoader();
                let filters = vm.prepareFilters();
                let dispatch = this.$store.dispatch('moduleUniversity/getAllUniversities', filters);
                dispatch.then((response) => {
                    vm.lists = this.$store.getters['moduleUniversity/getAllUniversities'];
                     vm.$helper.hideLoader();
                }).catch((error) => {
                    vm.$helper.hideLoader();
                    vm.$helper.handleError(error, vm);
                });
            },
             prepareFilters() {
                return {
                doPagination:0,
                numPerPage:1000,
                page:1,
                searchKey:this.query?this.query:''
                };
            },
            addFaculty() {
                let vm = this;
                vm.$helper.showLoader();
                let payload = vm.dataModel;
                if(!vm.hasUniversityChoiced)payload.data.universityId=vm.selected_list.id;
                vm.$store.dispatch('moduleFaculty/addFaculty', payload)
                    .then(() => {
                        vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)
                        //navigate to the all page based on the hasUniversityChoiced
                        if(!vm.hasUniversityChoiced)vm.$router.push({name: 'all_faculties'}).catch(()=>{});
                        else vm.$router.push({name: 'all_universities'}).catch(()=>{});
                    })
                    .catch(error => {
                          vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);
                      
                    })
            },
        }
    }
</script>

<style>
    .multiselect__select {
        height: 55px;
    }

    .multiselect__tags {
        min-height: 55px;
        padding: 15px 40px 0 8px;
    }
</style>