/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
  SET_SETTINGS(state, settings) {
    state.settings = settings
  },
}
