/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
  SET_TEACHERS(state, teachers) {
        state.teachers = teachers
    },

  REMOVE_TEACHERS(state,index){
        state.teachers.splice(index,1)
  }
}
