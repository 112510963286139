/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
    SET_LESSON_DISSCUSSION(state, lessonDiscussion) {
        state.lessonDiscussion = lessonDiscussion
    },
    REMOVE_LESSON_DISSCUSSION_COMMENT(state, payload) {
       state.lessonDiscussion[payload.index].lessonDiscussionComments.splice(payload.cindex,1)
    },
    REMOVE_LESSON_DISSCUSSION(state, index) {
       state.lessonDiscussion.splice(index,1)
    },
    ADD_LESSON_DISSCUSSION_COMMENT(state, payload) {
       state.lessonDiscussion[payload.index].lessonDiscussionComments.push(payload.comment);
    },

    ADD_LESSON_DISSCUSSION(state, discussion) {
       //push to array but at the begin
       state.lessonDiscussion.unshift(discussion);
    },

 
}
