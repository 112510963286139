/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
  SET_UNIVERSITIES(state, universities) {
         state.universities = universities
    },
  REMOVE_UNIVERSITIES(state, index) {
    state.universities.splice(index,1)
    },
}
