/*=========================================================================================
  File Name: moduleCaptain.js
  Description: Common Module
==========================================================================================*/


import state from './moduleAssignmentSubmissionState.js'
import mutations from './moduleAssignmentSubmissionMutations.js'
import actions from './moduleAssignmentSubmissionActions.js'
import getters from './moduleAssignmentSubmissionGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

