/*=========================================================================================
  File Name: moduleCaptain.js
  Description: Common Module
==========================================================================================*/


import state from './moduleTeacherState.js'
import mutations from './moduleTeacherMutations.js'
import actions from './moduleTeacherActions.js'
import getters from './moduleTeacherGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

