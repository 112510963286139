<template>
    <div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" @click.prevent>{{$t('dashboard')}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{$t('add_teacher')}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="dashboard_container">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <h2 class="font-weight-bold fun_font text-center">{{$t('add_teacher')}}</h2>
                        </div>
                    </div>
                    <div class="dashboard_container_body p-2">

                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_ar')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_ar" v-model="dataModel.name_ar"
                                           class="form-control"
                                           :placeholder="$t('title_ar')">
                                    <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_en')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_en" v-model="dataModel.name_en"
                                           class="form-control"
                                           :placeholder="$t('title_en')">
                                    <span class="text-danger text-sm">{{ errors.first('name_en') }}</span>
                                </div>
                            </div>

                           

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('phone')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="mobile" v-model="dataModel.mobile"
                                           class="form-control"
                                           :placeholder="$t('phone')">
                                    <span class="text-danger text-sm">{{ errors.first('mobile') }}</span>
                                </div>
                            </div>
                           

                            <div class="col-md-4" >
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('email')}}</label>
                                    <input type="text"
                                           v-validate="'email|required'" data-vv-name="email" v-model="dataModel.email"
                                           class="form-control"
                                           :placeholder="$t('email')">
                                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                                </div>
                            </div>
                            <div class="col-md-4" >
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('username')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="username" v-model="dataModel.username"
                                           class="form-control"
                                           :placeholder="$t('username')">
                                    <span class="text-danger text-sm">{{ errors.first('username') }}</span>
                                </div>
                            </div>

                             <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('password')}}</label>
                                    <input type="password"
                                           v-validate="'required|min:5'" data-vv-name="password" v-model="dataModel.password"
                                           class="form-control"
                                           :placeholder="$t('password')">
                                    <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                                </div>
                            </div>

                             <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('password_confirmation')}}</label>
                                    <input type="password"
                                           v-validate="'required|min:5'" data-vv-name="password_confirmation" v-model="password_confirmation"
                                           class="form-control"
                                           :placeholder="$t('password')">
                                    <span class="text-danger text-sm" id="password_confirmation">{{ errors.first('password_confirmation') }}</span>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('image')}}</label>
                                    <input type="file" accept="image/*" @change="previewImage()" ref="myImage"
                                           data-vv-name="image"
                                           class="form-control"
                                           :placeholder="$t('image')">
                                    <span class="text-danger text-sm">{{ errors.first('image') }}</span>
                                </div>
                                <!-- <div v-if="imgSrc">
                                    <vue-cropper
                                            ref="cropper"
                                            :src="imgSrc"
                                            :aspect-ratio="1 / 1"
                                            alt="Source Image">
                                    </vue-cropper>
                                </div> -->
                            </div>
                             <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('cvـfile')}}</label>
                                    <input type="file" accept="*/*" @change="previewCv()" ref="myCv"
                                            data-vv-name="file"  
                                           class="form-control"
                                           :placeholder="$t('image')">
                                    <span class="text-danger text-sm" >{{ errors.first('file') }}</span>
                                </div>
                                
                            </div>


                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('bio')}}</label>
                                    <vue-editor dir="ltr" v-model="dataModel.bio"   v-validate="'required'" data-vv-name="bio"
                                                :placeholder="$t('bio')"></vue-editor>
                                    <span class="text-danger text-sm">{{ errors.first('bio') }}</span>
                                </div>
                            </div>

                            <div class="col-md-12 text-center">
                                <button class="btn btn-danger-light" @click="submitForm()">
                                    {{$t('add')}}
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    export default {
        name: "Add",
        components: {VueCropper},
        data() {
            return {
                dataModel: {
                    name_ar: '',
                    name_en: '',
                    password: '',
                    email: '',
                    username: '',
                    mobile: '',
                    bio: '',
                    isVerified:1,
                    img:null,
                    file:null,

                },
                imgSrc: null,
                password_confirmation:'',
            }
        },
        mounted() {

        },
        methods: {
            previewImage() {
                // let image = this.$refs.myImage.files[0];
                // this.readFile(image)
                 this.dataModel.img =  this.$refs.myImage.files[0];
            },
             previewCv() {
                this.dataModel.file = this.$refs.myCv.files[0];
            },
            readFile(file) {
                if (file.type.indexOf('image/') === -1) {
                    console.log('Please select an image file');
                    return;
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.imgSrc = event.target.result;
                        // this.$refs.cropper.replace(event.target.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    console.log('Sorry, FileReader API not supported');
                }
            },
            setImageToModel() {
                let cropper = this.$refs.cropper;
                if (cropper) {
                    let data = cropper.getCroppedCanvas().toDataURL();
                    this.dataModel.img = this.convertToBlob(data);
                }
            },
            convertToBlob(data) {
                let vm = this;
                let block = data.split(";");
                let contentType = block[0].split(":")[1];
                let realData = block[1].split(",")[1];
                let image_file = vm.b64toBlob(realData, contentType);
                let extension = '';
                try {
                    extension = image_file.type.split('/')[1];
                } catch (e) {
                    extension = 'jpg'
                }
                let image_file_name = `image_name_example.${extension}`;
                image_file = vm.blobToFile(image_file, image_file_name);
                return image_file

            },
            b64toBlob(b64Data, contentType, sliceSize) {
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                var byteCharacters = atob(b64Data);
                var byteArrays = [];

                for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    var slice = byteCharacters.slice(offset, offset + sliceSize);

                    var byteNumbers = new Array(slice.length);
                    for (var i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

                var blob = new Blob(byteArrays, {type: contentType});
                return blob;
            },
            blobToFile(theBlob, fileName) {
                theBlob.lastModifiedDate = new Date();
                theBlob.lastModified = new Date().getTime();
                theBlob.name = fileName;
                return theBlob;
                // return new File(theBlob, fileName);
            },
            submitForm() {
                
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // if form have no errors
                        if(this.dataModel.password!=this.password_confirmation){
                           
                        //    $('#password_confirmation').text(this.$t('password_confirmation'));
                          this.$validator.errors.add({
                    field:  "password_confirmation",
                    msg:  this.$t('password_confirmation')
                })
                    
                        }else{
                             this.addTeacher();
                        }
                        
                    } else {
                        // form have validation errors
                        $('html, body').animate({ scrollTop: 19 }, 'slow');
                         this.$helper.printValidationError(this.$validator.errors,this);
                    }
                })
            },
            addTeacher() {
                let vm = this;
                vm.$helper.showLoader();
                const payload = vm.dataModel;
                
                // vm.setImageToModel();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        $('#loader_message').text(percentCompleted + ' %')
                    }
                };

                const formData = new FormData();
                _.forEach(vm.dataModel, (value, key) => {
                  if(value!=null)
                    formData.append(key, value)
                });

                payload.data = formData;
                payload.config = config;
               
                vm.$store.dispatch('moduleTeacher/addTeacher', payload)
                    .then(() => {
                        vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)
                        vm.$router.push({name: 'all_teachers'}).catch(()=>{})
                    })
                    .catch(error => {
                        vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);
                        
                    })
            },
        }
    }
</script>

<style scoped>

</style>