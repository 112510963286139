<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $t('discounts') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('add_discount') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('add_discount') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('num_of_courses_in_cart') }} *</label>
                  <input
                      type="number"
                      v-validate="'required|min:1'"
                      data-vv-name="num_of_courses_in_cart"
                      v-model="dataModel.numOfCourses"
                      class="form-control"
                      :placeholder="$t('num_of_courses_in_cart')"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('num_of_courses_in_cart')">عدد الدورات مطلوبة</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('value') }} *</label>
                  <input
                      type="number"
                      v-validate="'required|numeric'"
                      data-vv-name="coupon_value"
                      v-model="dataModel.value"
                      class="form-control"
                      :placeholder="$t('value')"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('coupon_value')">القيمة مطلوبة</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('discount_type') }} *</label>
                  <select v-validate="'required'" data-vv-name="discount_type"
                          v-model="dataModel.type"
                          class="form-control">
                    <option value="static">{{ $t('static') }}</option>
                    <option value="percentage">{{ $t('percentage') }}</option>
                  </select>
                  <span class="text-danger text-sm">{{ errors.first('discount_type') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('max_user') }} *</label>
                  <input
                      type="number"
                      v-validate="'required|numeric'"
                      data-vv-name="max_user"
                      name="max_user"
                      v-model="dataModel.maxUsers"
                      class="form-control"
                      :placeholder="$t('max_user')"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('max_user')">الحد الأقصى للمستخدمين مطلوب</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("start_date") }} *</label>
                  <flat-pickr
                      v-validate="'required'"
                      data-vv-name="start_date"
                      v-model="dataModel.startDate"
                      class="form-control"
                      :config="config"
                      :placeholder="$t('start_date')"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('start_date')"> {{ errors.first('start_date') }} </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("end_date") }} ({{ $t("optional") }})</label>
                  <flat-pickr
                      v-model="dataModel.endDate"
                      class="form-control"
                      :config="config"
                      :placeholder="$t('end_date')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
               <span class="flex-d text-danger text-sm" id="error_handle"
               ></span>
              </div>

              <div class="col-md-12 text-center">
                <button class="btn btn-success" @click="submitForm()">{{ $t('add') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';

export default {
  name: "Add",
  data() {
    return {
      config: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
      dataModel: {
        numOfCourses: '',
        value: '',
        type: null,
        startDate: null,
        endDate: null,
        maxUsers: '',
      }
    };
  },
  components: {
    flatPickr
  },
  methods: {
    submitForm() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let vm = this;
          const payload = {
            data: {
              numOfCourses: vm.dataModel.numOfCourses,
              value: vm.dataModel.value,
              type: vm.dataModel.type === 'percentage' ? 2 : 1,
              maxUser: vm.dataModel.maxUsers,
              startDate: vm.dataModel.startDate,
            }
          };
          vm.dataModel.endDate && (payload.data.endDate = vm.dataModel.endDate);
          vm.$helper.showLoader();
          this.$store.dispatch("moduleList/addDiscount", payload)
              .then(value => {
                vm.$helper.hideLoader();
                vm.$helper.showMessage('success', vm, 'تم إضافة الخصم');
              })
              .catch((error) => {
                vm.$helper.hideLoader();
                vm.$helper.handleError(error, vm);
              });
        } else {
          $("html, body").animate({scrollTop: 19}, "slow");
        }
      });
    }
  },
};
</script>