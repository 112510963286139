/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
    SET_LIST(state, list) {
        state.list = list
    },
    REMOVE_LIST(state, index) {
      state.list.splice(index,1)
 },
}
