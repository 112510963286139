/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
  SET_ACADEMIC_YEAR(state, Acedemicyears) {
        state.Acedemicyears = Acedemicyears
    },

    REMOVE_ACADEMIC_YEAR(state, index) {
       state.Acedemicyears.splice(index,1)
  },
}
