<template>
  <div style="min-height:1000px;">
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb d-print-none">
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $t('dashboard') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('subscription_points') }}</a>
            </li>
            <li v-if="subscriptions.length"
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ subscriptions[0].course.name_ar }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">تقييمات المدرس للطلاب المشتركين بالدورة "{{ subscriptions.length ? subscriptions[0].course.name_ar : '' }}"</h2>
            </div>
          </div>

          <div class="dashboard_container_body">

            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('cost') }}</th>
                  <th class="text-center">{{ $t('points') }}</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item , index) in subscriptions" :key="item.id">
                    <td>{{ index + 1 }}</td>

                    <td>{{ item.student.name }}</td>

                    <td class="font-weight-bolder" style="width: 100px;">{{ item.total }}</td>

                    <td class="font-weight-bolder d-flex justify-content-center">
                      <button class="btn btn-success btn-circle d-flex p-0 justify-content-center" style="width: 25px;height: 25px;font-size: 25px; line-height: 0.8"
                              @click="changePoints(item, item.points + 1)">+
                      </button>
                      <input class="mx-2" v-model="item.points" style="width: 80px" @focusout="changePoints(item)">
                      <button class="btn btn-danger btn-circle d-flex p-0 justify-content-center" style="width: 25px;height: 25px;font-size: 25px; line-height: 0.8"
                              @click="changePoints(item, item.points - 1)">-
                      </button>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="subscriptions.length===0">{{ $t('no_data') }}</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "All",
  components: {},
  data() {
    return {
      courseId: null,
      subscriptions: [],
    };
  },

  mounted() {
    this.courseId = this.$route.params.id;
    this.getAllSubscriptions();
  },
  methods: {
    reorderSubscriptions(subscriptions) {
      return subscriptions.sort((a, b) => a.points >= b.points ? -1 : 1);
    },
    getAllSubscriptions() {
      let vm = this;
      vm.$helper.showLoader();

      let dispatch = this.$store.dispatch("moduleSubscribe/getCourseSubscriptions", { id: vm.courseId });
      dispatch
          .then((response) => {
            vm.subscriptions = this.reorderSubscriptions(response.data.data || []);
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    changePoints(subscription, newPoints) {
      let vm = this;
      subscription.points = +(newPoints != null ? newPoints : subscription.points);

      if (subscription.points < 0) {
        subscription.points = 0;
        return;
      }

      vm.$helper.showLoader();

      const payload = {
        id: subscription.id,
        data: {
          "points": subscription.points,
        },
      };

      let dispatch = this.$store.dispatch("moduleSubscribe/changeSubscriptionPoints", payload);
      dispatch
          .then((response) => {
            if (response.data.status) {
              vm.subscriptions = vm.reorderSubscriptions(vm.subscriptions);
            }
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
  },
};
</script>
