<template>
    <div>
        <!--{{categories}}-->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" @click.prevent>{{$t('dashboard')}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{$t('all_universities')}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="dashboard_container">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <h2 class="font-weight-bold fun_font text-center">{{$t('all_universities')}}</h2>
                        </div>
                        <div class="col-md-6 text-center text-md-left">
                            <ul>
                                <li class="list-inline-item">
                                     <!-- v-if="$helper.hasAccessPermission('create-list')" -->
                                    <button class="btn btn-danger-light"
                                        
                                            @click="$router.push({name:'add_university'}).catch(()=>{})">
                                        <i class="ti-plus"></i>
                                        {{$t('add')}}
                                    </button>
                                </li>
                                <li class="list-inline-item">
                                    <!-- v-if="$helper.hasAccessPermission('all-course')" -->
                                    <button class="btn btn-warning-light" @click="$router.push({name:'all_faculties'}).catch(()=>{})"
                                            >
                                        {{$t('faculties')}}
                                    </button>
                                </li>
                                <!--<li class="list-inline-item">-->
                                <!--<button class="btn btn-warning-light">-->
                                <!--<i class="ti-printer"></i>-->
                                <!--{{$t('print')}}-->
                                <!--</button>-->
                                <!--</li>-->
                            </ul>
                        </div>
                        <div class="col-md-6">

                            <div class="dashboard_container_header">
                                <div class="dashboard_fl_1"></div>
                                <div class="dashboard_fl_2">
                                    <ul class="mb0">
                                        <li class="list-inline-item">
                                            <form class="form-inline my-2 my-lg-0" dir="ltr"
                                                  @submit.prevent="getAllLists()">
                                                <input class="form-control" type="search" :placeholder="$t('search')"
                                                       v-model="query"
                                                       aria-label="Search"/>
                                                <button class="btn my-2 my-sm-0" type="submit">
                                                    <i class="ti-search"></i>
                                                </button>
                                            </form>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard_container_body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-default">
                                <tr>
                                    <th class="text-left ">#</th>
                                    <th class="text-left ">{{$t('title_ar')}}</th>
                                    <th class="text-left">{{$t('title_en')}}</th>
                                     <th class="text-left " width="100px">{{$t('operation')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(list , index) in lists" :key="index">
                                    <td>{{index+1}}</td>
                                    <td>{{list.name_ar}}</td>
                                    <td>{{list.name_en}}</td>
                                    
                                   
                                    <td>
                                         <!--  -->
                                        <div class="btn-group direction-inverse">
                                            <button class="btn custom_btn btn-primary-light"
                                                  @click="deleteUniversity(list.id)" >
                                                {{$t('delete')}}
                                            </button>
                                             <!-- v-if="$helper.hasAccessPermission('delete-list')" -->
                                            <button class="btn custom_btn btn-success-light"
                                                   
                                                    @click="$router.push({name:'edit_university',params:{id:list.id}}).catch(()=>{})">
                                                {{$t('edit')}}
                                            </button>
                                             <!-- v-if="$helper.hasAccessPermission('all-course')" -->
                                            <button class="btn custom_btn btn-danger-light"
                                                   
                                                    @click="$router.push({name:'all_faculties',query:{id:list.id}}).catch(()=>{})">
                                                {{$t('all_faculties')}}
                                            </button>
                                            <!--  v-if="$helper.hasAccessPermission('update-list')" -->
                                            <button class="btn custom_btn btn-warning-light"
                                                   
                                                    @click="$router.push({name:'add_faculty',query:{id:list.id}}).catch(()=>{})">
                                                {{$t('add_faculty')}}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                                          <p class="text-lg-center font-weight-bold" v-if="lists.length==0">{{$t('no_data')}}</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
 
    export default {
        name: "All",
        components: {},
        data() {
            return {
                lists: [],
                query: null,
            }
        },
        mounted() {
            this.getAllLists();
        },
        methods: {
             
            prepareFilters() {
                return {
                doPagination:0,
                numPerPage:1000,
                page:1,
                searchKey:this.query?this.query:''
                };
            },
            getAllLists() {
                let vm = this;
                vm.$helper.showLoader();
                let filters = vm.prepareFilters();
                let dispatch = this.$store.dispatch('moduleUniversity/getAllUniversities', filters);
                dispatch.then((response) => {
                    vm.lists = this.$store.getters['moduleUniversity/getAllUniversities'];
                    console.log(vm.lists,"mogamed");
                    vm.$helper.hideLoader();
                }).catch((error) => {
                    vm.$helper.hideLoader();
                    vm.$helper.handleError(error, vm);
                    
                });
            },
            deleteUniversity(id) {
                let vm = this;
                vm.$swal({
                    title: vm.$t('warning'),
                    text: vm.$t('are_you_sure'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: vm.$t('yes'),
                    cancelButtonText: vm.$t('no'),
                }).then((result) => {
                    if (result.value) {
                        vm.deleteRequest(id)
                    }
                });
            },
            deleteRequest(id) {
                let vm = this;
                vm.$helper.showLoader();
                let dispatch = this.$store.dispatch('moduleUniversity/removeUniversity', {
                    id: id
                });
                dispatch.then((response) => {
                    vm.$helper.hideLoader();
                    
                    let index=vm.lists.findIndex(e=>{return e.id==id})
                    this.$store.commit('moduleUniversity/REMOVE_UNIVERSITIES',index );
                     this.$helper.showMessage('success',this);
                }).catch((error) => {
                    vm.$helper.hideLoader();
                    vm.$helper.handleError(error, vm);
                    
                });
            }
        }
    }
</script>

<style scoped>

</style>