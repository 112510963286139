/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
    SET_COURSES(state, courses) {
        state.courses = courses
    },

    REMOVE_COURSE(state, index) {
      state.courses.splice(index,1)
 },
}
