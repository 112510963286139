<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#" @click.prevent>{{ $t('dashboard') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{ $t('notify_users') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="dashboard_container_body p-2">
      <div class="row">
        <div class="col-md-12">
          <div class="dashboard_container">
            <div class="row">
              <div class="col-12 pt-3">
                <h2 class="font-weight-bold fun_font text-center">{{ $t('notify_users') }}</h2>
              </div>
            </div>
            <div class=" p-2">
              <div class="row">
                <div class="col-md-6 col-xl-3">
                  <div class="form-group">
                    <label class="font-weight-bold">{{ $t('university') }}</label>

                    <multiselect
                        :select-label="$t('multiselect_choose')"
                        :options="universities" :custom-label="$helper.getTitleValueLang"
                        track-by="id" v-model="selectedUniversity"
                        @input="selectedUniversity && getAllFaculties(selectedUniversity.id)"
                        :placeholder="$t('search')"></multiselect>
                    <span class="text-danger text-sm">{{ errors.first('list_type_id') }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3">
                  <div class="form-group">
                    <label class="font-weight-bold">{{ $t('faculty') }}</label>

                    <multiselect
                        :select-label="$t('multiselect_choose')"
                        :options="faculties" :custom-label="$helper.getTitleValueLang"
                        track-by="id" v-model="selectedFaculty"
                        @input="selectedFaculty && getAllCategories(selectedFaculty.id)"
                        :placeholder="$t('search')"></multiselect>
                    <span class="text-danger text-sm">{{ errors.first('list_type_id') }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3">
                  <div class="form-group">
                    <label class="font-weight-bold">{{ $t('section') }}</label>

                    <multiselect
                        :select-label="$t('multiselect_choose')"
                        :options="categories" :custom-label="$helper.getTitleValueLang"
                        track-by="id" v-model="selectedDepartment"
                        @input="selectedDepartment && getAllYears(selectedDepartment.id)"
                        :placeholder="$t('search')"></multiselect>
                    <span class="text-danger text-sm">{{ errors.first('list_type_id') }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3">
                  <div class="form-group">
                    <label class="font-weight-bold">{{ $t('academicyear') }}</label>

                    <multiselect
                        :select-label="$t('multiselect_choose')"
                        :options="years" :custom-label="$helper.getTitleValueLang"
                        track-by="id" v-model="selectedYear" @input="getAllCourses(selectedYear && selectedYear.id)"
                        :placeholder="$t('search')"></multiselect>
                    <span class="text-danger text-sm">{{ errors.first('list_type_id') }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3">
                  <div class="form-group">
                    <label class="font-weight-bold">{{ $t('course') }}</label>

                    <multiselect
                        :select-label="$t('multiselect_choose')"
                        :options="courses" :custom-label="$helper.getTitleValueLang"
                        track-by="id" v-model="selectedCourse" data-vv-name="courseId"
                        :placeholder="$t('search')"></multiselect>
                    <span class="text-danger text-sm">{{ errors.first('courseId') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('notification_title') }}</label>
                  <input type="text"
                         v-validate="'required'" data-vv-name="notification_title"
                         v-model="dataModel.notification_title"
                         class="form-control"
                         :placeholder="$t('notification_title')">
                  <span class="text-danger text-sm">{{ errors.first('notification_title') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('notification_body') }}</label>
                  <input type="text"
                         v-validate="'required'" data-vv-name="notification_body" v-model="dataModel.notification_body"
                         class="form-control"
                         :placeholder="$t('notification_body')">
                  <span class="text-danger text-sm">{{ errors.first('notification_body') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('attachment_if_exist') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('image') }}</label>
                  <input type="file" accept="image/*" ref="myImg" @change="setImage()"
                         data-vv-name="img"
                         class="form-control"
                         :placeholder="$t('image')">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('file') }}</label>
                  <input type="file" accept="*/*" ref="myFile" @change="setFile()"
                         data-vv-name="file"
                         class="form-control"
                         :placeholder="$t('file')">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('video') }}</label>
                  <small class="font-weight-bold text-danger"> ({{ $t('max_video') }})</small>
                  <input type="file" accept="video/mp4, video/quicktime, video/x-ms-wmv, video/avi, video/x-flv"
                         ref="myVideo"
                         @change="setVideo()"
                         data-vv-name="video"
                         class="form-control"
                         :placeholder="$t('video')">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('url_link') }}</label>
                  <input type="text"
                         data-vv-name="url_link"
                         v-model="dataModel.url"
                         class="form-control"
                         :placeholder="$t('url_link_if_exist')">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard_container p-4">
      <div class="col-md-12 text-center">
        <button class="btn btn-danger-light" @click="submitForm()">
          {{ $t('send_notification') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: "Notify",
  components: {VueCropper},
  data() {
    return {
      universities: [],
      selectedUniversity: null,
      faculties: [],
      selectedFaculty: null,
      categories: [],
      selectedDepartment: null,
      years: [],
      selectedYear: null,
      courses: [],
      selectedCourse: null,
      dataModel: {
        notification_title: '',
        notification_body: '',
        url: '',
        img: null,
        file: null,
        video: null,
      },
    }
  },
  mounted() {
    this.getAllUniversities();
  },
  methods: {
    prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: this.query ? this.query : '',
        // universityId:this.$route.query.id?this.$route.query.id:'',
      };
    },
    getAllCourses(id) {
      console.log('fetching courses', id)
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.academicYearId = id;
      let dispatch = this.$store.dispatch('moduleList/getAllList', filters);
      dispatch.then((response) => {
        vm.courses = this.$store.getters['moduleList/getAllList'];
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);

      });
    },
    getAllCategories(id) {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.facultyId = id;
      let dispatch = this.$store.dispatch('moduleCategory/getAllCategories', filters);
      dispatch.then((response) => {
        vm.categories = this.$store.getters['moduleCategory/getAllCategories'];
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    getAllUniversities() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      let dispatch = this.$store.dispatch('moduleUniversity/getAllUniversities', filters);
      dispatch.then((response) => {
        vm.universities = this.$store.getters['moduleUniversity/getAllUniversities'];

        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);

      });
    },
    getAllFaculties(id) {
      console.log(id, this.universityId);
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.universityId = id;
      let dispatch = this.$store.dispatch('moduleFaculty/getAllFaculties', filters);
      dispatch.then((response) => {
        vm.faculties = this.$store.getters['moduleFaculty/getAllFaculties'];
        console.log(response, vm.faculties, "dsaasdasdasddsasa");
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);

      });
    },
    getAllYears(id) {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.DepartmentId = id;
      let dispatch = this.$store.dispatch('moduleAcedemicyears/getAllAcedemicyears', filters);
      dispatch.then((response) => {
        vm.years = this.$store.getters['moduleAcedemicyears/getAllAcedemicyears'];

        vm.$helper.hideLoader();
      }).catch((error) => {

        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    setImage() {
      this.dataModel.img = this.$refs.myImg.files[0];
      console.log(this.dataModel.img);
    },
    setVideo() {
      this.dataModel.video = this.$refs.myVideo.files[0];
      console.log(this.dataModel.video);
    },
    setFile() {
      this.dataModel.file = this.$refs.myFile.files[0];
      console.log(this.dataModel.file);
    },
    submitForm() {
      this.$validator.errors.clear();
      this.$validator.validateAll().then(result => {
        if (this.selectedCourse == null &&
            this.selectedYear == null &&
            this.selectedDepartment == null &&
            this.selectedFaculty == null &&
            this.selectedUniversity == null) {
          this.$validator.errors.add({
            field: "choose_one_of_fields",
            msg: this.$t('choose_one_of_fields')
          });
          result = false;
        }
        if (result) {
          let vm = this;
          vm.$helper.showLoader();
          const payload = {};
          payload.config = {
            headers: {
              'content-type': 'multipart/form-data'
            },
          };
          const formData = new FormData();
          formData.append('notificationTitle', this.dataModel.notification_title);
          formData.append('notificationBody', this.dataModel.notification_body);
          if (this.selectedCourse && this.selectedCourse.id)
            formData.append("courseId",this.selectedCourse.id);
          else if (this.selectedYear && this.selectedYear.id)
            formData.append("academicYearId",this.selectedYear.id);
          else if (this.selectedDepartment && this.selectedDepartment.id)
            formData.append("deptId",this.selectedDepartment.id);
          else if (this.selectedFaculty && this.selectedFaculty.id)
            formData.append("facultyId",this.selectedFaculty.id);
          else if (this.selectedUniversity && this.selectedUniversity.id)
            formData.append("universityId",this.selectedUniversity.id);
          else
            return;
          this.dataModel.img && formData.append('img', this.dataModel.img);
          this.dataModel.file && formData.append('file', this.dataModel.file);
          this.dataModel.video && formData.append('vedio', this.dataModel.video);
          this.dataModel.url && formData.append('zoom', this.dataModel.url);
          payload.data = formData;
          let dispatch = this.$store.dispatch('moduleAdmin/notifyUsers', payload);
          dispatch.then((response) => {
            console.log(response);
            vm.$helper.showMessage('success', vm);
            vm.$helper.hideLoader();
          }).catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
        } else {
          $('html, body').animate({scrollTop: 19}, 'slow');
          this.$helper.printValidationError(this.$validator.errors, this);
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
