import { min } from "lodash";

const translationArMessages = {
    custom: {
        name: {
            required: () => 'الاسم مطلوب'
        },
        code: {
            required: () => 'الكود مطلوب'
        },

        bio: {
            required: () => 'السيرة الذاتية مطلوب'
        },
        title_ar: {
            required: () => 'الاسم بالعربية مطلوب'
        },
        name_ar: {
            required: () => 'الاسم بالعربية مطلوب',
            min: () => 'الاسم يجب ان يكون اقل من ٣٠ واكبر من ٣ حروف',
            max: () => 'الاسم يجب ان يكون اقل من ٣٠ واكبر من ٣ حروف',
        },
        description_ar: {
            required: () => 'الوصف بالعربية مطلوب'
        },
        desc: {
            required: () => 'الوصف مطلوب',
            min:()=>'يجب ان يكون الوصف اكبر من ٥ حروف',
        },
        vedio: {
            required: () => 'الفيديو مطلوب'
        },
        universityId: {
            required: () => 'الجامعة مطلوب'
        },
        facultyId: {
            required: () => 'الكلية مطلوب'
        },
        groupId: {
            required: () => 'المجموعة مطلوب'
        },

        method: {
            required: () => 'نوع الدوره مطلوب'
        },

        departmentId: {
            required: () => 'القسم مطلوب'
        },
        academicYearId: {
            required: () => 'السنة الدراسية مطلوب'
        },
        title_en: {
            required: () => 'الاسم بالانجليزية مطلوب'
        },
        name_en: {
            required: () => 'الاسم بالانجليزية مطلوب',
            min: () => 'الاسم يجب ان يكون اقل من ٣٠ واكبر من ٣ حروف',
            max: () => 'الاسم يجب ان يكون اقل من ٣٠ واكبر من ٣ حروف',
        },
        description_en: {
            required: () => 'الوصف بالانجليزية مطلوب'
        },
        category_id: {
            required: () => 'التصنيف مطلوب'
        },
        list_type_id: {
            required: () => 'نوع الدورة مطلوب'
        },
        list: {
            required: () => ' الدورة مطلوب'
        },
        courseId: {
            required: () => ' الدورة مطلوب'
        },
        attachement_price: {
            required: () => ' سعر ملفات الدوره مطلوب'
        },
        attachement: {
            required: () => ' الملف مطلوب'
        },
        course_keyword: {
            required: () => ' نوع الدوره مطلوب',
            min:()=>'عدد الحروف اكبر من ٢'
        },
        subject_id: {
            required: () => 'الماده مطلوب'
        },
        subjectId: {
            required: () => 'الماده مطلوب'
        },
        list_method_id: {
            required: () => 'طريقة الشرح مطلوب'
        },
        start_date: {
            required: () => 'تاريخ البداية مطلوب'
        },
        startDate: {
            required: () => 'تاريخ البداية مطلوب'
        },
        startDateGroup: {
            required: () => 'تاريخ البداية مطلوب'
        },
        end_date: {
            required: () => 'تاريخ النهاية مطلوب'
        },
        end_assignment_date: {
            required: () => 'تاريخ نهاية التسليم مطلوب'
        },
        assignmentDeadLineDate: {
            required: () => 'تاريخ نهاية التسليم مطلوب',
            required_if: () => 'تاريخ نهاية التسليم مطلوب'
        },
        endDateGroup: {
            required: () => 'تاريخ النهاية مطلوب'
        },
        liveStreamingTime: {
            required: () => 'تاريخ  مطلوب',
            required_if: () => 'تاريخ  مطلوب'
        },
        liveStreamingEndTime: {
            required: () => 'تاريخ النهاية مطلوب',
            required_if: () => 'تاريخ النهاية مطلوب',
            after:()=>'تاريخ نهايه البث المباشر يجب ان تكون بعد تاريخ بدايته',
        },
        lectures_count: {
            required: () => 'عدد المحاضرات مطلوب'
        },
        hours_count: {
            required: () => 'عدد الساعات مطلوب'
        },
        numOfHours: {
            required: () => 'عدد الساعات مطلوب',
            numeric:()=>   'عدد الساعات يجب ان يكون رقم',
            // min: 'this field must have no less than {length} characters',
        },
        whatYouWillLearn: {
            required: () => 'ماذا ستتعلم مطلوب',
            min:()=>'يجب ان يكون اكبر من ٥ حروف'
        },
        prerequisiteText: {
            required: () => 'المتطلبات مطلوب',
            min:()=>'يجب ان يكون اكبر من ٥ حروف'
        },
        numOfLessons: {
            required: () => 'عدد الدروس مطلوب',
            numeric:()=>   'عدد الدروس يجب ان يكون رقم',
            // min: 'this field must have no less than {length} characters',
        },
        nameGroup: {
            required: () => 'اسم المجموعة مطلوب',
            min: ()=>' عدد الحروف اقل من ٣٠ واكبر من ٣',
            max:()=> ' عدد الحروف اقل من ٣٠ واكبر من ٣',
        },
        group_name: {
            required: () => 'اسم المجموعة مطلوب'
        },
        maxNumOfStudentsGroup: {
            required: () => 'العدد المسموح مطلوب',
            numeric:()=>'يجب ان يكون رقم'
        },
        limit_numbers: {
            required: () => 'العدد المسموح مطلوب'
        },
        liveStreamingInfo: {
            required: () => 'بيانات الميتنج مطلوب',
            min: () => 'بيانات الميتنج يجب ان تتعدي ال٣ حروف'
        },
        role_id: {
            required: () => 'الدور مطلوب'
        },
        video: {
            required: () => 'الفيديو مطلوب'
        },
        image: {
            required: () => 'الصورة مطلوب'
        },
        img: {
            required: () => 'الصورة مطلوب'
        },
        file: {
            required: () => 'الملف مطلوب'
        },
        price: {
            required: () => 'السعر مطلوب',
            numeric: () => 'السعر يجب ان يكون رقم'
        },
        price_before_discount: {
            required: () => 'السعر قبل الخصم مطلوب',
            numeric: () => 'السعر يجب ان يكون رقم'
        },
        priceBeforeDiscount: {
            required: () => 'السعر قبل الخصم مطلوب',
            numeric: () => 'السعر يجب ان يكون رقم'
        },
        price_duration: {
            required: () => 'مدة الشراء بالايام مطلوب'
        },
        hours_number: {
            required: () => 'عدد الساعات مطلوب'
        },
        type: {
            required: () => 'النوع مطلوب'
        },
        username: {
            required: () => 'اسم المستخدم مطلوب'
        },
        secret_code: {
            required: () => 'رمز التحقق مطلوب'
        },
        phone: {
            required: () => 'رقم الهاتف مطلوب'
        },
        mobile: {
            required: () => 'رقم الهاتف مطلوب'
        },
        new_password: {
            required: () => 'كلمة المرور الجديدة مطلوب'
        },
        new_password_confirmation: {
            required: () => 'تأكيد كلمة المرور الجديدة مطلوب'
        },
        password: {
            required: () => 'كلمة المرور مطلوب',
            min:()=>'كلمه المرور يجب ان تتكون من ٥ حروف او اكثر'
        },
        password_confirmation: {
            required: () => 'تأكيد كلمة المرور مطلوب',
            min:()=>'كلمه المرور يجب ان تتكون من ٥ حروف او اكثر'
        },
        old_password: {
            required: () => ' كلمة المرور القديمة مطلوب'
        },
        gender: {
            required: () => 'النوع مطلوب'
        },
        instructor: {
            required: () => 'المحاضر مطلوب'
        },
        instructorId: {
            required: () => 'المحاضر مطلوب'
        },
        email: {
            required: () => 'البريد الالكتروني مطلوب',
            email: () => 'البريد الالكتروني يجب ان يكون في صورته الصحيحه'
        },
        comment: {
            required: () => 'التعليق مطلوب'
        },
        statusComments: {
            required: () => 'التعليق مطلوب'
        },
        post: {
            required: () => ' يجب اضافة منشور'
        },
        text: {
            required: () => ' يجب اضافة منشور'
        },
        choose_from_your_courses: {
            required: () => 'يجب إختيار إحدى المواد الخاصة بك'
        },
        choose_one_of_fields: {
            required: () => 'يجب على الأقل تحديد الجامعة أو الكلية أو القسم أو السنة الدراسية أو الدرس'
        },
        notification_title: {
            required: () => 'عنوان الإخطار مطلوب'
        },
        notification_body: {
            required: () => 'مضمون الإخطار مطلوب'
        },
        coupon_code: {
            required: () => 'كود الكوبون مطلوب'
        },
        coupon_value: {
            required: () => 'القيمة مطلوبة'
        },
        discount_type: {
            required: () => 'نوع الخصم مطلوب'
        },
        max_user: {
            required: () => 'الحد الأقصى للمستخدمين مطلوب'
        },
        marketer: {
            required: () => 'المسوق مطلوب'
        },
        semester: {
            required: () => 'التيرم مطلوب'
        },
    }
}
export default translationArMessages;
