<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('ads') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row p-2">
            <div class="col-12 pt-3 text-center">
              <h2 class="font-weight-bold fun_font">{{ $t('ads') }}</h2>
            </div>
            <div class="col-12 pt-3 text-left">
              <input type="file" accept="image/*" @change="previewImage()" ref="myImage" multiple
                     data-vv-name="img"
                     class="form-control d-none">
              <button
                  class="btn btn-danger-light mx-2" @click="$refs.myImage.click()"
              >{{ $t('add') }}
              </button>
              <p class="mx-2">يفضل أن يكون مقاس الصور ثابت</p>
            </div>
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('image') }}</th>
                  <th class="text-left">{{ $t('adding_date') }}</th>
                  <th class="text-left" style="width: 30px">{{ $t('operation') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in items" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td><img class="img-fluid" :src="item.img" alt="الصورة" style="max-height: 80px"/></td>
                  <td>{{ parseDate(item.createdAt) }}</td>
                  <td>
                    <div class="btn-group direction-inverse">
                      <button
                          class="btn custom_btn btn-primary-light"
                          @click="deleteItem(item.id)"
                      >{{ $t('delete') }}
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="items.length==0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {cloneDeep} from "lodash";
import moment from "moment";

export default {
  name: "All",

  data() {
    return {
      items: [],
      img: undefined,
    }
  },
  mounted() {
    this.getAds();
  },
  methods: {
    previewImage() {
      if (this.$refs.myImage.files.length) {
        let vm = this;
        vm.$swal({
          title: vm.$t("warning"),
          text: vm.$t("are_you_sure"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: vm.$t("yes"),
          cancelButtonText: vm.$t("no"),
        }).then(async (result) => {
          if (result.value) {
            vm.$helper.showLoader();
            for (const file of this.$refs.myImage.files) {
              await vm.addAd(file);
            }
            vm.$helper.hideLoader();
            vm.getAds();
            vm.$helper.showMessage('success', vm, 'تم إضافة العناصر بنجاح');
          }

          this.$refs.myImage.value = '';
        });
      }
    },
    parseDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return ' - ';
    },
    async getAds() {
      let vm = this;
      vm.$helper.showLoader();

      const res = await this.$store.dispatch("moduleAdmin/getAds").catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });

      if (res.data.status) {
        vm.items = res.data.data;
      }
      vm.$helper.hideLoader();
    },

    deleteItem(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },

    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch("moduleAdmin/removeAd", {id})
          .then(value => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage('success', vm, 'تم حذف العنصر');
            vm.items = cloneDeep(vm.items).filter(item => item.id != id);
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },

    async addAd(file) {
      let vm = this;

      const payload = {};
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          $('#loader_message').text(percentCompleted + ' %')
        }
      };
      const formData = new FormData();
      formData.append('img', file);

      payload.data = formData;
      payload.config = config;

      await this.$store.dispatch("moduleAdmin/addAd", payload).catch((error) => {
        vm.$helper.handleError(error, vm);
      });
    },
  },
};
</script>

<style>
.multiselect__select {
  height: 55px;
}

.multiselect__tags {
  min-height: 55px;
  padding: 15px 40px 0 8px;
}
</style>
