const translationEnMessages = {
    custom: {
        name: {
            required: () => 'name is Required'
        },
        code: {
            required: () => 'code is Required'
        },

        bio: {
            required: () => 'Bio is Required'
        },
        title_ar: {
            required: () => 'Arabic name is Required'
        },
        name_ar: {
            required: () => 'English name is Required'
        },
        description_ar: {
            required: () => 'Arabic description is Required'
        },
        desc: {
            required: () => 'Description is Required'
        },
        vedio: {
            required: () => 'Video is Required'
        },
        universityId: {
            required: () => 'University is Required'
        },
        facultyId: {
            required: () => 'Faculty is Required'
        },
        groupId: {
            required: () => 'Group is Required'
        },
        departmentId: {
            required: () => 'Department is Required'
        },
        academicYearId: {
            required: () => 'Acedemic year is Required'
        },
        title_en: {
            required: () => 'English name is Required'
        },
        name_en: {
            required: () => 'English name is Required'
        },
        description_en: {
            required: () => 'English description is Required'
        },
        category_id: {
            required: () => 'Category is Required'
        },
        list_type_id: {
            required: () => 'Course Type is Required'
        },
        list: {
            required: () => ' Course is Required'
        },
        courseId: {
            required: () => ' Course is Required'
        },
        attachement_price: {
            required: () => 'Attachement price is Required'
        },
        attachement: {
            required: () => ' Attachemnt is Required'
        },
        course_keyword: {
            required: () => ' Course keyword is Required'
        },
        end_assignment_date: {
            required: () => 'end assignment date is required'
        },
        assignmentDeadLineDate: {
            required: () => 'end assignment date is required'
        },
        subject_id: {
            required: () => 'Subject is Required'
        },
        method: {
            required: () => 'method is Required'
        },
        subjectId: {
            required: () => 'Subject is Required'
        },
        list_method_id: {
            required: () => 'Explain type is Required'
        },
        start_date: {
            required: () => 'start date is Required'
        },
        startDate: {
            required: () => 'start date is Required'
        },
        startDateGroup: {
            required: () => 'start date is Required'
        },
        end_date: {
            required: () => 'end date is Required'
        },
        endDateGroup: {
            required: () => 'end date is Required'
        },
        liveStreamingTime: {
            required: () => 'date is Required'
        },
        liveStreamingEndTime: {
            required: () => 'end date is Required'
        },
        lectures_count: {
            required: () => 'Lectures count is Required'
        },
        hours_count: {
            required: () => 'total hours is Required'
        },
        // numOfHours: {
        //     required: () => 'total hours is Required'
        // },
        whatYouWillLearn: {
            required: () => 'what You Will Learn is Required'
        },
        prerequisiteText: {
            required: () => 'Prerequisite is Required'
        },
        // numOfLessons: {
        //     required: () => 'Total Lessons is Required'
        // },
        nameGroup: {
            required: () => 'Group name is Required'
        },
        group_name: {
            required: () => 'Group name is Required'
        },
        maxNumOfStudentsGroup: {
            required: () => 'max group number is Required'
        },
        limit_numbers: {
            required: () => 'allowed numbers is Required'
        },
        liveStreamingInfo: {
            required: () => 'Meeting info is Required'
        },
        role_id: {
            required: () => 'Role is Required'
        },
        video: {
            required: () => 'video is Required'
        },
        image: {
            required: () => 'image is Required'
        },
        img: {
            required: () => 'image is Required'
        },
        file: {
            required: () => 'file is Required'
        },
        price: {
            required: () => 'price is Required'
        },
        price_before_discount: {
            required: () => 'price before discount is Required'
        },
        priceBeforeDiscount: {
            required: () => 'price before discount is Required'
        },

        hours_number: {
            required: () => 'hours count is Required'
        },
        type: {
            required: () => 'Type is Required'
        },
        username: {
            required: () => 'User name is Required'
        },
        secret_code: {
            required: () => 'Secret code is Required'
        },
        phone: {
            required: () => 'phone is Required'
        },
        mobile: {
            required: () => 'mobile is Required'
        },
        new_password: {
            required: () => 'New Password is Required'
        },
        new_password_confirmation: {
            required: () => 'New password Confirmation is Required'
        },
        password: {
            required: () => 'Password is Required'
        },
        password_confirmation: {
            required: () => 'New password Confirmation is Required'
        },
        old_password: {
            required: () => ' Old password is Required'
        },
        gender: {
            required: () => 'Gender is Required'
        },
        instructor: {
            required: () => 'Instructor is Required'
        },
        instructorId: {
            required: () => 'Instructor is Required'
        },
        email: {
            required: () => 'Email is Required',
            email: () => 'Email must be in right email format'
        },
        comment: {
            required: () => 'Comment is Required'
        },
        statusComments: {
            required: () => 'Comment is Required'
        },
        post: {
            required: () => ' must add post'
        },
        text: {
            required: () => '  must add post'
        },
        choose_from_your_courses: {
            required: () => 'You must choose one of your courses'
        },
        notification_title: {
            required: () => 'Notification Title Required'
        },
        notification_body: {
            required: () => 'Notification Body Required'
        },
        coupon_code: {
            required: () => 'Coupon Code Required'
        },
        coupon_value: {
            required: () => 'Value Required'
        },
        discount_type: {
            required: () => 'Discount Type Required'
        },
        max_user: {
            required: () => 'Max Users Required'
        },
        marketer: {
            required: () => 'Marketer Required'
        },
        semester: {
            required: () => 'Semester Required'
        },
    }
}
export default translationEnMessages;
