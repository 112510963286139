<template>
  <div style="min-height:1000px;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb d-print-none">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('getAllCoursesReport') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-2">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('getAllCoursesReport') }}</h2>
            </div>
          </div>

          <div class="mt-2">
            <div class="d-flex justify-content-end">
              <button class="btn btn-success-light mx-1" @click="toggleCompareCalcs()"><i class="ti-filter"></i> {{ $t('compare_calculations') }}</button>
              <button class="btn btn-success-light mx-1" @click="togglePostponedCourses()"><i class="ti-filter"></i> {{ $t('show_postponed') }}</button>
            </div>

            <div class="d-flex justify-content-end align-items-end" v-if="compareCalculations.isOpened">
              <div class="form-group">
                <label class="font-weight-bold">{{ $t("start_date") }}</label>
                <flat-pickr
                    v-model="compareCalculations.startDate"
                    class="form-control"
                    :placeholder="$t('start_date')"
                />
              </div>
              <div class="form-group mx-2">
                <label class="font-weight-bold">{{ $t("end_date") }}</label>
                <flat-pickr
                    v-model="compareCalculations.endDate"
                    class="form-control"
                    :placeholder="$t('end_date')"
                />
              </div>

              <div class="form-group">
                <button class="btn btn-black mx-1" @click="getAllExpiredSubscriptions()">{{ $t('compare') }}</button>
                <button class="btn btn-danger-light btn-circle" @click="resetFilters('expired')"><i class="ti ti-reload"></i></button>
              </div>
            </div>

            <div class="d-flex justify-content-end align-items-end" v-if="postponedCourses.isOpened">
              <div class="form-group">
                <label class="font-weight-bold">{{ $t("start_date") }}</label>
                <flat-pickr
                    v-model="postponedCourses.startDate"
                    class="form-control"
                    :placeholder="$t('start_date')"
                />
              </div>
              <div class="form-group mx-2">
                <label class="font-weight-bold">{{ $t("end_date") }}</label>
                <flat-pickr
                    v-model="postponedCourses.endDate"
                    class="form-control"
                    :placeholder="$t('end_date')"
                />
              </div>

              <div class="form-group">
                <button class="btn btn-black mx-1" @click="getAllHoldingSubscriptions()">{{ $t('show') }}</button>
                <button class="btn btn-danger-light btn-circle" @click="resetFilters('holding')"><i class="ti ti-reload"></i></button>
              </div>
            </div>
          </div>

          <div class="dashboard_container_body">
            <div class="row">
              <div :class="expiredUniversities && expiredUniversities.length ? 'col-6' : 'col-12'">
                <h6>الإشتراكات الحالية</h6>
                <div v-for="(uni , i) in universities" :key="uni.university.id">
                  <h4 class="primary-clr" v-if="uni.courses.length">{{ $helper.getTitleValueLang(uni.university) }}</h4>

                  <div class="table-responsive" v-if="uni.courses.length">
                    <table class="table table-bordered less-table-padding">
                      <thead class="thead-default">
                      <tr>
                        <th class="text-center">#</th>
                        <th class="text-left">{{ $t('list') }}</th>
                        <th class="text-left">{{ $t('subscriptions_count') }}</th>
                        <th class="text-left">{{ $t('teacher_percent') }}</th>
                        <th class="text-left">{{ $t('app_percent') }}</th>
                        <th class="text-left">{{ $t('transfer_cost') }}</th>
                        <th class="text-left">{{ $t('total') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template>
                        <tr v-for="(course , index) in uni.courses" :key="course.id">
                          <td class="text-center">{{ index + 1 }}</td>

                          <td><span class="text-blue cursor-pointer" @click="$router.push({name:'getCourseReport',params:{id:course.id}}).catch(()=>{});">{{ $helper.getTitleValueLang(course) }}</span></td>

                          <td>{{ course.totals.count }}</td>
                          <td>{{ course.totals.teacherPercentage.toFixed(2).replace(/\.00$/, '') }}</td>
                          <td>{{ course.totals.appPercentage.toFixed(2).replace(/\.00$/, '') }}</td>
                          <td>{{ course.totals.transferCost }}</td>
                          <td class="font-weight-bolder">{{ course.totals.course || 0 }}</td>
                        </tr>
                        <tr>
                          <td :colspan="2" class="p-0 pt-1 px-2 font-weight-bolder color--success" style="font-size: 18px">{{ $t('total') }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ uni.universityTotals.count || 0 }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ (uni.universityTotals.teacherPercentage || 0).toFixed(2).replace(/\.00$/, '') }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ (uni.universityTotals.appPercentage || 0).toFixed(2).replace(/\.00$/, '') }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ uni.universityTotals.transferCost || 0 }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ uni.universityTotals.course || 0 }}</td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div v-if="expiredUniversities && expiredUniversities.length" class="col-6">
                <h6>الإشتراكات المنتهية</h6>
                <div v-for="(uni , i) in expiredUniversities" :key="uni.university.id">
                  <h4 class="primary-clr" v-if="uni.courses.length">{{ $helper.getTitleValueLang(uni.university) }}</h4>

                  <div class="table-responsive" v-if="uni.courses.length">
                    <table class="table table-bordered less-table-padding">
                      <thead class="thead-default">
                      <tr>
                        <th class="text-center">#</th>
                        <th class="text-left">{{ $t('list') }}</th>
                        <th class="text-left">{{ $t('subscriptions_count') }}</th>
                        <th class="text-left">{{ $t('teacher_percent') }}</th>
                        <th class="text-left">{{ $t('app_percent') }}</th>
                        <th class="text-left">{{ $t('transfer_cost') }}</th>
                        <th class="text-left">{{ $t('total') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template>
                        <tr v-for="(course , index) in uni.courses" :key="course.id">
                          <td class="text-center">{{ index + 1 }}</td>

                          <td><span class="text-blue cursor-pointer" @click="$router.push({name:'getCourseReport',params:{id:course.id}}).catch(()=>{});">{{ $helper.getTitleValueLang(course) }}</span></td>

                          <td>{{ course.totals.count }}</td>
                          <td>{{ course.totals.teacherPercentage.toFixed(2).replace(/\.00$/, '') }}</td>
                          <td>{{ course.totals.appPercentage.toFixed(2).replace(/\.00$/, '') }}</td>
                          <td>{{ course.totals.transferCost }}</td>

                          <td class="font-weight-bolder">{{ course.totals.course || 0 }}</td>
                        </tr>
                        <tr>
                          <td :colspan="2" class="p-0 pt-1 px-2 font-weight-bolder color--success" style="font-size: 18px">{{ $t('total') }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ uni.universityTotals.count || 0 }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ (uni.universityTotals.teacherPercentage || 0).toFixed(2).replace(/\.00$/, '') }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ (uni.universityTotals.appPercentage || 0).toFixed(2).replace(/\.00$/, '') }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ uni.universityTotals.transferCost || 0 }}</td>
                          <td class="font-weight-bolder color--success p-0 pt-1 px-2 text-center" style="font-size: 18px">{{ uni.universityTotals.course || 0 }}</td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="m-4">
              <h4 class="primary-clr">إحصائيات</h4>

              <template v-for="(uni , i) in universities">
                <div class="mb-3" :key="uni.university.id" v-if="uni.barChartData.labels.length > 0 && uni.barChartData.datasets.length > 0">
                  <h6>{{ uni.university.name_ar }}</h6>

                  <Bar :chart-data="uni.barChartData"
                       :height="barHeight"/>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Bar } from 'vue-chartjs/legacy';

import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default {
  name: "All",
  components: { Bar },
  props: {
    barHeight: {
      type: Number,
      default: 150
    },
  },
  data() {
    return {
      universities: [],
      expiredUniversities: [],
      compareCalculations: {
        isOpened: false,
        startDate: '',
        endDate: '',
      },
      postponedCourses: {
        isOpened: false,
        startDate: '',
        endDate: '',
      },
    };
  },
  mounted() {
    this.getAllUniversities().then(result => {
      this.universities = result;
      this.prepareCharts();
    });
  },
  methods: {
    toggleCompareCalcs() {
      this.compareCalculations.isOpened = !this.compareCalculations.isOpened;
      this.postponedCourses.isOpened = false;
    },
    togglePostponedCourses() {
      this.postponedCourses.isOpened = !this.postponedCourses.isOpened;
      this.compareCalculations.isOpened = false;
    },
    prepareCharts() {
      this.universities.forEach(university => {
        university.barChartData = {
          labels: [],
          datasets: [
            {
              label: 'الإشتراكات الحالية',
              backgroundColor: [],
              borderRadius: 5,
              data: []
            },
            {
              label: 'الإشتراكات المنتهية',
              backgroundColor: [],
              borderRadius: 5,
              data: []
            },
          ],
        };
      });

      const coursesObj = {};

      this.universities.forEach(university => {
        university.barChartData = university.barChartData || {};

        university.courses.forEach(course => {
          if (course.totals.course > 0) {
            coursesObj[university.university.id] = coursesObj[university.university.id] || {};
            coursesObj[university.university.id][course.id] = coursesObj[university.university.id][course.id] || {};

            coursesObj[university.university.id][course.id].name_ar = course.name_ar;
            coursesObj[university.university.id][course.id].name_ar = course.name_ar;
            coursesObj[university.university.id][course.id].total = course.totals.course;
            coursesObj[university.university.id][course.id].color = this.generateRandomColor();
          }
        });
      });

      if (this.expiredUniversities.length) {
        this.expiredUniversities.forEach(university => {
          university.courses.forEach(course => {
            if (course.totals.course > 0) {
              coursesObj[university.university.id] = coursesObj[university.university.id] || {};
              coursesObj[university.university.id][course.id] = coursesObj[university.university.id][course.id] || {};

              coursesObj[university.university.id][course.id].name_ar = course.name_ar;
              coursesObj[university.university.id][course.id].expiredTotal = course.totals.course;
            }
          });
        });
      }

      this.universities.forEach(university => {
        coursesObj[university.university.id] = coursesObj[university.university.id] || {};
        Object.values(coursesObj[university.university.id]).forEach(course => {
          university.barChartData.labels.push(course.name_ar);
          university.barChartData.datasets[0].data.push(course.total || 0);
          university.barChartData.datasets[0].backgroundColor.push(course.color);

          if (this.expiredUniversities.length) {
            university.barChartData.datasets[1].data.push(course.expiredTotal || 0);
            university.barChartData.datasets[0].backgroundColor.push(course.color);
          }
        });
      });
    },
    generateRandomColor() {
      return `#${Math.floor(Math.random()*16777215).toString(16)}`;
    },

    async resetFilters(type) {
      switch (type) {
        case 'expired':
          this.expiredUniversities = [];
          this.compareCalculations.startDate = '';
          this.compareCalculations.endDate = '';
          this.prepareCharts();
          break;
        case 'holding':
          this.postponedCourses.startDate = '';
          this.postponedCourses.endDate = '';
          this.universities = await this.getAllUniversities();
          this.prepareCharts();
          break;
      }
    },

    async getAllExpiredSubscriptions() {
      const params = {
        expired: '1',
        paymentResult: 'EXPIRED',
      };

      this.compareCalculations.startDate && (params.expiredFrom = this.compareCalculations.startDate);
      this.compareCalculations.endDate && (params.expiredTo = this.compareCalculations.endDate);

      this.expiredUniversities = await this.getAllUniversities(params);
      this.prepareCharts();
    },

    async getAllHoldingSubscriptions() {
      const params = {
        paymentResult: 'CAPTURED,HOLDING',
        expired: '0',
      };

      this.postponedCourses.startDate && (params.holdingFrom = this.postponedCourses.startDate);
      this.postponedCourses.endDate && (params.holdingTo = this.postponedCourses.endDate);

      this.universities = await this.getAllUniversities(params);
      this.prepareCharts();
    },

    getAllUniversities(params) {
      return new Promise((resolve, reject) => {
        let vm = this;
        vm.$helper.showLoader();

        let reqParams = {
          paymentResult: 'CAPTURED',
          expired: '0',
        };

        params && (reqParams = params);

        this.$store.dispatch('moduleSubscribe/getAllSubscriptionsReport', reqParams).then((response) => {
          const universities = response.data.data;

          const index = universities.map(uni => uni.university.name_ar).indexOf('جامعه تجريبيه');

          if (index > -1) {
            universities.splice(index, 1);
          }

          resolve(universities);
          vm.$helper.hideLoader();
        }).catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
          resolve([]);
        });
      });
    },
  },
};
</script>
