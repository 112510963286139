<template>
  <div class="container">
    <u-animate-container>
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header border-bottom-1">
              <h4 class="font-weight-bold">{{$t('my_courses')}} ({{lists.length}})</h4>
            </div>
              <div class="p-2">
                <div class="row justify-content-center mt-2">
                  <div
                    class="col-3"
                    v-if="$helper.isInstructor()"
                  >
                    <button
                      class="btn btn-primary-light"
                     @click="$router.push({name:$helper.getRouteFullName('add_lists')}).catch(()=>{})"
                    >
                      {{ $t("add_list") }}
                        </button>
                  </div>
                </div>
              </div>
            <div class="card-body">
              <div class="p-2" v-for="(list,index) in lists" :key="index">
                <u-animate
                  name="bounceInDown"
                  :delay="index/100+'s'"
                  duration="0.5s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
                >
                  <div
                    class="row direction text-left pointer"
                  >
                     <div class="col-md-6"   @click="$router.push({name:'teacher_dashboard_courses',params:{id:list.id},query:{list_id:list.id,method:list.method,type:list.type}}).catch(()=>{})">
                      <div class="d-flex">
                        <div class="text-center" style="flex: 1">
                          <img :src="require('@/assets/img/3180572.png')" width="60px" alt />
                        </div>
                        <div style="flex: 6">
                          <h4>{{$helper.getTitleValueLang(list)}}</h4>
                          <p v-html="list.desc">

                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3"   @click="$router.push({name:'teacher_dashboard_courses',params:{id:list.id},query:{list_id:list.id,method:list.method,type:list.type}}).catch(()=>{})"></div>
                    <div class="col-md-3" >
                      <div class="text-right direction-inverse">
                        <i class="ti-calendar text-danger theme-cl"></i>
                        <span class="font-weight-bold text-dark ">{{$helper.formatDate(list.startDate)}}</span>
                      </div>
                      <div class="text-right pt-2 direction-inverse">
                        <i class="ti-user text-danger theme-cl"></i>
                        <span class="font-weight-bold text-dark text-small">{{list.courseSubscribes.length}} Student</span>
                      </div>
                      <div class="text-right pt-2 direction-inverse">
                        <i class="ti-credit-card text-danger theme-cl"></i>
                        <span class="font-weight-bold text-dark text-small">{{list.price}} KWD</span>
                      </div>
                       <div class="text-right pt-2 direction-inverse">
                        <h4 class="font-weight-bold ml-3">{{list.AVGRatingAndCount.ratingAVG?list.AVGRatingAndCount.ratingAVG.toFixed(1)+ '/5':'' }}</h4>
                      <button v-if="list.AVGRatingAndCount.ratingAVG"
                        class="btn btn-outline-info btn-sm"
                        @click="getCourseRating(list.id)"
                      >
                        {{ $t("show_rating") }}
                      </button>
                       <span class="font-weight-bold text-dark text-small" v-else>{{$t('no_rating')}}</span>

                        </div>

                     </div>
                    <div class="col-md-12 mt-2" >
                        <ul class="tags">
                          <li><a href="#" class="tag">{{list.method==1?$t('live'):$t('recorded')}}</a></li>
                          <li><a href="#" class="tag">{{list.type==1?$t('assignment'):$t('visual_lesson')}}</a></li>
                          <li><a href="#" class="tag">{{list.course_keyword}}</a></li>
                          <li><a href="#" class="tag" v-if="list.method==1">{{$t('group_num')}}:{{list.groups.length}}</a></li>
                        </ul>
                    </div>
                    <div class="col-md-12" >
                      <div class="d-flex justify-content-center">
                        <!-- <div class=""> -->
                        <button class="btn btn-success-light ml-2 btn-sm" v-if="list.method==1"
                                @click="$router.push({name:$helper.getRouteFullName('add_groups'),params:{id:list.id}}).catch(()=>{})">{{ $t('add_group') }}
                        </button>

                        <button class="btn btn-blue-light ml-2 btn-sm"
                                @click="$router.push({name: 'subscription_points',params:{id:list.id}}).catch(()=>{})">{{ $t('subscription_points') }}
                        </button>

                        <button class="btn btn-success-light btn-sm" @click="$router.push({name:$helper.getRouteFullName('edit_lists'),params:{id:list.id}}).catch(()=>{})">
                          {{ $t('edit') }}
                        </button>
                        <!-- </div> -->
                        <!-- <div class=""> -->
                        <button class="btn btn-danger-light btn-sm" @click="deleteList(list.id)">{{ $t('delete') }}</button>
                        <!-- </div> -->
                      </div>
                      <hr class="hr_bg" v-if="index < lists.length -1"/>
                    </div>
                  </div>
                </u-animate>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header border-bottom-1">
              <h4 class="font-weight-bold">{{$t('my_discussion')}} ({{discussions.length}})</h4>
            </div>
            <div class="card-body">
              <div class="p-2" v-for="(discussion,index) in discussions" :key="index">
                <u-animate
                   name="bounceInDown"
                  :delay="index/100+'s'"
                  duration="0.5s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
                >
                  <div
                    @click="$router.push({name:$helper.getRouteFullName('all_discussions')}).catch(()=>{})"
                    class="row direction text-left pointer"
                  >
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-8">
                          <h4 class="text-danger">
                            <i class="ti-user text-danger"></i>
                            {{discussion.user.username}}
                          </h4>
                          <p>
                           {{discussion.text}}
                          </p>
                        </div>
                        <div class="col-md-4 direction-inverse text-right">
                          <div>
                            <i class="ti-calendar text-danger theme-cl"></i>
                            <span class="font-weight-bold text-dark text-small">{{$helper.getTitleValueLang(discussion.lesson)}}</span>
                          </div>
                          <div>
                            <label class="badge badge-danger">{{$helper.formatDate(discussion.updatedAt)}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="index < discussions.length -1">
                      <hr class="hr_bg" />
                    </div>
                  </div>
                </u-animate>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header border-bottom-1">
              <h4 class="font-weight-bold">{{$t('my_assignments')}} ({{assignments.length}})</h4>
            </div>
            <div class="card-body">
              <div class="p-2" v-for="(assignment,index) in assignments" :key="index">
                <u-animate
                  name="bounceInDown"
                  :delay="index/100+'s'"
                  duration="0.5s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
                >
                  <div
                    class="row direction text-left pointer"
                  >
                    <div class="col-md-12">
                                       <div class="row" @click="$router.push({name:$helper.getRouteFullName('all_assignments')}).catch(()=>{})">
                        <div class="col-md-8">
                          <h4 class="text-danger">
                            <i class="ti-user text-danger"></i>
                            {{$helper.getTitleValueLang(assignment)}}
                          </h4>
                          <h6> {{ submissionStatusValues[
                          assignment.status ? assignment.status : 2
                        ] }}</h6>
                           <p> {{assignment.statusComments?assignment.statusComments:''}}</p>
                        </div>
                        <div class="col-md-4 direction-inverse text-right " >
                          <div>
                            <i class="ti-calendar text-danger theme-cl"></i>
                            <span class="font-weight-bold text-dark text-small"> {{$helper.formatDate(assignment.updatedAt)}}</span>
                            <p class="font-weight-bold text-dark text-small" style="direction: rtl;"> {{$t('student_name')}}:{{assignment.student.name}}</p>
                            <p class="font-weight-bold text-dark text-small" style="direction: rtl;">{{$t('date')}}:{{$helper.formatDate(assignment.student.updatedAt)}}</p>
                          </div>
                          <div>
                            <label class="badge badge-danger"> {{$helper.getTitleValueLang(assignment.lesson.course)}}</label>
                          </div>
                        </div>

                      </div>
                        <div class="col-12 text-center">
                          <button class="btn btn-danger-light btn-sm" @click="openFile(assignment)">{{$t('show_file')}}</button>

                           <button
                          class="btn custom_btn btn-success-light"
                          v-if="$helper.isInstructor()"
                          @click="openAssignmentEvaluate(assignment)"
                        >
                          {{ $t("review") }}
                        </button>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="index < assignments.length -1">
                      <hr class="hr_bg" />
                    </div>
                  </div>
                </u-animate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </u-animate-container>
        <sweet-modal
      ref="evalute"
      class="p-0"
      overlay-theme="dark"
      modal-theme="dark"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 mt-3 mr-1">
            <div class="form-group">
            <label class="font-weight-bold">{{ $t("status") }}</label>
            <multiselect
             :select-label="$t('multiselect_choose')"
              :options="submissionStatusValues"
              v-validate="'required'"
              data-vv-name="status"
              v-model="submissionStatus.status"
              class="form-control"
              :placeholder="$t('status')"
            ></multiselect>

            <span class="text-danger text-sm" id="status">{{
              errors.first("status")
            }}</span>
          </div>
          </div>
          <div class="col-12 mr-1">
            <div class="form-group">
            <label class="font-weight-bold">{{ $t("comment") }}</label>
            <!-- type="text" -->

            <textarea
              v-validate="'required'"
              data-vv-name="statusComments"
              v-model="submissionStatus.statusComments"
              class="form-control"
              :placeholder="$t('comment')"
            />
            <span class="text-danger text-sm mt-1" id="statusComments"><b>{{
              errors.first("statusComments")
            }}</b></span>
          </div>
            <div class="col-md-12 text-center">
                <span class="flex-d text-danger text-sm" id="error_handle"
                  ></span
                >
              </div>
          </div>
          <div class="col-12 mt-3">
            <button class="btn my-2 my-sm-0 text-secondary" type="submit" @click="validateEvalutionForm">
              {{ $t("add") }}
            </button>
          </div>
        </div>
      </div>
    </sweet-modal>
     <sweet-modal ref="ratingModal" :title="$t('show_rating')">
            <u-animate-container>
                <div class="row">
                    <div class="col-md-12" v-for="(rate , index) in ratingReviewList" :key="index">
                          <!-- <u-animate
                                  name="bounceInDown"
                                  :delay="index/10+'s'"
                                  duration="1s"
                                  :iteration="1"
                                  :offset="0"
                                  animateClass="animated"
                                  :begin="false"
                          > -->
                            <div class="card mb-2 mt-0 p-3">
                                <div class="card-body">
                                    <div class="row direction text-left">
                                        <div class="col-md-6">
                                            <div class="d-flex">
                                                <div style="flex: 1">
                                                    <img :src="rate.courseSubscribe.student.img"
                                                         width="70px" alt="" v-if="rate.courseSubscribe.student.img">
                                                    <img :src="require('@/assets/img/adult-learning.png')" v-else
                                                         width="70px" alt="">
                                                </div>
                                                <div class="pr-1 pl-1" style="flex: 3">
                                                    <!-- <h4>{{$t('student_name')}}</h4> -->
                                                    <p>{{rate.courseSubscribe.student.name}}</p>
                                                    <p>{{$helper.formatDate(rate.date)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <div v-html="$helper.getRate(Math.round(rate.rate))"></div>
                                        </div>
                                        <div class="col-md-12">
                                            <p>{{rate.reviewText}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </u-animate> -->
                    </div>
                </div>
            </u-animate-container>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal, SweetModalTab } from "sweet-modal-vue";

export default {
  name: "TeacherـDashboard",
   components: {SweetModal, SweetModalTab},

  data() {
    return {
      lists:[],
      discussions:[],
      assignments:[],
      ratingReviewList:[],

        submissionStatusValues: [
        this.$t("submission_reject"),
        this.$t("submission_accept"),
        this.$t("submission_pending"),
      ],
       submissionStatus: {},
    };
  },
   mounted() {
             this.getAllLists();
             this.getAllLatestDiscussion();
             this.getAllLatestAssignments();
        },
    methods:{
    openFile(assignment){
      $('<a href='+`${assignment.attachments}`+' target="blank"></a>')[0].click();

    },
    showRating() {
      this.$refs.ratingModal.open();
    },

     getCourseRating(id){
       let vm = this;
      vm.$helper.showLoader();
      let filters={
        id:id,
      };
      let dispatch = this.$store.dispatch(
        "moduleRatingReview/findRatingReviewByCourseID",
        filters
      );
      dispatch
        .then((response) => {
        vm.ratingReviewList=response.data.data.ratingAndReview;
        console.log(vm.ratingReviewList);
        vm.showRating();
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
     prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: this.query ? this.query : "",
      };
    },
    getAllLists() {
      let vm = this;
      let helper=vm.$helper;

        //if this page is allowed
      if(!helper.isInstructor())
      {helper.showMessage('error',vm,vm.$t('server_error_403')); return};

       let data=helper.getLocalStorage('userInfo');

      helper.showLoader();
      let filters = vm.prepareFilters();
      // filters.method = "both";
      let payload = {};
      payload = filters;
      payload.instructorId = data?data.instructorId:'';

// "moduleList/listCourseByInstructor"
      let dispatch = this.$store.dispatch(
        "moduleList/getAllList",
        payload
      );
      dispatch
        .then((response) => {
          vm.lists = response.data.data.result.data;
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
    getAllLatestDiscussion() {
      let vm = this;
      let helper=vm.$helper;

        //if this page is allowed
      if(!helper.isInstructor())
      {helper.showMessage('error',vm,vm.$t('server_error_403')); return};

       let data=helper.getLocalStorage('userInfo');

       helper.showLoader();
      let filters = vm.prepareFilters();
      let payload = {};
      payload.filters = filters;
      payload.filters.userId = data?data.id:'';
      payload.filters.orderBy = 'DESC';

      let dispatch = this.$store.dispatch(
        "moduleLessonDiscussion/getAllDisscusion",
        payload
      );
      dispatch
        .then((response) => {
         vm.discussions = this.$store.getters[
            "moduleLessonDiscussion/getAllLessonDiscussion"
          ];
         vm.discussions=vm.discussions.slice(0,5);
         console.log(vm.discussions);
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },


 openAssignmentEvaluate(assignment) {
      this.submissionStatus.id=assignment.id;
      this.$refs.evalute.open();
    },
     validateEvalutionForm(){
      console.log(this.submissionStatus['status'],this.submissionStatus['statusComments'],'all_submission 316');
      //validate fields
      if(!this.submissionStatus.status)
        $('#status').text(this.$t('isRequired'));
      else
        $('#status').text('');

      if(!this.submissionStatus.statusComments)
        $('#statusComments').text(this.$t('isRequired'));
      else
        $('#status').text('');

      if(this.submissionStatus.statusComments && this.submissionStatus.status){
         this.addEvalution();
      }
    },

    addEvalution(){
                let vm = this;
                vm.$helper.showLoader();
                let payload = {};
                payload.id=this.submissionStatus.id;

                payload.data={};
                payload.data.status=this.submissionStatusValues.indexOf(this.submissionStatus.status);
                payload.data.statusComments=this.submissionStatus.statusComments;

                vm.$store.dispatch('moduleAssignmentSubmission/evaluateSubmission', payload)
                    .then(() => {
                          vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)

                        //clear data from modal
                        delete this.submissionStatus.statusComments;
                        delete this.submissionStatus.status;
                        delete this.submissionStatus.id;

                        //close modal
                         this.$refs.evalute.close();
                        this.$router.go();
                      })
                    .catch(error => {

                        let error_type=error.response.data;
                        if(error_type.message == 'ASSIGNMENT_SUBMISSION_UNEDITABLE')
                           $('#error_handle').text(this.$helper.getErrorValueLang(error_type.data));

                           //clear data from modal
                       this.submissionStatus.statusComments="";
                        this.submissionStatus.status="";


                        vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);

                    })

    },

 getAllLatestAssignments() {
      let vm = this;
      let helper=vm.$helper;

        //if this page is allowed
      if(!helper.isInstructor())
      {helper.showMessage('error',vm,vm.$t('server_error_403')); return};

       let data=helper.getLocalStorage('userInfo');

       helper.showLoader();
      let filters = vm.prepareFilters();
      let payload = {};
      payload.filters = filters;
      payload.filters.instructorId = data?data.instructorId:'';
      payload.filters.orderBy = 'DESC';

      let dispatch = this.$store.dispatch(
        "moduleAssignmentSubmission/getAllAssignmentSubmissions",
        payload
      );
      dispatch
        .then((response) => {
         vm.assignments = this.$store.getters[
            "moduleAssignmentSubmission/getAllAssignmentSubmission"
          ];
         vm.assignments=vm.assignments.slice(0,5);
         console.log(vm.assignments);
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },

deleteList(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },
    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleList/removeList", {
        id: id,
      });
      dispatch
        .then((response) => {
          vm.$helper.hideLoader();
          let index = vm.lists.findIndex((e) => {
            return e.id == id;
          });
          this.$store.commit("moduleList/REMOVE_LIST", index);
           this.$helper.showMessage('success',this);
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
  },
};
</script>

<style>
.border-bottom-1 {
  border-bottom: 1px solid #ccc;
}

.pointer {
  cursor: pointer;
}

.hr_bg {
  height: 1px;
  border: 0;
  background: linear-gradient(to right, #fff, #555, #fff);
}

.text-small {
  font-size: 14px;
  padding: 0 5px;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}
</style>
