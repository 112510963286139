// en, ar
export default {
    ar: {
        Dashboard: 'لوحة التحكم',
        home: 'الرئيسية',
        courses: 'الدورات',
        no_data: 'لا توجد بيانات',
        student_data: 'بيانات الطالب',
        contact: 'تواصل معنا',
        name: 'الاسم',
        login: 'تسجيل الدخول',
        login_welcome: 'مرحبا بك , من فضلك قم بتسجيل المستخدم وكلمة المرور الخاصة بك',
        error: 'خطأ',
        success: 'نجاح',
        server_parse_error: 'خطأ شكل استرجاع البيانات من الخادم',
        server_permission_not_admin: 'هذا الاكونت ليس لديه صلاحيات مدير الموقع',
        server_permission_not_teacher: 'هذا الاكونت ليس لديه صلاحيات مدرس  ',
        server_error: 'خطأ فى استرجاع البيانات من الخادم برجاء المحاولة مرة اخري',
        server_error_401: 'انتهت الجلسة عليك تسجيل الدخول',
        server_error_403: 'غير مسموح لك بطلب هذه الخدمة',
        server_error_404: 'الرابط غير موجود',
        success_msg: 'تمت العملية بنجاح',
        error_msg: 'حدث خطأ',
        look_out: 'انتبه',
        are_sure: 'هل انت متأكد',
        yes: 'نعم',
        no: 'لا',
        image: 'الصورة',
        title_ar: 'الاسم بالعربية',
        url_link: 'رابط (مثال: زووم)',
        title_en: 'الاسم بالانجليزية',
        show: 'عرض ',
        enroll_now: 'انضم الان',
        new_account: 'حساب جديد',
        new_account_verify: ' تاكيد الحساب ',
        new_account_verify_message: ' لقد تم ارسال ايميل علي البريد الايكتروني المسجل في الاكونت الخاص بكم من فضلك احضر كود التحقق وادخله في المربع ادناه',
        from_time: 'نشرت من',
        admin: 'مدير الموقع',
        site_header_title: 'منصة جامعتي للدورات الاونلاين',
        site_header_paragraph: 'هذا النص هو مثال لنص يمكن أن إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير من الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.',
        lecture: 'محاضرة',
        course_name_test: 'اسم محاضرة لدورة تجريبي يستبدل لاحقا',
        views: 'مشاهدة',
        status: 'الحالة',
        time: 'الوقت بالساعات',
        review: 'تقييم',
        news_letter_head: 'انضم إلى آلاف الطلاب السعداء',
        news_letter_p: 'اشترك في النشرة الإخبارية واحصل على آخر الأخبار والتحديثات!',
        get_start: 'اشترك الان',
        type_your_email: 'اكتب البريد الالكتروني الخاص بك',
        ar: 'عربي',
        en: 'انجليزي',
        type: "النوع",
        users_enrolled: 'المستخدمين',
        nariman_saad: 'جامعتي',
        price: 'السعر',
        currency: 'د.ك',
        buy_now: 'اطلب الان',
        course_feature: 'خصائص الدورة',
        user_name: 'اسم المستخدم',
        password: 'كلمة المرور',
        phone: 'رقم الهاتف',
        login_p: '  اكتب اسم المستخدم وكلمة المرور للدخول',
        all: 'الكل',
        isRequired: 'هذا الحقل مطلوب',
        logout: 'خروج',
        gender: 'النوع',
        male: 'ذكر',
        female: 'انثي',
        country: 'الدولة',
        email: 'البريد الالكتروني',
        password_confirmation: 'تأكيد كلمة المرور',
        register: 'حساب جديد',
        more: 'المزيد',
        paid_till: 'تم الشراء حتي',
        search: 'البحث',
        search_query: 'كلمة البحث',
        course_categories: 'اقسام الدورات',
        show_filter: 'عرض الفلتر',
        Advanced_option: 'البحث المطور  ',
        we_found: 'وجدنا عدد  ',
        course_for_you: 'دورات من اجلك',
        price_duration: 'مدة الشراء بالايام',
        media: 'مشاهدة وسائط متعددة',
        attachment_course_info: 'بيانات ملفات الدوره',
        file: 'ملف',
        cvـfile: 'ملف السيره الذاتيه',
        profile: 'الصفحة الشخصية',
        my_info: 'بياناتي',
        optional: 'اختياري',
        download: 'تحميل',
        subscribe_msg: 'شكرا علي اشتراكك معنا سيتم ارسال كل العروض والدورات علي ايميلكم بشكل دوري',
        checkout_msg: 'سيتم تحويلك الان الي شاشه الدفع',
        welcome: 'مرحبا ',
        nice_day: 'يومك سعيد',
        days: ' ايام',
        hours: ' ساعات',
        minutes: ' دقائق',
        update: 'تحديث',
        my_courses: 'دوراتى',
        old_password: 'كلمة المرور القديمة',
        update_password: 'تغيير كلمة المرور',
        username: 'اسم المستخدم',
        description:'الوصف',
        login_admin: 'الدخول الى لوحة التحكم',
        password_confirmation_note: 'يجب التاكد ان رقم المرور متشابه',
        dashboard: 'لوحة التحكم',
        all_sections: 'الاقسام',
        add_section: 'اضافة قسم',
        section: ' القسم',
        edit_section: 'تعديل قسم',
        all_academicyear: 'السنوات الدراسيه',
        academicyear: ' السنة الدراسية',
        add_academicyear: 'اضافة سنه دراسية',
        add_academicyear_subjects: ' اضافة سنه دراسية والمواد',
        edit_academicyear: 'تعديل سنه دراسية',
        all_subjects: 'المواد',
        subject: 'المادة',
        end_assignment_date:'تاريخ انتهاء التسليم',
        add_subjects: 'اضافة مادة',
        edit_subjects: 'تعديل مادة',
        all_universities: 'الجامعات',
        add_university: 'اضافة جامعة',
        send_notification: 'إرسال التنبية',
        edit_university: 'تعديل جامعة',
        operation: 'عمليات',
        i_visible: 'ظهور / إخفاء',
        add: 'اضافة',
        edit: 'تعديل',
        save_edits: 'حفظ التعديل',
        delete: 'حذف',
        print: 'طباعة',
        warning: 'تنبيه',
        items_of:'العناصر من ',
        items_to:'الي ',
        items_for:' من ',
        items_in_page:' عدد العناصر في الجدول',
        teacher_profile:' الصفحه الشخصيه',
        notify_subscriber: 'إخطار المشتركين',
        are_you_sure: 'هل انت متأكد ؟',
        all_lists: 'جميع الدورات',
        manage_lessons: 'ترتيب الدروس',
        add_list: 'اضافة دورة',
        edit_list: 'تعديل دورة',
        all_groups: 'جميع المجموعات',
        group_num: 'عدد المجموعات',
        add_group: 'اضافة مجموعه',
        edit_group: 'تعديل مجموعه',
        all_admins: 'جميع المديرين',
        add_admins: 'اضافة مدير',
        edit_admins: 'تعديل مدير',
        description_ar: 'الوصف بالعربية',
        description_en: 'الوصف بالانجليزية',
        video: 'فيديو',
        attachement_price: 'سعر الملفات',
        course_keyword: 'نوع الكورس',
        hours_number: 'عدد الساعات',
        price_before_discount: 'السعر قبل الخصم',
        role: 'الدور',
        all_roles: 'جميع الادوار',
        add_roles: 'اضافة دور',
        edit_roles: 'تعديل دور',
        permissions_count: 'عدد الصلاحيات',
        settings: 'الاعدادات',
        main_settings: 'الاعدادات العامة',
        total_lists: 'اجمالى الدورات',
        total_list: 'اجمالى الدورة',
        total_subscribes: 'اجمالى المستحقات',
        total_subscribes_count: 'اجمالى الاشتراكات الكلية',
        current_subscribes_count: 'اجمالى الاشتراكات الحالية',
        total_users: 'اجمالى العملاء بالموقع',
        top_list: 'اكثر 5 دورات تسجيلاََ',
        users_month: 'عدد المسجلين بالنسبة للشهر',
        top_list_seen: 'الدورات الاكثر مشاهدة',
        intro_video: 'فيديو تمهيدي',
        attachment_if_exist: 'مرفقات إن وجد',
        all_sliders: 'معرض الصور',
        add_sliders: 'اضافة صورة',
        edit_sliders: 'تعديل صورة',
        all_courses: 'جميع الدروس',
        add_courses: 'اضافة درس',
        coupons: 'الكوبونات',
        add_coupon: 'إضافة كوبون',
        edit_courses: 'تعديل درس',
        link: 'رابط',
        multiselect_choose: 'اختر',
        list: 'دورة',
        max_video: 'اقصي حجم الفيديو ١٠٠٠ ميجا',
        all_users: 'العملاء',
        su_courses: 'الدروس التي تم الاشتراك بها',
        paid_at: 'تاريخ الاشتراك',
        num_pages: 'عدد الصفحات',
        cost: 'إجمالي الاشتراك',
        expired_at: 'تاريخ انتهاء الاشتراك',
        reports: 'تقارير',
        getAllUsersReport: 'تقرير الطلاب المسجلين بالموقع',
        getAllCoursesReport: 'تقرير جميع الدورات',
        getAllListReport: 'تقرير مشاهدات الدروس',
        filter: 'تصفية',
        course: 'الدرس',
        forget_password: 'نسيت كلمه السر',
        forget_password_note: 'سيتم ارسال رساله الي بريدكم الالكتروني',
        submission_reject: 'مرفوض',
        submission_pending: 'لم يتم التقيم',
        no_rating: 'لايوجد تقيمات',
        submission_accept: 'مقبول',
        video_audio: 'صوت / فيديو',
        select_list: 'اختر دورة',
        select_country: 'اختر الدولة',
        no_result_search: 'لا يوجد دورات بهذا المسمي.. حاول مره اخري',
        all_teachers: 'المحاضرين',
        teacher: 'المحاضر',
        add_teacher: 'اضافة محاضر',
        edit_teacher: 'تعديل محاضر',
        login_teacher: 'تسجيل الدخول ',
        new_account_teacher: 'حساب جديد ',
        bio: 'السيرة الذاتية',
        code: 'كود المادة',
        confirm_code: 'كود التاكيد',
        greater_than_price: 'اكبر من السعر',
        liveStreamingEndTime:'يجب ان يكون تاريخ النهايه اكبر من تاريخ البدايه',
        secret_code: 'رقم التاكيد',
        verify:'تحقق',
        list_type: 'نوع الدورة',
        list_method: 'طريقة الشرح',
        start_date: 'تاريخ البداية',
        end_date: 'تاريخ النهاية',
        lectures_count: 'عدد المحاضرات',
        hours_count: 'عدد الساعات',
        what_you_learn: 'ماذا ستتعلم',
        list_requirement: 'متطلبات المادة',
        recorded: 'دروس مسجلة',
        live: 'بث مباشر',
        visual_lesson:'شرح مرئي',
        create_group: 'انشاء مجموعة',
        group_name: 'اسم المجموعة',
        group: ' المجموعة',
        limit_numbers: 'العدد المسموح',
        JOI_VALIDATION_INVALID_DATA:'خطا في بعض البيانات',
        times: 'الاوقات',
        course_type: 'نوع الدرس',
        assignment: 'واجب',
        all_assignments: 'الواجبات',
        subject_name: 'اسم المادة',
        subject_name_en: 'اسم المادة بالانجليزيه',
        meeting_info: ' بيانات المقابلة',
        all_sent_students: 'عدد الطلاب المرسلة',
        all_register_students: 'عدد الطلاب المسجلة',
        show_students: 'عرض الطلبة',
        show_student_assignment: 'عرض واجبات الطلبة',
        student_name: 'اسم الطالب',
        date: 'التاريخ',
        show_file: 'عرض الملف',
        show_video: 'الفيديو',
        rating: 'التقييم',
        comment: 'تعليق',
        show_rating: 'عرض التقييم',
        show_list: 'عرض الدورة',
        discussions: 'المناقشات',
        post: 'منشور',
        all_discussions: 'جميع المناقشات',
        add_discussion: 'رد المناقشات',
        person_name: 'اسم الشخص',
        teacher: 'المدرس',
        add_faculty: 'اضافة كلية',
        edit_faculty: 'تعديل كلية',
        faculties: 'جميع الكليات',
        faculty: 'الكلية  ',
        university: 'الجامعة  ',
        all_faculties: 'الكليات',
        add_level: 'اضافة مستوي',
        level_name: 'اسم المستوي',
        add_subject: 'اضافة مادة  ',
        teacher_dashboard: 'لوحة تحكم المدرس',
        universities_details: 'بيانات الجامعة',
        reply: 'الرد',
        my_discussion: 'مناقشاتى',
        my_assignments: 'واجباتى',
        assignments: 'الواجبات',
        reg_students: 'الطلاب المشتركين',
        my_lessons: 'الدروس',
        date_required_error: ' يجب اختيار يوم علي الاقل للمجموعه',
        url_link_if_exist: 'مرفق رابط إن وجد',
        choose_from_your_courses: 'إختر إحدى الكورسات الخاصة بك',
        choose_one_of_fields: 'يجب على الأقل تحديد الجامعة أو الكلية أو القسم أو السنة الدراسية أو الدرس',
        notification_title: 'عنوان الإخطار',
        notification_body: 'مضمون الإخطار',
        notify_users: 'إخطار المستخدمين',
        coupon: 'الكوبون',
        discount_coupons: 'كوبونات الخصم',
        coupon_code: 'كود الكوبون',
        discount_type: 'نوع الخصم',
        value: 'القيمة',
        max_user: 'الحد الأقصى للمستخدمين',
        percentage: 'نسبة',
        static: 'ثابت',
        course_price: 'سعر الدورة',
        board: 'اللوحة الشرفية',
        adding_date: 'تاريخ الإضافة',
        course_name: 'إسم الدورة',
        department: 'القسم',
        export_csv: 'تصدير إلى ملف اكسل',
        lectures_times: 'مواعيد المحاضرات',
        date_time: 'التاريخ والوقت',
        date_time_type: 'النوع',
        category: "التصنيف",
        lesson_category: "تصنيف الدرس",
        ads: "الإعلانات",
        due_date: "تاريخ التسليم",
        attachment: "المرفق",
        note: "ملاحظة",
        upload_attachment: "رفع المرفق",
        waiting: "إنتظار",
        processing: "قيد التنفيذ",
        done: "إنتهي",
        all_quizzes: "الكويزات",
        aca_email: "الإيميل الأكاديمي",
        aca_password: "الرقم السري الأكاديمي",
        discounts: "الخصومات",
        add_discount: "إضافة خصم",
        num_of_courses_in_cart: "عدد الدورات فى السلة",
        isFree: "مجاني؟",
        subscription_points: "تقييمات الطلاب المشتركين (النقاط)",
        points: 'نقاط',
        links: 'لينكات',
        videos: 'الفيديوهات',
        text: 'النص',
        title: "العنوان",
        none: "بدون",
        teacher_percent: "نسبة الدكتور",
        app_percent: "نسبة التطبيق",
        transfer_cost: "مصاريف التحويل",
        compare_calculations: "مقارنة الحسابات",
        show_postponed: "إظهار المؤجلين",
        hide_postponed: "إخفاء المؤجلين",
        compare: "مقارنة",
        total_holding: 'إجمالى الإشتراكات الموجلة',
        total_expired: 'إجمالى الإشتراكات المنتهية',
        total: 'الإجمالى',
        subscriptions_count: 'عدد الإشتراكات',
        delete_attachment: "حذف المرفق",
        marketer: "المسوق",
        semester: "التيرم",
    },
    en: {
        semester: "Semester",
        marketer: "Marketer",
        subscriptions_count: 'Subscriptions count',
        delete_attachment: "Delete Attachment",
        compare: "Compare",
        total_holding: 'Total Postponed',
        total_expired: 'Total Expired',
        total: 'Total',
        compare_calculations: "Compare Calculations",
        show_postponed: "Show Postponed",
        hide_postponed: "Hide Postponed",
        teacher_percent: "Teacher Percentage",
        app_percent: "App Percentage",
        transfer_cost: "Transfer Cost",
        board: 'Honorary Board',
        none: "None",
        title: "Title",
        text: 'text',
        videos: 'Videos',
        links: 'Links',
        points: 'Points',
        subscription_points: "Ratings for participating students (Points)",
        isFree: "Is free?",
        num_of_courses_in_cart: "# of courses in cart",
        add_discount: "Add Discount",
        discounts: "Discounts",
        aca_email: 'Academic Email',
        aca_password: 'Academic Password',
        all_quizzes: 'All Quizzes',
        waiting: 'Waiting',
        processing: 'Processing',
        done: 'Done',
        upload_attachment: 'Upload Attachment',
        note: 'Note',
        attachment: "Attachment",
        due_date: 'Due Date',
        ads: 'Ads',
        category: "Category",
        lesson_category: "Lesson Category",
        date_time: 'Date and Time',
        date_time_type: 'Type',
        lectures_times: 'Course Times',
        export_csv: 'Export to CSV',
        department: 'Department',
        course_name: 'Course Name',
        adding_date: 'Created Date',
        course_price: 'Course Price',
        discount_coupons: 'Discount Coupons',
        percentage: 'Percentage',
        static: 'Static',
        coupon: 'Coupon',
        coupon_code: 'Coupon Code',
        discount_type: 'Discount Type',
        value: 'Value',
        max_user: 'Max Users',
        choose_one_of_fields: 'You must at least specify the university, faculty, department, academic year, or course',
        notify_users: 'Notify Users',
        notification_title: 'Notification Title',
        notification_body: 'Notification Body',
        choose_from_your_courses: 'Choose one of your courses',
        url_link_if_exist: 'Attach URL if exist',
        Dashboard: 'Dashboard',
        home: 'Home',
        courses: 'Courses',
        no_data: 'No Data',
        teacher_profile: 'teacher profile',
        notify_subscriber: 'Notify Subscribers',
        contact: 'Contact Us',
        name: 'Name',
        login: 'Login',
        login_welcome: 'Welcome in our platform, please login to your profile, it is just one minute ',
        error: 'error',
        success: 'success',
        server_parse_error: 'Error data from server',
        server_permission_not_admin: 'This account is not adminstartor',
        server_permission_not_teacher: 'This account has no instructor Permission',
        server_error: 'Error data from server',
        server_error_401: 'Your session is expired, we are redirecting to relogin',
        server_error_403: 'is page is not permitted',
        server_error_404: 'this page is not found',
        success_msg: 'your operation is success',
        error_msg: 'error is happen',
        look_out: 'sorry',
        are_sure: 'are you sure?',
        yes: 'yes',
        no: 'no',
        image: 'image',
        title_ar: 'Arabic name',
        url_link: 'URL (ex. Zoom)',
        title_en: ' English name',
        show: 'Show ',
        enroll_now: 'Enroll Now ',
        new_account: 'Account New',
        student_data: 'student data',
        new_account_verify: ' Verify Acccount  ',
         new_account_verify_message: ' Verification email has sent to your email, please find the sended code and enter it here',
        from_time: 'posted from',
        admin: 'admin',
        site_header_title: 'Jamety platform for education',
         lecture: 'lecture',
        course_name_test: 'اسم محاضرة لدورة تجريبي يستبدل لاحقا',
        views: 'views',
        end_assignment_date:'Assignment deadline is required',
        status: 'status',
        time: 'time in hours',
        review: 'review',
        news_letter_head: 'join us Quickly',
         ar: 'Arabic',
        en: 'English',
        multiselect_choose: 'Choose',
        no_rating:'no rating found',
        users_enrolled: 'users',
        nariman_saad: 'jamety',
        price: 'price',
        currency: 'K.W',
        buy_now: 'buy now',
        course_feature: 'Course Feature',
        user_name: 'username',
        password: 'password',
        phone: 'phone',
        login_p: '  write your username and password to login',
        all: 'all',
        isRequired: 'this field is Required',
        logout: 'logout',
        gender: 'gender',
        male: 'male',
        female: 'female',
        country: 'country',
        email: 'Email',
        password_confirmation: 'Password Confirmation',
        register: 'register',
        more: 'more',
        paid_till: 'this is valid to',
        search: 'search',
        search_query: 'search query',
        course_categories: 'course categories',
        show_filter: 'show filter',
        Advanced_option: 'Advanced search  ',
        we_found: 'we found ',
        course_for_you: 'course for you',
        price_duration: 'price duration',
        media: 'show media',
        attachment_course_info: 'attachment course info',
        file: 'file',
        cvـfile: 'cv file',
        profile: 'home profile',
        my_info: 'my profile',
        optional: 'optional',
        download: 'download',
        subscribe_msg: ' thanks for your subscription, all offers and courses will send to your mail',
         welcome: 'welcome ',
        nice_day: 'nice day',
        days: ' days',
        hours: ' hours',
        minutes: ' minutes',
        update: 'update',
        my_courses: 'my courses',
        old_password: 'old password',
        update_password: 'update password',
        username: 'username',
        description:'description',
        login_admin: 'login admin control panel',
        password_confirmation_note: 'this filed and password must be similar',
        dashboard: 'dashboard',
        all_sections: 'all sections',
        add_section: 'add section',
        section: ' section',
        edit_section: 'edit category ',
        all_academicyear: ' all academicyear',
        academicyear: '  academicyear',
        add_academicyear: 'add academicyear  ',
        add_academicyear_subjects: ' add academicyear subjects   ',
        edit_academicyear: 'edit academicyear  ',
        all_subjects: 'all subjects',
        subject: 'subject',
        add_subjects: '  add subjects',
        edit_subjects: 'edit subjects ',
        all_universities: 'all universities',
        add_university: ' add university',
        send_notification: 'Send Notification',
        edit_university: ' edit university',
        operation: 'operations',
        i_visible: 'Visible / Invisible',
        add: 'add',
        edit: 'edit',
        save_edits: 'Save Edits',
        delete: 'delete',
        print: 'print',
        warning: 'warning',
        are_you_sure: 'are you sure?',
        all_lists: ' all lists',
        manage_lessons: 'Reorder Lessons',
        add_list: ' add list',
        edit_list: '  edit list',
        all_groups: ' all groups',
        add_group: ' add group',
        edit_group: ' edit group',
        all_admins: ' all admins',
        liveStreamingEndTime:'Meeting end date must be greater than start date',
        add_admins: ' add admins',
        edit_admins: ' edit admins',
        description_ar: 'arabic  description',
        description_en: ' English description',
        video: 'video',
        attachement_price: 'attachement price',
        course_keyword: ' course keyword',
        hours_number: ' hours number',
        price_before_discount: 'price before discount',
        role: 'role',
        all_roles: ' all roles',
        add_roles: ' add roles',
        edit_roles: ' edit roles',
        permissions_count: 'permissions count',
        settings: 'settings',
        main_settings: ' main settings',
        total_lists: ' total courses',
        total_list: ' total courses',
        total_subscribes: ' total subscribes',
        total_subscribes_count: 'total subscribes count',
        current_subscribes_count: 'current subscribes count',
        total_users: 'total users',
        top_list: 'top courses',
        users_month: 'users subscribers in month',
        top_list_seen: 'top course seen',
        intro_video: 'intro video',
        attachment_if_exist: 'Attachments If Exists',
        all_sliders: 'all sliders',
        add_sliders: 'add sliders',
        edit_sliders: ' edit sliders',
        all_courses: ' all courses',
        add_courses: ' add courses',
        coupons: 'Coupons',
        add_coupon: 'Add Coupon',
        edit_courses: ' edit courses',
        link: 'link',
        list: 'course',
        forget_password: 'forget password',
        forget_password_note: 'confirmation mail will be sent',
        max_video: 'max vedio is 1000 M',
        all_users: 'all users',
        su_courses: 'subscribed courses',
        paid_at: ' paid at',
        num_pages: ' num pages',
        cost: 'Total Subscription',
        expired_at: 'expired at',
        reports: 'reports',
        greater_than_price: 'greater than price field',
        getAllUsersReport: 'All Users Report',
        getAllCoursesReport: 'All Courses Report',
        getAllListReport: 'All List Report',
        filter: 'filter',
        course: 'course',
        submission_reject: 'reject',
        submission_pending: 'pending',
        submission_accept: 'accepted',
        video_audio: ' video / audio ',
        select_list: 'select course',
        items_of:'items from ',
        items_to:'to ',
        items_for:' of ',
        items_in_page:' items in page ',
        select_country: 'select country',
        no_result_search: 'no courses found',
        all_teachers: 'all teachers',
        teacher: 'teacher',
        add_teacher: ' add teacher',
        edit_teacher: ' edit teacher',
        login_teacher: 'login teacher',
        new_account_teacher: 'new account teacher',
        bio: 'Bio',
        code: 'course code',
        secret_code: 'secret code',
        verify:'verify',
        list_type: 'course type',
        list_method: 'course method',
        confirm_code:'code',
        start_date: 'start date',
        end_date: 'end date',
        lectures_count: 'lectures count',
        hours_count: 'hours count',
        what_you_learn: 'what you will learn',
        list_requirement: 'course requirement',
        recorded: 'recorded lessons',
        live: ' live',
        visual_lesson:' visual lesson',
        create_group: ' create group',
        group_name: 'group name ',
        group: ' group',
        limit_numbers: 'limit numbers',
        JOI_VALIDATION_INVALID_DATA:' VALIDATION INVALID DATA',
        times: 'times',
        course_type: 'course type',
        assignment: 'assignment',
        all_assignments: 'all assignments',
        subject_name: 'subject_name',
        subject_name_en: 'english subject name',
        meeting_info: '  meeting information',
        all_sent_students: '  all sent students',
        all_register_students: ' all register students ',
        show_students: '  show students',
        show_student_assignment: '  show student assignment',
        student_name: ' student name',
        date: 'date',
        show_file: ' show file',
        show_video: 'Video',
        rating: 'rating',
        comment: 'comment',
        show_rating: ' show rating',
        show_list: ' show list',
        discussions: 'discussions',
        post: 'post',
        all_discussions: ' all discussions',
        add_discussion: ' add discussion',
        person_name: 'person name',
        teacher: 'teacher',
        add_faculty: ' add faculty',
        edit_faculty: ' edit faculty',
        faculties: 'all faculties',
        faculty: 'faculty  ',
        university: 'university  ',
        all_faculties: 'all faculties',
        add_level: ' add level',
        level_name: ' level name',
        add_subject: ' add subject  ',
        teacher_dashboard: 'teacher dashboard',
        universities_details: 'universities details',
        reply: 'reply',
        my_discussion: 'my discussion',
        my_assignments: 'my assignments',
        assignments: 'assignments',
        reg_students: 'register students',
        my_lessons: 'my lessons',
        date_required_error: 'date is required',







    }
}
