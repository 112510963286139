/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
    SET_SLIDERS(state, sliders) {
        state.sliders = sliders
    },
}
