<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t("dashboard") }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >
              {{ $t("manage_lessons") }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">
                {{ $t("manage_lessons") }}
              </h2>
              <p class="font-weight-bold text-center">
                التحكم فى ترتيب الدروس وإخفاءها وظهورها
              </p>
            </div>
          </div>
          <!-- <div class="dashboard_container_body"> -->
          <div class="dashboard_container_body mt-5">
            <draggable v-model="lessons" tag="div" item-key="id" class="row mx-0 my-2">
              <div class="col-lg-4 col-md-6 col-12" v-for="(lesson, index) in lessons" :key="index">
                <figure class="text-card cursor-move d-flex justify-content-between flex-wrap" :class="lesson.available ? '' : 'disabled'">
                  <div class="d-flex align-items-center">
                    <h5 class="m-0 text-danger">{{ index + 1 }}</h5>
                    <div class="mx-2">
                      <h6 class="m-0">{{ lesson.name_ar }}</h6>
                      <span class="text-white px-1 py-0 primary-bg-clr" :class="lesson.available ? '' : 'gray-bg-clr'"
                            style="border-radius: 5px">{{ lesson.category || 'بدون' }}</span>
                    </div>
                  </div>
                  <div class="align-self-center">
                    <input :id="'course_'+index" type="checkbox" name="course_index[]" v-model="lesson.available"/>
                  </div>
                </figure>
              </div>
            </draggable>
            <p class="text-lg-center font-weight-bold" v-if="lessons.length==0">{{ $t('no_data') }}</p>
            <div class="row justify-content-center">
              <div class='col-6 m-1 text-center'>
                <button
                    class="btn btn-success-light"
                    @click="saveChanges()"
                >{{ $t('save_edits') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import draggable from 'vuedraggable'
import { cloneDeep } from 'lodash';

export default {
  name: "All",
  components: {
    draggable,
  },
  data() {
    return {
      lesson_types: ["assignment", "visual_lesson"],
      query: null,
      lessons: [],
    };
  },
  mounted() {
    this.getAllLessons();
  },
  methods: {
    async saveChanges() {
      let vm = this;
      vm.$helper.showLoader();
      await this.$store.dispatch("moduleCourse/changeLessonsPriorityAndAvailability", {
        data: vm.lessons.map((lesson, index) => {
          return {
            "lessonId": lesson.id,
            "available": lesson.available ? 1 : 0,
            "priority": index
          };
        })
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
      vm.getAllLessons();
    },

    async getAllLessons() {
      let vm = this;
      let filters = {
        courseId: vm.$route.query.list_id,
        attributes: 'id name_ar name_en available priority type category courseId',
      };

      vm.$helper.showLoader();
      await this.$store.dispatch("moduleCourse/getAllLessonsForReorder", filters).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
      vm.lessons = cloneDeep(this.$store.getters["moduleCourse/getAllCourses"]);
      vm.$helper.hideLoader();
    },
  },
};
</script>

<style scoped>
.tableScroll {
  width: 100%;
  max-width: auto;
  overflow-x: scroll;
}
</style>
