/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
  SET_SUBJECTS(state, subjects) {
        state.subjects = subjects
    },

  remove_subject(state,index){
        state.subjects.splice(index,1)
  }
}
