<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('all_courses') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('all_courses') }}</h2>
            </div>
          </div>
          <div class="row p-2 mb-2 d-print-none">
            <div class="col-md-4">
              <label class="font-weight-bold">{{ $t('list') }}</label>
              <multiselect
                  v-model="selectedList"
                  :options="lists"
                  track-by="id"
                  :select-label="$t('multiselect_choose')"
                  :placeholder="$t('select_list')"
                  :custom-label="nameWithLang"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label class="font-weight-bold">{{ $t('search') }}</label>
              <input
                  class="form-control w-100"
                  type="search"
                  :placeholder="$t('search')"
                  v-model="query"
                  aria-label="Search"
              />
            </div>
            <div class="col-md-12 text-center mt-2">
              <button
                  class="btn btn-success-light"
                  @keyup.enter="handleCoursesFilter()"
                  @click="handleCoursesFilter()"
              >{{ $t('filter') }}
              </button>

              <button
                  class="btn btn-danger-light mx-2"
                  @click="$router.push({name:$helper.getRouteFullName('add_courses'),query:{list_id:get_list_id(),method:getListMethod(),type:getListType()},params:{list_id:$route.query.list_id?$route.query.list_id:''}}).catch(()=>{});"
              >{{ $t('add_courses') }}
              </button>

              <button
                  class="btn btn-black"
                  @click="$router.push({name:$helper.getRouteFullName('manage_lessons'),query:{list_id:get_list_id(),method:getListMethod(),type:getListType()},params:{list_id:$route.query.list_id?$route.query.list_id:''}}).catch(()=>{});"
              >{{ $t('manage_lessons') }}
              </button>
            </div>
          </div>

          <div class="row justify-content-end mb-2 ml-2">

            <div
                dir="ltr"
                class="col-3 text-center mt-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer items-center font-medium"
            >

              <span class=""
              >{{ $t('items_of') }}{{ page * itemsPerPage - (itemsPerPage - 1) }} {{ $t('items_to') }}
                {{
                  page * itemsPerPage -
                  (itemsPerPage - 1) +
                  (courses.length - 1)
                }}
                {{ $t('items_for') }} {{ numRows }}</span
              >
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <div class="col-3 text-center">
              <label class="font-weight-bold">{{ $t("items_in_page") }}</label>
              <multiselect
                  :options="pager_items_choices"
                  v-model="itemsPerPage"
                  @input="getAllCourses()"
                  :placeholder="$t('search')"></multiselect>
            </div>
            <!-- </div> -->
          </div>
          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('list') }}</th>
                  <th class="text-left">{{ $t('title_ar') }}</th>
                  <th class="text-left">{{ $t('category') }} ({{$t('optional')}})</th>
                  <th class="text-left">{{ $t('isFree') }}</th>
                  <th class="text-left" width="100px">{{ $t('operation') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(course , index) in courses" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <slot v-if="course.course">{{ $helper.getTitleValueLang(course.course) }}</slot>
                  </td>
                  <td>{{ course.name_ar }}</td>
<!--                  <td>{{ course.category || 'بدون' }}</td>-->
                  <td>
                    <multiselect
                        v-model="course.category"
                        :select-label="$t('multiselect_choose')"
                        :options="categories"
                        :placeholder="$t('category')"
                        class="min-h"
                        :allow-empty="false"
                        @select="onLessonChanged(course)"
                    ></multiselect>
                  </td>
                  <td>
                    <input type="checkbox" v-model="course.isFree" @change="onLessonChanged(course)">
                  </td>
                  <td>
                    <div class="btn-group direction-inverse">
                      <button
                          class="btn custom_btn btn-danger-light"
                          v-if="course.vedio && course.vedio.length"
                          @click="displayVedio(course.vedio)"
                      >{{ $t('show_video') }}
                      </button>
                      <button
                          class="btn custom_btn btn-primary-light"

                          @click="deleteCourse(course.id)"
                      >{{ $t('delete') }}
                      </button>
                      <!-- v-if="$helper.hasAccessPermission('update-course')" -->
                      <button
                          class="btn custom_btn btn-success-light"
                          @click="$router.push({name:$helper.getRouteFullName('edit_courses'),params:{list_id:course.id},query:{list_id:get_list_id(),method:getListMethod(),type:getListType()}}).catch(()=>{});"
                      >{{ $t('edit') }}
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="courses.length==0">{{ $t('no_data') }}</p>

            </div>

            <div class="text-center" v-if="last_page > 1">

              <paginate
                  :page-count="last_page"
                  :page-range="1"
                  :click-handler="handlePaginate"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                  :page-class="'page-item'">
              </paginate>


            </div>

          </div>
        </div>
      </div>
    </div>
    <sweet-modal ref="modal" class="p-0" overlay-theme="dark" modal-theme="dark">
      <div class="container">
        <div class="row">
          <div class="col-mds-12 w-100" v-if="videoID">
            <vue-vimeo-player ref="player" :video-id="videoID" @ready="onReady" :player-height="height"
                              style="width: 100%">
            </vue-vimeo-player>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>

import { SweetModal } from "sweet-modal-vue";
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  name: "All",
  components: { SweetModal, vueVimeoPlayer },
  data() {
    return {
      lists: [],
      selectedList: null,
      courses: [],
      query: null,
      pager_items_choices: [1, 5, 10, 20, 50, 100],
      itemsPerPage: 10,
      page: 1,
      last_page: 1,
      numRows: 1,
      lesson_types: ["assignment", "visual_lesson"],
      videoID: null,
      height: 300,
      categories: [],
    }
  },
  mounted() {
    this.getAllLists();
    this.getAllCourses();
    this.getAllCategories();
  },
  methods: {
    async getAllCategories() {
      let vm = this;
      vm.$helper.showLoader();
      const res = await vm.$store.dispatch("moduleCourse/getAllCategories").catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      if (res.data.status && res.data.data) {
        const list = res.data.data.map(item => item.category);

        list.unshift('بدون');
        vm.categories = list;
      }

      vm.$helper.hideLoader();
    },
    onLessonChanged(lesson) {
      this.updateLesson(lesson);
    },
    updateLesson(lesson) {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append('category', lesson.category == 'بدون' ? "" : lesson.category);
      formData.append('name_ar', lesson.name_ar);
      formData.append('name_en', lesson.name_en);
      formData.append('type', lesson.type);
      formData.append('isLiveStreaming', +lesson.isLiveStreaming);
      formData.append('isAssostatedWithGroup', +lesson.isAssostatedWithGroup);
      formData.append('courseId', lesson.courseId);
      formData.append('isFree', lesson.isFree);

      payload.data = formData;
      payload.config = config;
      payload.id = lesson.id;

      vm.$store.dispatch("moduleCourse/updateCourse", payload)
          .then(() => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage("success", vm);
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    onReady() {
      this.playerReady = true
    },
    displayVedio(vedio_path) {
      let code = vedio_path.split('/').slice(-1);
      this.videoID = code;

      this.$refs.modal.open();
    },
    getListMethod() {
      return this.$route.query.method;
    },
    get_list_id() {
      return this.$route.query.list_id;
    },
    getListType() {
      return this.$route.query.type;
    },
    nameWithLang(o) {
      return this.$helper.getTitleValueLang(o);
    },
    print() {
      window.print();
    },
    getAllLists() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      // filters.method = "both";
      filters.startFrom = "1992-11-05 00:00:00.000";
      filters.startTo = "2992-11-05 00:00:00.000";
      let dispatch = this.$store.dispatch("moduleList/getAllList", filters);
      dispatch
          .then((response) => {
            let lists = this.$store.getters["moduleList/getAllList"];
            vm.lists = lists;
            let list_id = this.$route.query.list_id;
            if (list_id) {
              _.forEach(lists, (list) => {
                if (list.id == list_id) {
                  vm.selectedList = list;
                }
              });
            }
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    handleCoursesFilter() {
      let vm = this;
      if (vm.selectedList) {
        let queries = JSON.parse(JSON.stringify(this.$route.query));
        queries.list_id = vm.selectedList.id;
        queries.method = vm.selectedList.method;
        queries.type = vm.selectedList.type;
        this.$router.replace({ query: queries }).catch((error) => {
          console.log(error)
        });
        //retrieve the courses
        vm.getAllCourses();
      }
      //  this.$router.replace({ query:{list_id:vm.selectedList.id, method:vm.selectedList.method, type:vm.selectedList.type}}).catch((error)=>{console.log(error)});

    },
    handlePaginate(pageNum) {
      this.page = pageNum;
      this.getAllCourses();
    },

    prepareFilters() {
      return {
        doPagination: 1,
        numPerPage: this.itemsPerPage,
        page: this.page,
        searchKey: this.query ? this.query : "",
      };
    },
    getAllCourses() {
      let vm = this;
      let course_id = this.selectedList
          ? this.selectedList.id
          : this.$route.query.list_id;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.type = "both"; //1 or 0 or both'
      filters.course_id = course_id;
      filters.available = 2; // all lessons

      let dispatch = this.$store.dispatch(
          "moduleCourse/getAllCourses",
          filters
      );
      dispatch
          .then((response) => {
            vm.courses = this.$store.getters["moduleCourse/getAllCourses"].map(value => {
              value.category = value.category == '' ? 'بدون' : value.category;
              return value;
            });
            vm.numRows = response.data.data.result.numRows;
            vm.last_page = response.data.data.result.numPages;
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    deleteCourse(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },
    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleCourse/removeCourse", {
        id: id,
      });
      dispatch
          .then((response) => {
            vm.$helper.hideLoader();
            let index = vm.courses.findIndex(e => {
              return e.id == id
            })
            this.$store.commit('moduleCourse/REMOVE_COURSE', index);
            this.$helper.showMessage('success', this);
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
  },
};
</script>

<style>
.multiselect__select {
  height: 55px;
}

.multiselect__tags {
  min-height: 55px;
  padding: 15px 40px 0 8px;
}
</style>
