/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
    SET_CATEGORIES(state, categories) {
        state.categories = categories
    },
    REMOVE_CATEGORIES(state, index) {
      state.categories.splice(index,1)
    },
}
