<template>
    <div class="d-none d-md-flex">
        <div class="dashboard-navbar">
            <div class="d-navigation">
                <ul id="side-menu" class="text-left" dir="ltr">
                    <li :class="$route.name == 'teacher_dashboard' ? 'active': ''">
                        <a href="" @click.prevent="$router.push({name:'teacher_dashboard'}).catch(()=>{});">
                            <i class="ti-dashboard"></i>{{$t('teacher_dashboard')}}</a>
                    </li>
                    <li v-if="$helper.hasAccessPermission('all-groups')"
                        :class="$route.name == 'all_groups_teacher' ? 'active': ''">
                        <a href="" @click.prevent="$router.push({name:'all_groups_teacher'}).catch(()=>{});">
                            <i class="ti-ticket"></i>{{$t('all_groups')}}</a>
                    </li>
<!--                    <li v-if="$helper.hasAccessPermission('all-assignments')"-->
<!--                        :class="$route.name == 'all_assignments_teacher' ? 'active': ''">-->
<!--                        <a href="" @click.prevent="$router.push({name:'all_assignments_teacher'}).catch(()=>{});">-->
<!--                            <i class="ti-ticket"></i>{{$t('all_assignments')}}</a>-->
<!--                    </li>-->


                    <li v-if="$helper.hasAccessPermission('all-discussions')"
                        :class="$route.name == 'all_discussions_teacher' ? 'active': ''">
                        <a href="" @click.prevent="$router.push({name:'all_discussions_teacher'}).catch(()=>{});">
                            <i class="ti-comment"></i>{{$t('all_discussions')}}</a>
                    </li>
                     <li v-if="$helper.hasAccessPermission('all-assignments')"
                        :class="$route.name == 'getAllUsersReport_teacher' ? 'active': ''">
                        <a href="" @click.prevent="$router.push({name:'getAllUsersReport_teacher'}).catch(()=>{});">
                            <i class="ti-ticket"></i>{{$t('getAllUsersReport')}}</a>
                    </li>
                    <li v-if="$helper.hasAccessPermission('all-assignments')"
                        :class="$route.name == 'profile_teacher' ? 'active': ''">
                        <a href="" @click.prevent="$router.push({name:'profile_teacher',params:{id:getInstructorId()}}).catch(()=>{});">
                            <i class="ti-ticket"></i>{{$t('teacher_profile')}}</a>
                    </li>
                    <li :class="$route.name == 'notify_subscribers' ? 'active': ''">
                        <a href="" @click.prevent="$router.push({name:'notify_subscribers'});">
                            <i class="ti-bell"></i>{{$t('notify_subscriber')}}</a>
                    </li>
                    <li><a href="#" @click.prevent="logout()"><i class="ti-power-off"></i>{{$t('logout')}}</a></li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Sidebar",
        data() {
            return {}
        },
        mounted() {

        },
        methods: {
             getInstructorId(){
                let data=this.$helper.findInstructorData();
                if(data)
                    return data.instructorId;
                return '';

            },
             getToken(){
                return localStorage.getItem('token');
            },
            toggleMenu(id) {
                $(`#${id}`).toggleClass('collapse show collapsed')
                $(`#${id}`).toggleClass('')
            },
            logout() {
                this.$helper.removeLocalStorage('userType');
                this.$helper.removeLocalStorage('token');
                this.$helper.removeLocalStorage('userInfo');
                //  location.reload()
                this.$router.go(0);
            }
        }
    }
</script>

<style scoped>

</style>
