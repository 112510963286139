<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('add_courses') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('add_courses') }}</h2>
              <h2 class="font-weight-bold fun_font text-center" v-if="list">
                {{ $t('list') }}
                <b class="text-danger">" {{ $helper.getTitleValueLang(list) }} "</b>
              </h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('title_ar') }}</label>
                  <input
                      type="text"
                      v-validate="'required|min:3|max:30'"
                      data-vv-name="name_ar"
                      v-model="dataModel.name_ar"
                      class="form-control"
                      :placeholder="$t('title_ar')"
                  />
                  <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('title_en') }}</label>
                  <input
                      type="text"
                      v-validate="'required|min:3|max:30'"
                      data-vv-name="name_en"
                      v-model="dataModel.name_en"
                      class="form-control"
                      :placeholder="$t('title_en')"
                  />
                  <span class="text-danger text-sm">{{ errors.first('name_en') }}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('description') }}</label>
                  <vue-editor
                      dir="ltr"
                      type="text"
                      v-validate="'min:5'"
                      data-vv-name="desc"
                      v-model="dataModel.desc"
                  ></vue-editor>
                  <span class="text-danger text-sm">{{ errors.first('desc') }}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('video_audio')}}</label>
                                    <small class="font-weight-bold text-danger"> ({{$t('max_video')}})</small>
                                    <input type="file" @change="previewVideo()" ref="myVideo"
                                           data-vv-name="file"
                                           accept="video/* , audio/*"
                                           class="form-control"
                                           :placeholder="$t('video_audio')">
                                    <span class="text-danger text-sm">{{ errors.first('file') }}</span>
                                </div>
              </div>-->
              <div class="col-md-6" v-show="getListMethod()==1">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('course_type') }}</label>
                  <select
                      v-validate="'required'"
                      ref="type"
                      data-vv-name="type"
                      v-model="dataModel.type"
                      class="form-control"
                  >
                    <option
                        :key="index"
                        :disabled="checkLessonTypesIsEnabled()"
                        v-for="(lesson_type , index) in lesson_types"
                        :value="index"
                    >{{ $t(lesson_type) }}
                    </option>
                  </select>
                  <span class="text-danger text-sm">{{ errors.first('type') }}</span>
                </div>
              </div>

              <div class="col-md-6" v-if="isAssignment()">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('end_assignment_date') }}</label>
                  <flat-pickr
                      data-vv-name="assignmentDeadLineDate"
                      v-model="dataModel.assignmentDeadLineDate"
                      class="form-control"
                      v-validate.immediate="'required_if:type,0'"
                      :placeholder="$t('end_assignment_date')"/>
                  <span class="text-danger text-sm">{{ errors.first('assignmentDeadLineDate') }}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('list') }}</label>
                  <multiselect
                      v-model="selectedList"
                      v-validate="'required'"
                      :select-label="$t('multiselect_choose')"
                      :options="lists"
                      @input="getAllGroubyByCourseId(selectedList)"
                      track-by="id"
                      :placeholder="$t('select_list')"
                      :custom-label="nameWithLang"
                  ></multiselect>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('lesson_category') }} ({{ $t('optional') }})</label>
                  <multiselect
                      v-model="dataModel.category"
                      :select-label="$t('multiselect_choose')"
                      :options="categories"
                      track-by="id"
                      :placeholder="$t('category')"
                  ></multiselect>
                </div>
              </div>

              <div class="col-md-6" v-show="getListMethod()==1">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('group_name') }}</label>
                  <multiselect
                      v-model="selectedGroup"
                      :options="groups"
                      :select-label="$t('multiselect_choose')"
                      track-by="id"
                      :placeholder="$t('group_name')"
                      label="name"
                  ></multiselect>
                  <span class="text-danger text-sm">{{ errors.first('groupId') }}</span>
                </div>
              </div>

              <div class="col-md-6" v-if="!isAssignment()">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('video') }}</label>
                  <input
                      type="file"
                      @change="previewVideo()"
                      accept="video/mp4, video/quicktime, video/x-ms-wmv, video/avi, video/x-flv"
                      ref="myVideo"
                      data-vv-name="vedio"
                      class="form-control"
                      :placeholder="$t('video')"
                  />
                  <span class="text-danger text-sm">{{ errors.first('vedio') }}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('file') }}</label>
                  <input
                      type="file"
                      @change="previewFile()"
                      ref="myfile"
                      data-vv-name="file"
                      class="form-control"
                      :placeholder="$t('file')"
                      multiple
                  />
                  <span class="text-danger text-sm">{{ errors.first('attachments') }}</span>
                </div>
                <div v-if="dataModel.attachments && dataModel.attachments.length">
                  <div class="d-flex justify-content-between align-items-center attachment-preview mb-1" v-for="(file) in dataModel.attachments" :key="file.name">
                    <span class="cursor-pointer text-overflow-ellipsis" @click="openFile(file)">{{ file.name }}</span>
                    <i class="ti ti-trash cursor-pointer" @click="removeAttachment(file)"></i>
                  </div>
                </div>
              </div>

              <div class="col-md-12 direction" v-if="!isAssignment() && getListMethod()==1">
                <hr/>
                <input type="checkbox" id="isLive" v-model="isLive" value="1"/>
                <label for="isLive" class="p-1 font-weight-bold">{{ $t('live') }}</label>
              </div>

              <div class="col-md-12" v-if="isLive && !isAssignment() && getListMethod()==1">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="font-weight-bold">{{ $t('meeting_info') }}</label>
                      <input
                          type="text"
                          v-validate="'required|min:3'"
                          data-vv-name="liveStreamingInfo"
                          v-model="dataModel.liveStreamingInfo"
                          class="form-control"
                          :placeholder="$t('meeting_info')"
                      />
                      <span class="text-danger text-sm">{{ errors.first('liveStreamingInfo') }}</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="font-weight-bold">{{ $t('start_date') }}</label>
                      <flat-pickr
                          v-validate="'required'"
                          data-vv-name="liveStreamingTime"
                          :config="config"
                          v-model="dataModel.liveStreamingTime"
                          class="form-control"
                          :placeholder="$t('start_date')"
                      />
                      <span class="text-danger text-sm">{{ errors.first('liveStreamingTime') }}</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="font-weight-bold">{{ $t('end_date') }}</label>
                      <flat-pickr
                          v-validate="'required'"
                          data-vv-name="liveStreamingEndTime"
                          :config="config"
                          v-model="dataModel.liveStreamingEndTime"
                          class="form-control"
                          :placeholder="$t('end_date')"
                      />
                      <span class="text-danger text-sm" id="liveStreamingEndTime">{{ errors.first('liveStreamingEndTime') }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 green-skin">
                <input id="isFree" class="checkbox-custom" name="isFree" type="checkbox" v-model="dataModel.isFree">
                <label class="font-weight-bold checkbox-custom-label" for="isFree">مجاني ؟</label> <span style="font-size: 12px">مشاهده بدون إشتراك</span>
              </div>

              <div class="col-md-12 text-center">
                <span class="flex-d text-danger text-sm" id="error_handle"></span>
              </div>

              <div class="col-md-12 text-center">
                <button class="btn btn-danger-light" @click="submitForm()">{{ $t('add') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Add",
  data() {
    return {
      dataModel: {
        name_ar: "",
        name_en: "",
        desc: "",
        type: 1,
        isLiveStreaming: 1,
        vedio: null,
        courseId: '',
        isAssostatedWithGroup: 1,
        groupId: '',
        attachments: null,
        liveStreamingInfo: "",
        liveStreamingTime: "",
        liveStreamingEndTime: "",
        assignmentDeadLineDate: '',
        category: '',
        isFree: false,
      },
      list: "",
      selector_type: "link",
      lesson_types: ["assignment", "visual_lesson"],
      isLive: false,
      selectedGroup: null,
      groups: [],
      categories: [],
      selectedList: null,
      lists: [],
      config: {
        enableTime: true,
        dateFormat: "Z",
        altInput: true,
        altFormat: "Y-m-d h:i K",
      },
    };
  },
  watch: {
    selector_type(newVal, oldVal) {
      if (newVal == "link") {
        delete this.dataModel.file;
        this.$refs.myVideo.value = null;
        return;
      }
      delete this.dataModel.link;
    },
  },
  mounted() {
    this.dataModel.courseId = this.$route.params.list_id;
    this.getAllLists();
    this.getAllCategories();
  },
  methods: {
    async getAllCategories() {
      let vm = this;
      vm.$helper.showLoader();
      const res = await vm.$store.dispatch("moduleCourse/getAllCategories").catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      if (res.data.status && res.data.data) {
        vm.categories = res.data.data.map(item => item.category);
      }

      vm.$helper.hideLoader();
    },
    getListMethod() {
      return this.$route.query.method;
    },
    getListType() {
      return this.$route.query.type;
    },
    checkLessonTypesIsEnabled() {
      let vm = this;
      if (vm.getListType() == 0)
        return true;

      return false;


    },
    nameWithLang(o) {
      return this.$helper.getTitleValueLang(o);
    },
    isAssignment() {
      //check if the selected type is assignent
      if (this.dataModel.type == 0)
        return true;
      else
        return false;
    },
    findCourse() {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleCourse/findCourse", {
        id: vm.$route.params.list_id,
      });
      dispatch
          .then((response) => {
            let list = response.data.data.list;
            delete list.video;
            vm.list = list;
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();

            vm.$helper.handleError(error, vm);

          });
    },
    prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 10000,
        page: 1,
        searchKey: this.query ? this.query : "",
      };
    },
    getAllLists() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      // filters.method = "both";
      filters.startFrom = "1992-11-05 00:00:00.000";
      filters.startTo = "2992-11-05 00:00:00.000";
      let dispatch = this.$store.dispatch("moduleList/getAllList", filters);
      dispatch
          .then((response) => {
            let lists = this.$store.getters["moduleList/getAllList"];
            vm.lists = lists;
            let list_id = this.$route.params.list_id;
            if (list_id) {

              _.forEach(lists, (list) => {
                if (list.id == list_id) {
                  vm.selectedList = list;
                  vm.getAllGroubyByCourseId(list);
                }
              });
            }
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);

          });
    },

    getAllGroubyByCourseId(list) {
      let vm = this;
      if (!list) return
      vm.$helper.showLoader();
      vm.selectedGroup = null;
      let filters = {};
      filters.filters = vm.prepareFilters();
      filters.id = list.id;
      let dispatch = this.$store.dispatch(
          "moduleGroup/findGroupByCourseID",
          filters
      );
      dispatch
          .then((response) => {
            vm.groups = response.data.data.result.data;
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);

          });
    },
    previewImage() {
      this.dataModel.image = this.$refs.myImage.files[0];
    },
    previewVideo() {
      this.dataModel.vedio = this.$refs.myVideo.files[0];
    },
    previewFile() {
      this.dataModel.attachments = (this.dataModel.attachments || []).concat(...this.$refs.myfile.files);
    },
    openFile(file) {
      const blobData = new Blob([file], { type: file.type });
      const blobUrl = URL.createObjectURL(blobData);
      window.open(blobUrl, '_blank');
      URL.revokeObjectURL(blobUrl);
    },
    removeAttachment(file) {
      this.dataModel.attachments = Array.from(this.dataModel.attachments).filter(f => f !== file);
    },
    submitForm() {
      //check if the date is less than end date in livestream section


      // ----validators---
      this.$validator.validateAll().then((result) => {

        if (this.dataModel.liveStreamingTime && this.dataModel.liveStreamingEndTime && !this.$helper.isDateGreater(this.dataModel.liveStreamingTime, this.dataModel.liveStreamingEndTime)) {
          //  $('#liveStreamingEndTime').text(this.$t('liveStreamingEndTime'));
          this.$validator.errors.add({
            field: "liveStreamingEndTime",
            msg: this.$t('liveStreamingEndTime')
          })
          result = false;
          //  return;
        }

        if (result) {
          // if form have no errors
          this.addCourse();
        } else {
          // form have validation errors
          $('html, body').animate({scrollTop: 19}, 'slow');
          this.$helper.printValidationError(this.$validator.errors, this);
        }
      });
    },
    addCourse() {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};
      payload.data = vm.dataModel;
      payload.data.isLiveStreaming = vm.isLive ? 1 : 0;
      payload.data.isAssostatedWithGroup = vm.selectedGroup ? 1 : 0;
      payload.data.courseId = vm.selectedList ? vm.selectedList.id : '';
      payload.data.groupId = vm.selectedGroup ? vm.selectedGroup.id : null;

      const config = {
        headers: {

          "content-type": "multipart/form-data",
          crossdomain: true,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
          );
          $("#loader_message").text(percentCompleted + " %");
        },
      };

      const formData = new FormData();
      _.forEach(vm.dataModel, (value, key) => {
        if (value != null || key == 'assignmentDeadLineDate') formData.append(key, value);
      });


      if (this.dataModel.attachments && this.dataModel.attachments.length > 0) {
        //delete the key attachments to re append them as array of elemnets
        formData.delete('attachments');

        _.forEach(this.dataModel.attachments, (value, index) => {
          formData.append("attachments", value);
        });
      }

      payload.data = formData;
      payload.config = config;

      vm.$store
          .dispatch("moduleCourse/addCourse", payload)
          .then(() => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage("success", vm);
            vm.$router.push({
              name: this.$helper.getRouteFullName("all_courses"),
              query: {list_id: this.$route.params.list_id, method: this.$route.query.method, type: this.$route.query.type}
            }).catch(() => {
            });
          })
          .catch((error) => {
            if (error.response) {
              let error_type = error.response.data;

              if (
                  error_type.message === "RESOURCE_NOT_FOUND" ||
                  error_type.message === "UNACCEPTABLE_DATE"
              )
                $("#error_handle").text(
                    vm.$helper.getErrorValueLang(error_type.data)
                );
            }
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);

          });
    },
  },
};
</script>

<style scoped>
</style>
