import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllRatingReview({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.RATING_REVIEW}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_RATING_REVIEW', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    
    
    findRatingReviewByCourseID({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.LIST_RATING_REVIEW_COURSEID}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    findRatingReview({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_RATING_REVIEW_ID}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    addRatingReview({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.ADD_RATING_REVIEW}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateRatingReview({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_RATING_REVIEW}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeRatingReview({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_RATING_REVIEW}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
