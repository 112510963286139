/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
  SET_SUBSCRIBES(state, subscribes) {
    state.subscribes = subscribes
  },
}
