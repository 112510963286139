<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t("dashboard") }}</a>
            </li>
            <li
              class="breadcrumb-item active"
              aria-current="page"
              @click.prevent
            >
              {{ $t("all_teachers") }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">
                {{ $t("all_teachers") }}
              </h2>
            </div>
          </div>
          <div class="dashboard_container_header">
            <div class="dashboard_fl_1 text-left">
              <ul style="display:flex;">
                <li class="list-inline-item">
                  <!-- v-if="$helper.hasAccessPermission('create-faculty')" -->
                  <button
                    class="btn btn-danger-light"
                    @click="$router.push({ name: 'add_teachers' }).catch(()=>{})"
                  >
                    <i class="ti-plus"></i>
                    {{ $t("add") }}
                  </button>
                </li>
               <li class="nav-item">
                <button class="btn btn-success-light"  @click="getAllTeachers()">
                <i class="ti-filter"></i>
                {{$t('filter')}}
                </button>
                </li>
              </ul>
            </div>

            <div class="dashboard_fl_2">
              <ul class="mb0">
                <li class="list-inline-item">
                  <form
                    class="form-inline my-2 my-lg-0"
                    dir="ltr"
                    @submit.prevent="getAllTeachers()"
                  >
                    <input
                      class="form-control"
                      type="search"
                      :placeholder="$t('search')"
                      v-model="query"
                      aria-label="Search"
                    />
                    <button class="btn my-2 my-sm-0" type="submit">
                      <i class="ti-search"></i>
                    </button>
                  </form>
                </li>
              </ul>
            </div>
          </div>
           <div class="row justify-content-end mb-2 ml-2">
              
            <div
              dir="ltr"
              class="col-3 text-center mt-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer items-center font-medium"
            >
         
              <span class=""
                >{{$t('items_of')}}{{ page * itemsPerPage - (itemsPerPage - 1) }} {{$t('items_to')}}
                {{
                  page * itemsPerPage -
                  (itemsPerPage - 1) +
                  (teachers.length - 1)
                }}
                {{$t('items_for')}} {{ numRows }}</span
              >
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <div class="col-3 text-center">
              <label class="font-weight-bold">{{ $t("items_in_page") }}</label>
                <multiselect
                 :select-label="$t('multiselect_choose')"
                  :options="pager_items_choices" 
                   v-model="itemsPerPage" 
                   @input="getAllTeachers()"
                  :placeholder="$t('search')"></multiselect>
              </div>
              <!-- </div> -->
              </div>
          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">{{ $t("title_ar") }}</th>
                    <th class="text-left">{{ $t("title_en") }}</th>
                    <th class="text-left">{{ $t("phone") }}</th>
                    <th class="text-left">{{ $t("email") }}</th>
                    <th class="text-left" width="100px">
                      {{ $t("operation") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in teachers" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name_ar }}</td>
                    <td>{{ item.name_en }}</td>
                    <td>{{ item.mobile }}</td>
                    <td>{{ item.email }}</td>
                    <td>
                      <div class="btn-group direction-inverse">
                        <!-- v-if="$helper.hasAccessPermission('delete-teacher')" -->
                        <button
                          class="btn custom_btn btn-primary-light"
                          @click="deleteTeacher(item.id)"
                        >
                          {{ $t("delete") }}
                        </button>
                        <!-- v-if="$helper.hasAccessPermission('update-teacher')" -->
                        <button
                          class="btn custom_btn btn-success-light"
                          @click="
                            $router.push({
                              name: 'edit_teacher',
                              params: { id: item.id },
                            }).catch(()=>{})
                          "
                        >
                          {{ $t("edit") }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="teachers.length==0">{{$t('no_data')}}</p>

            </div>
            <div class="text-center" v-if="last_page > 1">
              <paginate
                :page-count="last_page"
                :page-range="1"
                :click-handler="handlePaginate"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "All",
  data() {
    return {
      teachers: [],
      query: null,
      pager_items_choices: [5, 10, 20, 50, 100],
      itemsPerPage: 10,
      page: 1,
      last_page: 1,
      numRows: 1,
    };
  },
  mounted() {
    this.getAllTeachers();
  },
  methods: {
    handlePaginate(pageNum) {
      this.page = pageNum;
      this.getAllTeachers();
    },
    prepareFilters() {
      return {
        doPagination: 1,
        numPerPage:this.itemsPerPage,
        page: this.page,
        searchKey: this.query ? this.query : "",
      };
    },
    getAllTeachers() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      let dispatch = this.$store.dispatch(
        "moduleTeacher/getAllTeachers",
        filters
      );
      dispatch
        .then((response) => {
          vm.teachers = this.$store.getters["moduleTeacher/getAllTeachers"];
           vm.numRows=response.data.data.result.numRows;
          vm.last_page=response.data.data.result.numPages;
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
    deleteTeacher(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },
    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      let dispatch = this.$store.dispatch("moduleTeacher/removeTeacher", {
        id: id,
      });
      dispatch
        .then((response) => {
          vm.$helper.hideLoader();

          let index = vm.teachers.findIndex((e) => {
            return e.id == id;
          });
          this.$store.commit("moduleTeacher/REMOVE_TEACHERS", index);
           this.$helper.showMessage('success',this);
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
  },
};
</script>

<style scoped>
</style>