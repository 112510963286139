<template>
    <div>
        <!--{{categories}}-->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" @click.prevent>{{$t('dashboard')}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{$t('add_academicyear')}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="dashboard_container">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <h2 class="font-weight-bold fun_font text-center">{{$t('add_academicyear')}}</h2>
                        </div>
                    </div>
                    <div class="dashboard_container_body p-2">

                        <div class="row">

                            <div class="col-md-4" v-if="!hasAcedemicChoiced">
                                <label class="font-weight-bold">{{$t('section')}}</label>
                                <multiselect
                                        :options="lists" :custom-label="$helper.getTitleValueLang"
                                        track-by="id" v-model="selected_list"
                                         :select-label="$t('multiselect_choose')"
                                           data-vv-name="departmentId" v-validate="'required'"
                                        :placeholder="$t('search')"></multiselect>
                                    <span class="text-danger text-sm">{{ errors.first('departmentId') }}</span>
                            </div>


                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_ar')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_ar" v-model="dataModel.data.name_ar"
                                           class="form-control"
                                           :placeholder="$t('title_ar')">
                                    <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_en')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_en" v-model="dataModel.data.name_en"
                                           class="form-control"
                                           :placeholder="$t('title_en')">
                                    <span class="text-danger text-sm">{{ errors.first('name_en') }}</span>
                                </div>
                            </div>

                        </div>

                    </div>
                     <div class="row">
                                <div class="col-12 pt-3">
                                     <h3 class="font-weight-bold fun_font text-center">{{$t('add_subject')}}</h3>
                                  </div>
                                 <div class="col-md-12" :key="index" v-for="(item , index) in dataModel.data.subjects">
                                     <div class="card p-2">
                                          <div class="row align-items-center">
                                        <div class="col-md-4">
                                                <label class="font-weight-bold">{{$t('title_ar')}}</label>
                                                <input type="text" class="form-control" v-model="item.subject_name_ar">
                                         </div>
                                           <div class="col-md-4">
                                                <label class="font-weight-bold">{{$t('title_en')}}</label>
                                                <input type="text" class="form-control" v-model="item.subject_name_en">
                                         </div>
                                           <div class="col-md-4 mx-auto mt-3">
                                                <button class="btn btn-warning-light btn-sm" @click="removeSubjectDom(index)">
                                            {{$t('delete')}}
                                        </button>
                                         </div>
                                         </div>
                                   </div>
                                </div>
                                  <div class="col-md-12 mt-1">
                                        <button class="btn btn-warning-light btn-sm" @click="addSubjectDom()">
                                            {{$t('add_subject')}}
                                        </button>
                                    </div>
                            </div>
                </div>


                    <div class="col-md-12 text-center">
                        <button class="btn btn-danger-light" @click="submitForm()">
                            {{$t('add')}}
                        </button>
                    </div>
                </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "Add",
        data() {
            return {
                dataModel: {
                    data:{
                    name_ar: '',
                    name_en: '',
                    departmentId:0,
                    subjects:[],
                    }
                },
                lists:[],
                selected_list:null,
                hasAcedemicChoiced:false,
            }
        },
        mounted() {
            this.getAllLists ();
            //if the university is already choiced
            if(this.$route.query.id){
                this.hasAcedemicChoiced=true;
                this.dataModel.data.departmentId=this.$route.query.id;
            }
        },
        methods: {
             addSubjectDom() {
                this.dataModel.data.subjects.push({
                    subject_name_ar: '', subject_name_en: ''
                })
            },
             removeSubjectDom(index) {
                this.dataModel.data.subjects.splice(index,1)
            },
            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // if form have no errors
                        this.addAcedemicYear();
                    } else {
                        // form have validation errors
                        $('html, body').animate({ scrollTop: 19 }, 'slow');
                        this.$helper.printValidationError(this.$validator.errors,this);
                    }
                })
            },
             getAllLists() {
                let vm = this;
                vm.$helper.showLoader();
                let filters = vm.prepareFilters();
                let dispatch = this.$store.dispatch('moduleCategory/getAllCategories', filters);
                dispatch.then((response) => {
                    vm.lists = this.$store.getters['moduleCategory/getAllCategories'];
                     vm.$helper.hideLoader();
                }).catch((error) => {
                    vm.$helper.hideLoader();
                    vm.$helper.handleError(error, vm);

                });
            },
             prepareFilters() {
                return {
                doPagination:0,
                numPerPage:1000,
                page:1,
                searchKey:this.query?this.query:''
                };
            },
            addAcedemicYear() {
                let vm = this;
                vm.$helper.showLoader();
                let payload = vm.dataModel;
                if(!vm.hasAcedemicChoiced)payload.data.departmentId=vm.selected_list.id;
                //server take this input as strigified
                payload.data.subjects=JSON.stringify(payload.data.subjects);

                vm.$store.dispatch('moduleAcedemicyears/addAcedemicyear', payload)
                    .then(() => {
                         //to parse the object to the normal case from the stringify
                        vm.dataModel.data.subjects=JSON.parse(vm.dataModel.data.subjects);
                        vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)
                        //navigate to the all page based on the hasAcedemicChoiced
                        // if(!vm.hasAcedemicChoiced)
                        vm.$router.push({name: 'all_academicyear',query:{id:this.$route.query.id}}).catch(()=>{});
                        // else vm.$router.push({name: 'all_categories',query:{id:this.$route.query.id}}).catch(()=>{});
                    })
                    .catch(error => {
                        //to parse the object to the normal case from the stringify
                        vm.dataModel.data.subjects=JSON.parse(vm.dataModel.data.subjects);

                        vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);

                    })
            },
        }
    }
</script>

<style>
    .multiselect__select {
        height: 55px;
    }

    .multiselect__tags {
        min-height: 55px;
        padding: 15px 40px 0 8px;
    }
</style>
