/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
    SET_GROUP(state, group) {
        state.group = group
    },
    REMOVE_GROUP(state, index) {
      console.log(index,"lkj");
      state.group.splice(index,1)
 },
}
