import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllSubjects({commit}, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_SUBJECTS, {
                params: filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_SUBJECTS', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addSubject({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.CREATE_SUBJECT, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    findSubject({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_SUBJECT}/${payload.id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateSubject({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_SUBJECT}/${payload.id}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeSubject({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_SUBJECT}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
