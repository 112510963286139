<template>
    <div>
        <!--{{categories}}-->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" @click.prevent>{{$t('dashboard')}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{$t('edit_university')}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="dashboard_container">
                    <div class="row">
                        <div class="col-12 pt-3">
                            <h2 class="font-weight-bold fun_font text-center">{{$t('edit_university')}}</h2>
                        </div>
                    </div>
                    <div class="dashboard_container_body p-2">

                        <div class="row">

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_ar')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_ar" v-model="dataModel.name_ar"
                                           class="form-control"
                                           :placeholder="$t('title_ar')">
                                    <span class="text-danger text-sm">{{ errors.first('name_ar') }}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="font-weight-bold">{{$t('title_en')}}</label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name_en" v-model="dataModel.name_en"
                                           class="form-control"
                                           :placeholder="$t('title_en')">
                                    <span class="text-danger text-sm">{{ errors.first('name_en') }}</span>
                                </div>
                            </div>

                            <div class="col-md-12 text-center">
                                <button class="btn btn-danger-light" @click="submitForm()">
                                    {{$t('edit')}}
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Add",
        data() {
            return {
                dataModel: {
                    name_ar: '',
                    name_en: '',
                    id:0
                }
            }
        },
        mounted() {
            this.findUniversity();
        },
        methods: {
            findUniversity() {
                let vm = this;
                vm.$helper.showLoader();
                let dispatch = this.$store.dispatch('moduleUniversity/findUniversity', {
                    id: vm.$route.params.id
                });
                dispatch.then((response) => {
                    vm.dataModel = response.data.data.university;
                    vm.$helper.hideLoader();
                }).catch((error) => {
                    vm.$helper.hideLoader();
                    vm.$helper.handleError(error, vm);
                });
            },
            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // if form have no errors
                        this.updateUniversity();
                    } else {
                        // form have validation errors
 $('html, body').animate({ scrollTop: 19 }, 'slow');
 this.$helper.printValidationError(this.$validator.errors,this);
                    }
                })
            },
            updateUniversity() {
                let vm = this;
                vm.$helper.showLoader();
                let payload ={};
                //return only the required fields
                payload.data=vm.$helper.deletUnusedObj(['name_ar','name_en'], vm.dataModel);
                payload.id=vm.dataModel.id;
                vm.$store.dispatch('moduleUniversity/updateUniversity', payload)
                    .then(() => {
                        vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)
                        vm.$router.push({name: 'all_universities'}).catch(()=>{})
                    })
                    .catch(error => {
                          vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);
                      
                    })
            },
        }
    }
</script>

<style scoped>

</style>