import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllGroup({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_GROUP_COURSEID}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_GROUP', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    
    
    findGroupByCourseID({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_GROUP_COURSEID}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    findGroup({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_GROUP}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    addGroup({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.CREATE_GROUP}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateGroup({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_GROUP}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeGroup({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_GROUP}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
