import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllFaculties({commit}, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_FACULTIES, {
                params: filters
            }).then((response) => {
                if (response.data.status) {
                     commit('SET_FACULTIES', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addFaculty({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.CREATE_FACULTY, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    findFaculty({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_FACULTY}/${payload.id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateFaculty({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_FACULTY}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeFaculty({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_FACULTY}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
