<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $t('courses') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $route.query.courseName }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('add_coupon') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('add_coupon') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-6" v-if="$helper.isAdmin()">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('list') }}</label>
                  <multiselect
                      v-model="selectedCourses"
                      :options="courses"
                      track-by="id"
                      :select-label="$t('multiselect_choose')"
                      :placeholder="$t('select_list')"
                      :custom-label="nameWithLang"
                      :multiple="true"
                  ></multiselect>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('coupon_code') }} *</label>
                  <input
                      type="text"
                      v-validate="'required|min:6|max:30'"
                      data-vv-name="coupon_code"
                      v-model="dataModel.code"
                      class="form-control"
                      :placeholder="$t('coupon_code')"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('coupon_code')">كود الكوبون يجب أن لا يقل عن 6 أحرف أو أرقام</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('value') }} *</label>
                  <input
                      type="number"
                      v-validate="'required|numeric'"
                      data-vv-name="coupon_value"
                      v-model="dataModel.value"
                      class="form-control"
                      :placeholder="$t('value')"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('coupon_value')">القيمة مطلوبة</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('discount_type') }} *</label>
                  <select v-validate="'required'" data-vv-name="discount_type"
                          v-model="dataModel.type"
                          class="form-control">
                    <option value="static">{{ $t('static') }}</option>
                    <option value="percentage">{{ $t('percentage') }}</option>
                  </select>
                  <span class="text-danger text-sm">{{ errors.first('discount_type') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('max_user') }} *</label>
                  <input
                      type="number"
                      v-validate="'required|numeric'"
                      data-vv-name="max_user"
                      name="max_user"
                      v-model="dataModel.maxUsers"
                      class="form-control"
                      :placeholder="$t('max_user')"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('max_user')">الحد الأقصى للمستخدمين مطلوب</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("start_date") }} *</label>
                  <flat-pickr
                      v-validate="'required'"
                      data-vv-name="start_date"
                      v-model="dataModel.startDate"
                      class="form-control"
                      :config="config"
                      :placeholder="$t('start_date')"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('start_date')"> {{ errors.first('start_date') }} </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("end_date") }} ({{ $t("optional") }})</label>
                  <flat-pickr
                      v-model="dataModel.endDate"
                      class="form-control"
                      :config="config"
                      :placeholder="$t('end_date')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <input id="applyMarketer" class="checkbox-custom" name="applyMarketer" type="checkbox" v-model="dataModel.applyMarketer">
                <label class="font-weight-bold checkbox-custom-label" for="applyMarketer">إضافة / تعيين مسوق</label>
              </div>
              <div class="col-md-6" v-if="dataModel.applyMarketer">
                <label class="font-weight-bold">{{$t('marketer')}}</label>
                <multiselect
                    :select-label="$t('multiselect_choose')"
                    :options="marketersList" v-model="dataModel.selectedMarketer" :custom-label="item => item.name"
                    data-vv-name="marketer" v-validate="'required'" :taggable="true" @tag="addMarketerToList"
                    placeholder="اختر أو ادخل جديد">
                </multiselect>
                <span class="text-danger text-sm">{{ errors.first('marketer') }}</span>
              </div>
              <div class="col-md-6" v-if="dataModel.applyMarketer">
                <label class="font-weight-bold">{{$t('university')}}</label>
                <multiselect
                    :select-label="$t('multiselect_choose')"
                    :options="universitiesList" :custom-label="$helper.getTitleValueLang"
                    track-by="id" v-model="dataModel.selectedUniversity"
                    data-vv-name="universityId" v-validate="'required'"
                    :placeholder="$t('search')">
                </multiselect>
                <span class="text-danger text-sm">{{ errors.first('universityId') }}</span>
              </div>
              <div class="col-md-6" v-if="dataModel.applyMarketer">
                <label class="font-weight-bold">{{$t('semester')}}</label>
                <multiselect
                    :select-label="$t('multiselect_choose')"
                    :options="semestersList" :custom-label="$helper.getTitleValueLang"
                    track-by="id" v-model="dataModel.selectedSemester"
                    data-vv-name="semester" v-validate="'required'"
                    :placeholder="$t('search')">
                </multiselect>
                <span class="text-danger text-sm">{{ errors.first('semester') }}</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 text-center">
               <span class="flex-d text-danger text-sm" id="error_handle"
               ></span>
              </div>

              <div class="col-md-12 text-center">
                <button class="btn btn-success" @click="submitForm()">{{ $t('add') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import helper from "../../../helpers/helper";

export default {
  name: "Add",
  data() {
    return {
      config: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
      courseId: this.$route.query.courseId,
      dataModel: {
        code: '',
        value: '',
        type: null,
        startDate: null,
        endDate: null,
        maxUsers: '',
        applyMarketer: false,
        selectedUniversity: null,
        selectedSemester: null,
        selectedMarketer: '',
      },
      courses: [],
      selectedCourses: [],
      universitiesList: [],
      marketersList: [],
      semestersList: [],
    };
  },
  components: {
    flatPickr
  },
  mounted() {
    if (helper.isAdmin()) {
      this.getAllCourses();
    }

    this.getAllUniversities();
    this.getAllMarketers();
    this.getAllSemesters();
  },
  methods: {
    nameWithLang(o) {
      return this.$helper.getTitleValueLang(o);
    },
    addMarketerToList(item) {
      this.marketersList.push({ id: -1, name: item });
      this.dataModel.selectedMarketer = { id: -1, name: item };
    },
    getAllCourses() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = {
        doPagination: 0,
        numPerPage: 1,
        page: 1,
        searchKey: "",
      };
      filters.startFrom = "1992-11-05 00:00:00.000";
      filters.startTo = "2992-11-05 00:00:00.000";
      this.$store.dispatch("moduleList/getAllList", filters).then((res) => {
        let lists = this.$store.getters["moduleList/getAllList"] || [];
        lists.unshift({
          id: 'all',
          name_ar: "لكل الدورات",
          name_en: "all",
        });
        vm.courses = lists;
        vm.selectedCourses = [lists[0]];
        vm.courseId = lists[0].id;
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    getAllUniversities() {
      this.$helper.showLoader();

      const params = {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: ''
      };

      this.$store.dispatch('moduleUniversity/getAllUniversities', params).then(() => {
        this.universitiesList = this.$store.getters['moduleUniversity/getAllUniversities'];
        this.$helper.hideLoader();
      }).catch((error) => {
        this.$helper.hideLoader();
        this.$helper.handleError(error, this);
      });
    },
    getAllMarketers() {
      this.$helper.showLoader();
      this.$store.dispatch('moduleList/getAllMarketers').then((response) => {
        const marketers = [];

        (response.data.data || []).forEach(item => {
          const index = marketers.findIndex(mk => mk.name === (item.name || ''));

          if (index === -1) {
            marketers.push(item);
          }
        });

        this.marketersList = marketers;
        this.$helper.hideLoader();
      }).catch((error) => {
        this.$helper.hideLoader();
        this.$helper.handleError(error, this);
      });
    },
    getAllSemesters() {
      this.$helper.showLoader();
      this.$store.dispatch('moduleList/getAllSemesters').then((response) => {
        this.semestersList = (response.data.data || []);
        this.$helper.hideLoader();
      }).catch((error) => {
        this.$helper.hideLoader();
        this.$helper.handleError(error, this);
      });
    },
    submitForm() {
      let vm = this;
      if (helper.isInstructor() && (!vm.courseId || vm.courseId == 'all')) {
        helper.showMessage('error', vm, 'برجاء تحديد الدورة لإضافة الكوبون');
        return;
      }
      if (helper.isAdmin() && this.selectedCourses.length === 0) {
        vm.$helper.showMessage('error', vm, 'يجب إختيار الدورة');
        return;
      }
      const coursesIds = helper.isAdmin() ? this.selectedCourses.map(value => value.id) : [vm.courseId];

      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const payload = {
            data: {
              coursesIds,
              code: vm.dataModel.code,
              value: vm.dataModel.value,
              type: vm.dataModel.type === 'percentage' ? 2 : 1,
              maxUser: vm.dataModel.maxUsers,
              startDate: vm.dataModel.startDate,
            }
          };
          vm.dataModel.endDate && (payload.data.endDate = vm.dataModel.endDate);

          if (this.dataModel.applyMarketer) {
            payload.data.marketerName = this.dataModel.selectedMarketer.name.trim();
            payload.data.universityId = this.dataModel.selectedUniversity.id;
            payload.data.semesterId = this.dataModel.selectedSemester.id;
          }

          vm.$helper.showLoader();
          this.$store.dispatch("moduleList/addCoupon", payload)
              .then(value => {
                vm.$helper.hideLoader();
                vm.$helper.showMessage('success', vm, 'تم إضافة الكوبون');
              })
              .catch((error) => {
                vm.$helper.hideLoader();
                vm.$helper.handleError(error, vm);
              });
        } else {
          $("html, body").animate({scrollTop: 19}, "slow");
        }
      });
    }
  },
};
</script>
