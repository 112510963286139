<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('links') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="text-center p-4">
            <h2 class="font-weight-bold fun_font">{{ $t('links') }}</h2>
          </div>

          <div class="mx-3 d-flex justify-content-between">
            <select ref="type" data-vv-name="type" v-model="selectedType" class="form-control w-auto" @change="filterLinks()">
              <option :key="index" v-for="(type , index) in types" :value="type.name"
              >{{ $t(type.text) }}
              </option>
            </select>

            <button class="btn btn-danger-light" @click="showAddModal()">
              {{ $t('add') }}
            </button>
          </div>

          <div class="dashboard_container_body mt-3">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('text') }}</th>
                  <th class="text-left">{{ $t('link') }}</th>
                  <th class="text-left">{{ $t('adding_date') }}</th>
                  <th class="text-left" style="width: 30px">{{ $t('operation') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in items" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.text }}</td>
                  <td>{{ item.link }}</td>
                  <td>{{ parseDate(item.createdAt) }}</td>
                  <td>
                    <div class="btn-group direction-inverse">
                      <button
                          class="btn custom_btn btn-primary-light"
                          @click="deleteItem(item.id)"
                      >{{ $t('delete') }}
                      </button>
                      <button
                          class="btn custom_btn btn-success-light"
                          @click="showEditModal(item)"
                      >{{ $t('edit') }}
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="items.length==0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="addLinkRef" overlay-theme="dark" modal-theme="dark">
      <h4 class="text-white">{{ modalType === 'add' ? 'إضافة لينك جديد' : 'تعديل لينك' }}</h4>
      <div class="row text-left">
        <div class="col-12">
          <label class="font-weight-bold">{{ $t('text') }}</label>
          <multiselect
              v-if="selectedType === 'social'"
              v-model="selectedSocialType"
              :options="socialTypes"
              v-validate="'required'"
              :searchable="false"
              track-by="id"
              :select-label="$t('multiselect_choose')"
              :custom-label="nameWithLang"
              :placeholder="$t('type')"
              @select="onSelectedType"
          ></multiselect>
          <input v-else type="text" v-validate="'required'" v-model="newLink.text" class="form-control" :placeholder="$t('text')">
        </div>
        <div class="col-12 mt-3">
          <label class="font-weight-bold">{{ $t('link') }}</label>
          <input type="url" v-validate="'required|url'" v-model="newLink.link" class="form-control" :placeholder="$t('link')">
        </div>
        <p class="text-danger col-12 mt-2" v-if="error">{{ error }}</p>
        <div class="col-12 mt-4">
          <button class="btn btn-success-light w-100" @click="addEditLink()">
            {{ $t(modalType) }}
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>

import { cloneDeep } from "lodash";
import moment from "moment";
import { SweetModal } from "sweet-modal-vue";

export default {
  name: "All",
  components: {SweetModal},
  data() {
    return {
      error: "",
      newLink: {
        text: "",
        link: ""
      },
      items: [],
      allLinks: [],
      socialTypes: [
        { name_ar: 'فيس بوك', name_en: 'Facebook', val: 'facebook' },
        { name_ar: 'واتساب', name_en: 'Whatsapp', val: 'whatsapp' },
        { name_ar: 'تويتر', name_en: 'Twitter', val: 'twitter' },
        { name_ar: 'يوتيوب', name_en: 'Youtube', val: 'youtube' },
        { name_ar: 'انستجرام', name_en: 'Instagram', val: 'instagram' },
        { name_ar: 'تيك توك', name_en: 'Tiktok', val: 'tiktok' },
        { name_ar: 'تليجرام', name_en: 'Telegram', val: 'telegram' },
        { name_ar: 'سناب شات', name_en: 'Snapchat', val: 'snapchat' },
      ],
      types: [{
        name: 'social',
        text: 'تواصل إجتماعي'
      },
        {
          name: 'helper',
          text: 'تساعد فى استخدام موقع الجامعة'
        }, {
          name: 'groups',
          text: 'جروبات عامة'
        }],
      selectedType: 'social',
      selectedSocialType: null,
      modalType: 'add',
      updateLinkId: -1,
    }
  },
  mounted() {
    this.getLinks();
  },
  methods: {
    parseDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return ' - ';
    },
    nameWithLang(o) {
      return this.$helper.getTitleValueLang(o);
    },
    showAddModal() {
      this.modalType = 'add';
      this.selectedSocialType = null;
      this.newLink = {
        text: "",
        link: ""
      };
      this.$refs.addLinkRef.open();
    },
    showEditModal(link) {
      this.modalType = 'edit';
      this.selectedSocialType = null;

      if (link.type === 'social') {
        const type = this.socialTypes.find(item => item.val === link.text);

        this.selectedSocialType = type || null;
      }

      this.newLink = {
        text: link.text || '',
        link: link.link || '',
      };

      this.updateLinkId = link.id;
      this.$refs.addLinkRef.open();
    },
    onSelectedType(o) {
      this.newLink.text = o.val;
    },
    async getLinks() {
      let vm = this;
      vm.$helper.showLoader();

      const res = await this.$store.dispatch("moduleAdmin/getLinks").catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });

      if (res.data.status && res.data.data && res.data.data.length) {
        vm.allLinks = res.data.data;
        this.filterLinks();
      }
      vm.$helper.hideLoader();
    },

    filterLinks() {
      this.items = this.allLinks.filter(link => link.type === this.selectedType) || [];
    },

    deleteItem(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },

    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch("moduleAdmin/removeLink", { id })
          .then(value => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage('success', vm, 'تم حذف العنصر');
            vm.allLinks = cloneDeep(vm.allLinks).filter(link => link.id != id);
            this.filterLinks();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },

    async addEditLink() {
      this.error = '';
      const result = await this.$validator.validateAll();

      if (result) {
        const data = {
          "text": this.newLink.text,
          "link": this.newLink.link,
          "type": this.selectedType
        };

        if (this.modalType === 'add') {
          await this.$store.dispatch("moduleAdmin/addLink", data).catch((error) => {
            this.$helper.handleError(error, this);
          });

        } else if (this.modalType === 'edit' && this.updateLinkId) {
          const payload = {
            id: this.updateLinkId,
            data,
          };

          await this.$store.dispatch("moduleAdmin/updateLink", payload).catch((error) => {
            this.$helper.handleError(error, this);
          });
        }

        this.$refs.addLinkRef.close();
        this.selectedSocialType = null;
        this.newLink = {
          text: "",
          link: ""
        };

        await this.getLinks();
      } else {
        this.error = 'تأكد من ادخال جميع الحقول بشكل صحيح';
      }
    },
  },
};
</script>
