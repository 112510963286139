/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
    SET_RATING_REVIEW(state, ratingReview) {
        state.ratingReview = ratingReview
    },
    REMOVE_RATING_REVIEW(state, index) {
       state.ratingReview.splice(index,1)
 },
}
