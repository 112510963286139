/*=========================================================================================
  File Name: moduleCaptainMutations.js
  Description: Captain Module Mutations
==========================================================================================*/


export default {
    SET_ASSIGNMENT_SUBMISSION(state, assignmentSubmission) {
        state.assignmentSubmission = assignmentSubmission
    },
    REMOVE_ASSIGNMENT_SUBMISSION(state, index) {
       state.assignmentSubmission.splice(index,1)
 },
}
